import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import vlogo from '../../imgs/backgroundPngLogo.png';
import DoneLoader from '../BotConfigure/DoneLoader';
import ReactConfetti from 'react-confetti';
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Typewriter from 'typewriter-effect';
import { useRef } from "react";

function InterviewBot() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  console.log(id, 'of student') // Retrieve the 'id' parameter
  const [showInstructionsModal, setShowInstructionsModal] = useState(true);
  const [startInterview, setStartInterview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [responseData, setResponseData] = useState();
  const [tabSwitchCount, setTabSwitchCount] = useState(0)
  const [timeLeft, setTimeLeft] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState(Array(questions.length).fill(""));
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [score, setScore] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [qaData, setQaData] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [timeTaken, setTimeTaken] = useState(null);
  const [testFinished, setTestFinished] = useState(false);
  const totalTime = questions.length * 60;
  console.log(selectedType, "selectedType")
  const [username, setUsername] = useState('');
  const [hubName, setHubName] = useState('');
  const [studentId, setStudentId] = useState('');
  const [hubId, setHubId] = useState('');
  const [showDocModal, setShowDocModal] = useState(false);
  const [docNames, setDocNames] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState('');

  const MyCodeLoader = () => <DoneLoader />;

  const assessmentTypes = [
    { id: 4, label: "AI Generated MCQ", iconUrl: "https://cdn-icons-gif.flaticon.com/16059/16059787.gif" },
    { id: 1, label: "AI Generated Descriptive", iconUrl: "https://cdn-icons-gif.flaticon.com/16059/16059787.gif" },
    { id: 2, label: "QuestionBank MCQ", iconUrl: "https://cdn-icons-gif.flaticon.com/16059/16059787.gif" },
    { id: 3, label: "QuestionBank Descriptive", iconUrl: "https://cdn-icons-gif.flaticon.com/16059/16059787.gif" },
  ];

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    setUsername(storedUsername || 'UserName');
    const storedHubName = sessionStorage.getItem('Ass_HubName');
    setHubName(storedHubName || 'Assessment Hub');
    const storedStudentId = sessionStorage.getItem('id');
    setStudentId(storedStudentId || '1');
    const urlParams = new URLSearchParams(window.location.search);
    const hubIdUrl = urlParams.get('id');
    setHubId(hubIdUrl || '1');
  }, []);

  useEffect(() => {
    if (selectedType === 1) {
      fetchDocNames();
    } else if (selectedType === 3) {
      getQBDesdata();
    } else if (selectedType === 4) {
      fetchDocNames();
    } else if (selectedType === 2) {
      getQBMcqData();
    } else {

    }
  }, [selectedType]);

  const fetchDocNames = () => {
    const questionType = selectedType === 4 ? 'ai_mcq' : 'ai_descriptive';
    axios.get(`${apiURL}/get_unique_doc_names/${hubId}?question_type=${questionType}`)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          setDocNames(response.data.data);
          setShowDocModal(true);
        } else {
          toastr.error("Failed to load document names.");
        }
      })
      .catch((error) => {
        console.error("Error fetching document names:", error);
        toastr.error("Error connecting to the server.");
      });
  };

  const handleStartAI = () => {
    if (!selectedDoc) {
      toastr.warning("Please select a document before starting the test.");
      return;
    }

    if (selectedType === 1) {
      getAIDesdata();
    } else {
      getAIMcqdata();
    }
    setShowDocModal(false);
  };

  const getAIDesdata = () => {
    axios.get(`${apiURL}/get_ai_assessment_questions?hub_id=${hubId}&docname=${selectedDoc}&question_type=ai_descriptive`) // ${hubId} - hardcoded - 1
      .then((response) => {
        const transformedQuestions = response.data.questions.map((item) => ({
          question: item.question,
          answer: item.answer,
          id: item.id,
          // q_no: item.q_no
        }));
        setQaData(response.data);
        console.log('get_qa_data', response.data.data)
        setQuestions(transformedQuestions);
        console.log('transformed qn response', transformedQuestions)
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getAIMcqdata = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const hubId = urlParams.get('id');
    if (!hubId) {
      toastr.error("Hub ID is missing in the URL.");
      return;
    }

    axios.get(`${apiURL}/get_ai_assessment_questions?hub_id=${hubId}&docname=${selectedDoc}&question_type=ai_mcq`) // ${hubId} - hardcoded - 2
      .then((response) => {
        if (response.status === 200 && response.data.questions) {
          const transformedQuestions = response.data.questions.map((item) => ({
            id: item.id, // Added question ID for reference
            q_no: item.q_no, // Added question number
            question: item.question,
            options: [
              item.options.option1,
              item.options.option2,
              item.options.option3,
              item.options.option4
            ],
            answer: item.answer,
            difficulty: item.difficulty_level || "Not specified",
            subject: item.subject || "General",
            topic: item.topic || "General",
          }));
          setQuestions(transformedQuestions);
          setUserResponses(Array(transformedQuestions.length).fill(""));
        } else {
          toastr.error("Failed to load questions. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toastr.error("Error connecting to the server.");
      });
  };

  const getQBDesdata = () => {
    axios.get(`${apiURL}/get_qbank_assessment_questions?hub_id=${hubId}&question_type=qbank_descriptive`)
      .then((response) => {
        const transformedQuestions = response.data.questions.map((item) => ({
          question: item.question,
          answer: item.answer,
          id: item.id,
          q_no: item.q_no
        }));
        setQaData(response.data);
        setQuestions(transformedQuestions);
        console.log('transformed qn response', transformedQuestions)
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getQBMcqData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const hubId = urlParams.get('id');
    if (!hubId) {
      toastr.error("No Hub's assigned for you");
      console.error("Hub ID is missing in the URL.");
      return;
    }

    axios.get(`${apiURL}/get_qbank_assessment_questions?hub_id=${hubId}&question_type=qbank_mcq`)
      .then((response) => {
        if (response.status === 200 && response.data.questions) {
          const transformedQuestions = response.data.questions.map((item) => ({
            id: item.id, // Added question ID for reference
            q_no: item.q_no, // Added question number
            question: item.question,
            options: [
              item.options.Option1,
              item.options.Option2,
              item.options.Option3,
              item.options.Option4
            ],
            answer: item.answer,
            difficulty: item.difficulty_level || "Not specified",
            subject: item.subject || "General",
            topic: item.topic || "General",
          }));
          setQuestions(transformedQuestions);
          setUserResponses(Array(transformedQuestions.length).fill(""));
        } else {
          toastr.error("Failed to load questions. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toastr.error("Error connecting to the server.");
      });
  };

  const handleNextInstructions = () => {
    setShowInstructionsModal(false);
    setStartInterview(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTabSwitchCount((prevCount) => prevCount + 1);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const handleAnswer = (index, answer) => {
    const updatedResponses = [...userResponses];
    updatedResponses[index] = answer;
    setUserResponses(updatedResponses);
  };

  const [currentResponse, setCurrentResponse] = useState("");
  const [aiDesResponses, setAiDesResponses] = useState([]);
  const [qbDesResponses, setQbDesResponses] = useState([]);
  const [scoreDetails, setScoreDetails] = useState({});

  const handleInputChange = (e) => {
    setCurrentResponse(e.target.value);
  };

  const handleFinishTest = async () => {
    setIsLoading(true);

    if (testFinished) return; // Prevent multiple calls
    setTestFinished(true);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const timeSpent = totalTime - timeLeft;
    setTimeTaken(timeSpent);

    // if (selectedType === 1) {
    //   if (aiDesResponses.length < questions.filter((q) => q.type === "descriptive").length) {
    //     toastr.warning("Please answer all descriptive questions before finishing the test.");
    //     setIsLoading(false);
    //     return;
    //   }
    // } else if (selectedType === 3) {
    //   if (qbDesResponses.some((response) => response.q_no === null || response.user_answer === "")) {
    //     toastr.warning("Please answer all descriptive questions before finishing the test.");
    //     setIsLoading(false);
    //     return;
    //   }
    // } else {
    //   if (userResponses.some((response) => response === null || response === "")) {
    //     toastr.warning("Please answer all MCQ questions before finishing the test.");
    //     setIsLoading(false);
    //     return;
    //   }
    // }

    try {
      let responseData = null;

      if (selectedType === 1) {
        const response = await fetch(`${apiURL}/validating_ai_descriptive_questions?student_id=${studentId}&hubid=${hubId}&question_type=ai_descriptive`, { // ${hubId} - hardcoded - 1
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(aiDesResponses),
        });

        if (!response.ok) {
          throw new Error("Error while submitting the answers");
        }

        const data = await response.json();
        setTotalScore(data.final_score);
        setScore(data.final_score);
        setScoreDetails(data.score_details);
        responseData = data;
        toastr.success("Assessment submitted successfully!");

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);

      } else if (selectedType === 3) {

        const payload = {
          student_id: studentId,
          hub_id: hubId,
          question_type: "qbank_descriptive",
          answers: qbDesResponses,
        };

        const response = await fetch(`${apiURL}/validate_qbank_descriptive_questions`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Error while submitting the answers");
        }

        const data = await response.json();
        setTotalScore(data.final_score);
        setScore(data.final_score);
        setScoreDetails(data.score_details);
        responseData = data;
        toastr.success("Descriptive assessment submitted successfully!");

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);

      } else if (selectedType === 4) {

        const formattedAnswers = questions.reduce((acc, question, index) => {
          acc[question.id] = userResponses[index];
          return acc;
        }, {});

        const payload = {
          hub_id: hubId, // hubId - hardcoded -2
          student_id: studentId,
          question_type: "ai_mcq",
          answers: formattedAnswers,
        };

        const response = await fetch(`${apiURL}/validate_ai_mcq_answers`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Error while submitting the answers");
        }

        const data = await response.json();
        setScore(data.final_score);
        responseData = data;
        toastr.success("Assessment completed. Check your score!");

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);

      } else {
        const formattedAnswers = questions.reduce((acc, question, index) => {
          acc[question.q_no] = userResponses[index];
          return acc;
        }, {});

        const payload = {
          hub_id: hubId,
          student_id: studentId,
          question_type: "qbank_mcq",
          answers: formattedAnswers,
        };

        const response = await fetch(`${apiURL}/validate_qbank_mcq_answers`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Error while submitting the answers");
        }

        const data = await response.json();
        setScore(data.final_score);
        responseData = data;
        toastr.success("Assessment completed. Check your score!");

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);

      }

      // Set response data in state for rendering
      setResponseData(responseData);
      setShowFinishModal(false);
      setStartInterview(false);
    } catch (error) {
      console.error("Error:", error);
      toastr.error("Failed to submit answers. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const timerRef = useRef(null);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now()); // Track start time of each question
  const [questionTimes, setQuestionTimes] = useState({}); // Store time spent on each question

  const startTimer = () => {
    const totalTime = questions.length * 60;
    setTimeLeft(totalTime);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft === null || testFinished) return; // Exit if test is finished

    if (timeLeft > 0) {
      return;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    Swal.fire({
      title: "Time Out!",
      text: "Your assessment time has ended. Submitting your answers.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      handleFinishTest();
    });
  }, [timeLeft, testFinished]);

  const handleNextQuestion = () => {
    setIsLoading(true);

    const questionId = questions[currentQuestionIndex]?.id;
    const q_no = questions[currentQuestionIndex]?.q_no;

    // Record time spent on current question
    const timeSpentOnQuestion = Date.now() - questionStartTime;
    setQuestionTimes((prev) => ({
      ...prev,
      [questionId]: timeSpentOnQuestion / 1000, // Store in seconds
    }));

    // const totalTime = Object.values(questionTimes).reduce((acc, time) => acc + time, 0);
    // const timeSpent = totalTime.toFixed(2);
    // setTimeTaken(timeSpent);

    if (selectedType === 1 || selectedType === 3) {
      const userAnswer = currentResponse.trim();

      if (!userAnswer) {
        toastr.warning("Please provide an answer before proceeding.");
        setIsLoading(false);
        return;
      }

      setAiDesResponses((prevResponses) => [
        ...prevResponses,
        { id: questionId, user_answer: userAnswer || "" },
      ]);

      setQbDesResponses((prevResponses) => [
        ...prevResponses,
        { q_no: q_no, user_answer: userAnswer || "" },
      ]);

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setCurrentResponse("");
        setQuestionStartTime(Date.now()); // Reset start time for the next question
        toastr.success("Answer saved.");
      } else {
        setShowFinishModal(true);
      }
    } else {
      if (!userResponses[currentQuestionIndex]) {
        toastr.warning("Please select an answer before proceeding.");
        setIsLoading(false);
        return;
      }

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestionStartTime(Date.now()); // Reset start time for the next question
        toastr.success("Answer saved.");
      } else {
        setShowFinishModal(true);
      }
    }

    setIsLoading(false);
  };

  const handleNextClick = () => {
    if (selectedType) {
      setQuestionStartTime(Date.now()); // Start timer for the first question
      handleNextInstructions(selectedType);
      startTimer();
    } else {
      toastr.info("Please select an Assessment type before proceeding.");
    }
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const totalPossibleScore = questions.length * 1;
  const totalResult = Object.values(scoreDetails).reduce((acc, curr) => acc + curr.score, 0);
  const totalPercentage = selectedType === 3 ? (totalResult / totalPossibleScore).toFixed(2) : ((totalResult / totalPossibleScore) * 100).toFixed(2);

  return (
    <>
      <style>
        {`
  body {
    background-color: #2b2d42;
    color: #edf2f4;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .text-green {
    color: #2ea87e;
  }
  .border-green {
    border: 1px solid #2ea87e;
  }
  .border-top-green {
    border-top: 1px solid #2ea87e;
  }
  .card {
    background: rgba(58, 59, 60, 0.7);
    backdrop-filter: blur(10px);
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  .card-header {
    background: rgba(74, 78, 105, 0.7);
    color: #edf2f4;
    font-weight: bold;
  }
  `}
      </style>

      <div className='card-header bg-primary py-3 d-flex flex-wrap justify-content-between'>
        <img src={vlogo} style={{ maxWidth: '10%' }} />
        <h4 className='text-white m-0 p-0'>User : {username}</h4>
      </div>
      <div className='card-body'>
        {/* Main Assessment Content */}
        {startInterview && (
          loading ? (
            <MyCodeLoader />
          ) : (
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className='mx-4'>
                      <span className='d-flex flex-wrap align-items-center flex-wrap justify-content-between'>
                        <h4 className="m-0 p-0 text-primary">Hub Name : {hubName}</h4>
                        <h5 className="m-0 p-0 text-warning">
                          Time Remaining
                          <span className="text-danger">
                            {" - "}
                            {formatTime(timeLeft)}
                            <img
                              src="https://cdn-icons-gif.flaticon.com/8721/8721066.gif"
                              style={{ width: '25px' }}
                              alt="Icon"
                              className="rounded-circle ms-3"
                            />
                          </span>
                        </h5>
                      </span>
                    </div>
                    <hr />

                    {/* Hub question and user response component */}
                    <div className='card-body' style={{ maxHeight: '50vh', minHeight: '50vh', overflow: 'auto' }}>
                      {currentQuestionIndex < questions.length ? (
                        <div className="text-center">
                          <p className="mb-4 fs-4 text-white">{questions[currentQuestionIndex].question}</p>
                          <div className="row mb-4 mx-xl-5 mx-1 px-xl-5">
                            {selectedType === 1 || selectedType === 3 ? (
                              // Textarea for descriptive answer
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Type your answer here..'
                                className='text-success fs-6'
                                value={currentResponse}
                                onChange={handleInputChange}
                              />
                            ) : (
                              // MCQ options
                              questions[currentQuestionIndex].options.map((option, index) => (
                                <div className="col-xl-6 mb-3" key={index}>
                                  <Button
                                    className={`btn bg-${userResponses[currentQuestionIndex] === option ? "green" : "white"} text-${userResponses[currentQuestionIndex] === option ? "white" : "dark"} border-green w-100`}
                                    onClick={() => handleAnswer(currentQuestionIndex, option)}
                                  >
                                    {option}
                                  </Button>
                                </div>
                              ))
                            )}
                          </div>
                          <div className="mx-auto text-center mt-5">
                            {/* {currentQuestionIndex > 0 && (
                              <div
                                className="btn btn-primary me-4"
                                onClick={() => !isLoading && setCurrentQuestionIndex(currentQuestionIndex - 1)}
                                role="button"
                                aria-label="Previous"
                              >
                                {isLoading && <span className="" role="status" aria-hidden="true"></span>}
                                Previous
                              </div>
                            )} */}
                            {currentQuestionIndex < questions.length - 1 ? (
                              <div
                                className="btn btn-primary"
                                onClick={() => !isLoading && handleNextQuestion()}
                                role="button"
                                aria-label="Next"
                              >
                                {isLoading ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
                                  </>
                                ) : (
                                  'Next'
                                )}
                              </div>
                            ) : (
                              <div
                                className="btn btn-warning"
                                onClick={() => !isLoading && handleNextQuestion()}
                                role="button"
                                aria-label="Finish"
                              >
                                {isLoading ? (
                                  <>
                                    <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
                                  </>
                                ) : (
                                  'Finish'
                                )}
                              </div>
                            )}
                          </div>

                        </div>
                      ) : (
                        <p className="m-0 p-0 text-info fs-6">Fetching Assessment Questions, Please Wait...</p>
                      )}
                    </div>
                    <div className='card-footer border-top-green'>
                      <div className="border-green rounded p-3">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mx-4">
                          <p className="m-0 p-0 text-white fs-6">Questions Remaining :</p>
                          <p className="m-0 p-0 text-warning fs-6 fw-bold">
                            {questions.length - currentQuestionIndex} / {questions.length}
                          </p>
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap align-items-center justify-content-between mx-4">
                          <p className="m-0 p-0 text-white fs-6">Tabs Switched :</p>
                          <p className="m-0 p-0 text-warning fs-6 fw-bold">
                            {tabSwitchCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {/* Select Assessment Type */}
      <Modal className="mt-xl-5 mt-3" show={showInstructionsModal} onHide={() => setShowInstructionsModal(false)} backdrop="static">
        <Modal.Header className="bg-primary">
          <Modal.Title className="d-flex flex-wrap align-items-center">
            <img src="https://cdn-icons-gif.flaticon.com/16059/16059787.gif" style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <h4 className="text-white m-0 p-0 ms-3">Assessment Type</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          <div className="row g-2">
            {assessmentTypes.map((type) => (
              <div key={type.id} className="col-xl d-flex flex-wrap justify-content-center">
                <div
                  className={`card text-white text-center ${selectedType === type.id ? 'bg-primary' : 'bg-dark'}`}
                  style={{ width: "12rem", cursor: "pointer" }}
                  onClick={() => setSelectedType(type.id)}
                >
                  <img src={type.iconUrl} className="card-img-top rounded-circle mx-auto mt-3" style={{ width: "60px" }} alt={type.label} />
                  <div className="card-body">
                    <h5 className="text-white">{type.label}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="d-flex flex-wrap justify-content-between">
            <a className="btn btn-primary" href="/KnowledgeScreen">
              Cancel
            </a>
            <div className="btn btn-primary" onClick={() => handleNextClick()}>
              Start Test
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Document Selection Modal */}
      <Modal className="mt-xl-5 mt-3" show={showDocModal} onHide={() => setShowDocModal(false)} backdrop="static">
        <Modal.Header className="bg-primary">
          <Modal.Title className="d-flex flex-wrap align-items-center">
            <img src="https://cdn-icons-gif.flaticon.com/16059/16059787.gif" style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <h4 className="text-white m-0 p-0 ms-3">Document / Subject Selection</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          <div className="row g-2">
            <ul className="list-group">
              {docNames.map((doc, index) => (
                <li
                  key={index}
                  className={`list-group-item ${selectedDoc === doc ? 'active' : ''}`}
                  onClick={() => setSelectedDoc(doc)}
                  style={{ cursor: 'pointer' }}
                >
                  {doc}
                </li>
              ))}
            </ul>
          </div>
          <hr />
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="btn btn-primary" onClick={() => setShowDocModal(false)}>
              Cancel
            </div>
            <div className="btn btn-primary" onClick={() => handleStartAI()}>
              Select
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Finish Confirmation Modal */}
      <Modal show={showFinishModal} onHide={() => setShowFinishModal(false)} backdrop="static">
        <Modal.Header className='bg-primary'>
          <Modal.Title className='d-flex flex-wrap align-items-center'>
            <img src='https://cdn-icons-gif.flaticon.com/15578/15578568.gif' style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <h4 className='text-white m-0 p-0 ms-3'>Finish Assessment ?</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=' bg-dark'>
          <p className='text-white fs-6'>Are you sure you want to finish the Assessment ?</p>
          <hr className='text-green' />
          <div className="d-flex flex-wrap justify-content-between">
            <div className="btn btn-primary" onClick={() => setShowFinishModal(false)}>
              Cancel
            </div>
            <div className="btn btn-primary" onClick={() => !isLoading && handleFinishTest()} role="button" aria-label="Finish">
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
              ) : (
                'Finish'
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Confetti */}
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      {/* Summary Modal */}
      <Modal show={score !== null} onHide={() => setScore(null)} backdrop="static">
        <Modal.Header className='bg-primary'>
          <Modal.Title className='d-flex align-items-center'>
            <h4 className='text-white m-0 p-0 me-5'>Assessment Summary</h4>
            <img src='https://cdn-icons-gif.flaticon.com/10971/10971313.gif' style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <a className="btn btn-primary ms-xl-5 ps-xl-5" href='/KnowledgeScreen'><i className='fas fa-close ms-5 ps-4'></i></a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-dark'>
          {selectedType === 2 || selectedType === 4 ? (
            // Content for MCQ type (selectedType === 2)
            <div className='text-white'>
              {selectedType === 4 && selectedDoc && (
                <span className='d-flex flex-wrap align-items-center justify-content-between'>
                  <p className="mb-1">Test Subject :</p>
                  <b className='text-white'>{selectedDoc}</b>
                </span>
              )}
              <span className='d-flex flex-wrap align-items-center justify-content-between'>
                <p className="mb-1">Correct answers :</p>
                <b className='text-success'>{responseData?.correct_count}</b>
              </span>
              <span className='d-flex flex-wrap align-items-center justify-content-between'>
                <p className="mb-1">Incorrect answers :</p>
                <b className='text-danger'>{responseData?.incorrect_count}</b>
              </span>
              {timeTaken !== null && (
                <span className='d-flex flex-wrap align-items-center justify-content-between'>
                  <p className="mb-1">Time taken :</p>
                  <b className='text-light'><span className='text-white'>{Math.floor(timeTaken / 60)}</span> minutes <span className='text-white'>{timeTaken % 60}</span> seconds</b>
                </span>
              )}
              <hr className='text-green' />
              {/* Time taken per question */}
              {questionTimes && (
                <div className="mt-3">
                  <h5 className="text-center text-warning">Time Breakdown</h5>
                  <ul className="list-group">
                    {Object.entries(questionTimes).map(([id, time], index) => (
                      <li key={id} className="list-group-item bg-dark text-light d-flex justify-content-between align-items-center">
                        <span>Question {index + 1} </span> {/* Question ID : {id} */}
                        <span className="badge bg-primary text-white">
                          {parseFloat(time).toFixed(2)} seconds
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <hr className='text-green' />
              {/* Detailed response breakdown */}
              <div className='mt-3'>
                <h5 className="text-center text-warning">Summary</h5>
                {(selectedType === 2 ? responseData?.result_data : responseData?.results)?.map((item, index) => (
                  <div key={index} className="bg-dark p-3 rounded my-2">
                    <h6 className="text-white">Question {index + 1} :</h6>
                    <p className={`text-${item.status === 'Correct' ? 'success' : 'danger'}`}><span className='text-light'>Status : </span>{item.status}</p>
                    <p className="text-secondary">Submitted Answer : <span className="text-light">{item.submitted_answer}</span></p>
                    <p className="text-success">Correct Answer : <span className="text-light">{item.correct_answer}</span></p>
                  </div>
                ))}
              </div>
              <hr className='text-green' />
              <span className='mx-auto text-center fs-6 text-warning'>
                <b>
                  <Typewriter
                    options={{
                      delay: 10,
                      deleteSpeed: 50,
                      loop: false,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("Thank you for participating in the Assessment !!")
                        .start();
                    }}
                  />
                </b>
              </span>
            </div>
          ) : (
            // Content for descriptive type (selectedType === 1 or 3)
            <div className='text-white'>
              {selectedType === 1 && selectedDoc && (
                <span className='d-flex flex-wrap align-items-center justify-content-between'>
                  <p className="mb-1">Test Subject :</p>
                  <b className='text-white'>{selectedDoc}</b>
                </span>
              )}
              <span className='d-flex flex-wrap align-items-center justify-content-between'>
                <p className="mb-1">Your score :</p>
                <b className='text-uppercase'>{totalPercentage}% out of 100%</b>
              </span>
              {timeTaken !== null && (
                <span className='d-flex flex-wrap align-items-center justify-content-between'>
                  <p className="mb-1">Time taken : </p>
                  <b className='text-light'><span className='text-white'>{Math.floor(timeTaken / 60)}</span> minutes <span className='text-white'>{timeTaken % 60}</span> seconds</b>
                </span>
              )}
              <hr className='text-green' />
              {/* Time taken per question */}
              {questionTimes && (
                <div className="mt-3">
                  <h5 className="text-center text-warning">Time Breakdown</h5>
                  <ul className="list-group">
                    {Object.entries(questionTimes).map(([id, time], index) => (
                      <li key={id} className="list-group-item bg-dark text-light d-flex justify-content-between align-items-center">
                        <span>Question {index + 1} </span> {/* Question ID : {id} */}
                        <span className="badge bg-primary text-white">
                          {parseFloat(time).toFixed(2)} seconds
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <hr className='text-green' />
              {/* Detailed scores for descriptive questions */}
              <div className='mt-3'>
                <h5 className="text-center text-warning">Summary</h5>
                {Object.keys(scoreDetails).map((questionId, index) => (
                  <div key={questionId} className="bg-dark p-3 rounded my-2">
                    <h6 className="text-white">Question {index + 1} :</h6>
                    <p className="text-success"><span className='text-warning'>Score :</span> {scoreDetails[questionId].score.toFixed(1)} / {selectedType === 1 ? 1.0 : 100}</p>
                    {/* 100 for QB des || 1.0 for AI des */}
                    <p className="text-white"><span className="text-secondary">Feedback :</span> {scoreDetails[questionId].feedback}</p>
                  </div>
                ))}
              </div>
              <span className='mx-auto text-center fs-6 text-warning'>
                <b>
                  <Typewriter
                    options={{
                      delay: 10,
                      deleteSpeed: 50,
                      loop: false,
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString("Thank you for participating in the Assessment !!")
                        .start();
                    }}
                  />
                </b>
              </span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='bg-dark'>
          <a className="btn btn-primary text-center mx-auto" href='/KnowledgeScreen'>Close</a>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InterviewBot;