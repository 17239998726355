import React, { useState, useEffect } from 'react'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import SideBar from '../../Layouts/SideBar'
import Footer from '../../Layouts/Footer'
import axios from 'axios';
import Swal from 'sweetalert2';
import { apiURL, botApiURL } from '../../CommonConfig/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners"; // For spinner
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // For progress bar

function KnowledgeBotConfig() {

    const navigate = useNavigate();
    const [color, setColor] = useState('#ffffff');
    const [loading, setLoading] = useState({ upload: false, download: null, delete: null, add: null });
    const [loadingData, setLoadingData] = useState(false);

    const [students, setStudents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    const [botname, setBotname] = useState("");
    const [fontsize, setFontsize] = useState(14);
    const [fontfamily, setFontfamily] = useState("Arial");
    const [welcomemsg, setWelcomemsg] = useState("Welcome");
    const [unknownmsg, setUnknownmsg] = useState("I don't understand");
    const [disclimermsg, setDisclimermsg] = useState("This is a general disclaimer.");
    const [activeTab, setActiveTab] = useState('navpills2-11');
    const [knowledgeHubId, setKnowledgeHubId] = useState(0);
    const [options, setOptions] = useState([]);
    const [team, setTeam] = useState(null);

    const [droppedFiles, setDroppedFiles] = useState([]);
    const [previewFiles, setPreviewFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [pdfData, setPdfData] = useState([]);
    const [droppedFile, setDroppedFile] = useState(null);
    const [fileId, setFileId] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);

    const handleColorChange = (e) => {
        setColor(e.target.value);
    };

    // Handle hex code input change
    const handleHexInputChange = (e) => {
        const hex = e.target.value;
        if (/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
            setColor(hex);
        } else {
            setColor('#ffffff');
        }
    };

    const handleSubmit = async () => {
        var Botname = botname;
        var Botcolor = color;
        var Fontsize = fontsize;
        var Fontfamily = fontfamily;
        var Welcomemsg = welcomemsg;
        var Unknownmsg = unknownmsg;
        var Disclimermsg = disclimermsg;

        if (!Botname || !Botcolor || !Fontsize || !Fontfamily || !Welcomemsg || !Unknownmsg || !Disclimermsg) {
            showAlert('Please fill in all fields before submitting', 'error');
            return;
        }

        var payload = {
            "bot_name": Botname,
            "bot_color": Botcolor,
            "font_size": Fontsize,
            "font_family": Fontfamily,
            "welcome_message": Welcomemsg,
            "unknown_response": Unknownmsg,
            "disclimer_message": Disclimermsg,
            "created_by": 0,
        };

        try {

            const response = await axios.post(`${apiURL}/create_knowledge`, payload);
            if (response.data.id !== null) {
                setKnowledgeHubId(response.data.id)
                showAlert('Knowledge bot configuration added successfully', 'success');
                setActiveTab('navpills2-21');

            } else {
                showAlert('Knowledge bot configuration added failed', 'error');
            }

        } catch (error) {
            console.error('Error making POST request:', error);
            showAlert('Error making POST request', 'error');
        }
    };

    const upload = async () => {
        debugger
        if (uploadedFiles.length === 0) {
            showAlert('Please select at least one file to upload', 'warning');
            return;
        }

        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            const mimeType = file.type || 'application/pdf';
            formData.append('file', file, file.name);
        });

        try {
            // const response = await axios.post(`${apiURL}/fileuploads`, formData, {
            const response = await axios.post(`${botApiURL}/fileuploads`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            debugger
            if (response.status === 200) {
                console.log(response);
                if (response.data.file_url !== "") {
                    showAlert('File(s) uploaded successfully!', 'success');
                    setActiveTab('navpills2-22');
                    fetchCombinedData();
                } else {
                    showAlert('File upload failed', 'error');
                }

            } else {
                showAlert('File upload failed', 'error');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                title: 'Error uploading file',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };

    const showAlert = (message, type) => {
        Swal.fire({
            title: message,
            icon: type,
            confirmButtonText: 'OK',
            confirmButtonColor: '#2EA87E',
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiURL}/get_all_team`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            });

            const result = await response.json();

            if (result.message === "Success") {
                const formattedOptions = result.data.map((team) => ({
                    value: team.id,
                    label: team.team_name,
                }));
                setOptions(formattedOptions);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const UpdateTeamData = async () => {
        if (team == null) {
            showAlert('Please select team', 'warning');
            return;
        }
        try {
            const response = await axios.put(`${apiURL}/update_knowledge_team/${knowledgeHubId}/${team}`, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (response.status === 200) {
                showAlert('Team Assigned successfully!', 'success');
            } else {
                showAlert('Team Assigned failed', 'error');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleResetHub = () => {
        let timerInterval;

        Swal.fire({
            title: 'Synchronizing Hub',
            html: 'Please wait for <b></b> seconds.',
            timer: 60000, // 1 minute
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = Math.ceil(Swal.getTimerLeft() / 1000); // Update countdown
                }, 1000);
            },
            willClose: () => {
                clearInterval(timerInterval); // Clean up the interval
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                // Action after timer ends (optional)
                console.log('Reset process completed.');
            }
        });
        debugger
        axios.post('${botApiURL}/scriptfile', {}, {
            headers: {
                'accept': 'application/json',
            }
        })
            .then((response) => {
                console.log("Bot restart response:", response);
            })
            .catch((error) => {
                console.error("Error restarting bot:", error);
            });
    };

    const fetchStudents = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_Students`);

            if (response.status === 200 && response.data && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                setStudents(sortedData);
                // setCount(sortedData.length);
            } else {
                // toastr.error('Failed to load students');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            // toastr.error('An error occurred while fetching students');
        } finally {
        }
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredStudents = students.filter(
        (student) =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalItems = filteredStudents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const fetchCombinedData = async () => {
        setLoadingData(true); // Show loader
        try {
            const response = await axios.get(`${botApiURL}/vbot_getall_files/`);
            setPdfData(response.data.db_vbot_knowledge || []);
        } catch (error) {
            console.error('Error fetching PDF data:', error);
            Swal.fire("Failed to load data", error.message, "error");
        } finally {
            setLoadingData(false); // Hide loader
        }
    };

    useEffect(() => {
        fetchCombinedData();
    }, []);

    const uploadFiles = async () => {
        if (uploadedFiles.length === 0 && droppedFiles.length === 0) {
            Swal.fire("Please select at least one file to upload", "", "warning");
            return;
        }

        const allFiles = [...uploadedFiles, ...droppedFiles];
        const formDataAll = new FormData();
        allFiles.forEach((file) => formDataAll.append("file", file));

        const formDataUploaded = new FormData();
        uploadedFiles.forEach((file) => formDataUploaded.append("file", file));

        setLoading((prev) => ({ ...prev, upload: true }));
        try {
            // First API call: Upload all files
            const uploadAllFiles = axios.post(`${apiURL}/upload_pdf/32`, formDataAll, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            // Second API call: Upload only uploaded files
            const uploadOnlyUploadedFiles = axios.post(`${botApiURL}/fileuploads`, formDataUploaded, {
                headers: { "Content-Type": "multipart/form-data" },
            });

            const [responseAllFiles, responseUploadedFiles] = await Promise.all([
                uploadAllFiles,
                uploadOnlyUploadedFiles,
            ]);

            if (responseAllFiles.status === 200 && responseUploadedFiles.status === 200) {
                Swal.fire("File(s) uploaded successfully!", "", "success");
                fetchCombinedData();
                setUploadedFiles([]);
                setDroppedFiles([]);
                setPreviewFile(null);
            } else {
                Swal.fire("One or more file uploads failed", "", "error");
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            Swal.fire("Error uploading files", error.message, "error");
        } finally {
            setLoading((prev) => ({ ...prev, upload: false }));
        }
    };


    const handleFileDropButton = (fileId) => {
        console.log("Drop button clicked for file_id:", fileId);

        const file = pdfData.find((item) => item.file_id === fileId);

        if (!file) {
            Swal.fire("File not found!", "", "error");
            console.error("File not found in pdfData for file_id:", fileId);
            return;
        }

        if (!file.file_convertion) {
            Swal.fire("Invalid file data!", "", "error");
            console.error("Invalid or missing file_convertion for file:", file);
            return;
        }

        setLoading((prev) => ({ ...prev, add: fileId }));
        try {

            const base64Data = file.file_convertion;
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);

            const blob = new Blob([byteArray], { type: "application/pdf" });
            const convertedFile = new File([blob], file.file_name, { type: "application/pdf" });

            setDroppedFiles((prevFiles) => [...prevFiles, convertedFile]);
            console.log("File successfully added to droppedFiles:", convertedFile);
        } catch (error) {
            Swal.fire("Error processing file!", "", "error");
            console.error("Error converting file_convertion to File:", error);
        } finally {
            setLoading((prev) => ({ ...prev, add: null }));
        }
    };

    const handleDownloadKnowledge = async (fileId) => {
        setLoading((prev) => ({ ...prev, download: fileId }));
        try {
            const response = await axios.get(`${botApiURL}/Filedownload/${fileId}`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `file_${fileId}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            Swal.fire('Error downloading file', error.message, 'error');
        } finally {
            setLoading((prev) => ({ ...prev, download: null }));
        }
    };

    const handleDeleteKnowledge = async (fileId, fileLocation) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you really want to delete the file with ID: ${fileId}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        });

        if (!result.isConfirmed) return;

        setLoading((prev) => ({ ...prev, delete: fileId }));
        try {
            const response = await axios.delete(`${botApiURL}/deleteuplodfiles`, {
                params: { file_id: fileId, file_location: fileLocation },
            });

            if (response.status === 200) {
                Swal.fire('File deleted successfully.', '', 'success');
                setPdfData((prevData) => prevData.filter((file) => file.file_id !== fileId));
                fetchCombinedData();
            } else {
                Swal.fire('Failed to delete the file.', '', 'error');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            Swal.fire('Error deleting file', error.message, 'error');
        } finally {
            setLoading((prev) => ({ ...prev, delete: null }));
        }
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);

        if (files.length > 0) {
            const file = files[0];
            setPreviewFile(URL.createObjectURL(file));
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const fileList = e.dataTransfer.files;
        if (fileList.length > 0) {
            const files = Array.from(fileList);
            setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    const handleRemoveFile = (fileName, type) => {
        if (type === 'uploaded') {
            setUploadedFiles(uploadedFiles.filter(file => file.name !== fileName));
        } else if (type === 'dropped') {
            setDroppedFiles(droppedFiles.filter(file => file.name !== fileName));
        }
    };

    return (
        <div>
            <div id="main-wrapper">
                <NavBar />
                <Header />
                <SideBar />
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Knowledge Hub Configure</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-tabs d-flex flex-wrap justify-content-between align-items-center mb-3">
                                            <span>
                                                <ul class="nav nav-tabs d-inline-flex" role="tablist">
                                                    <li class=" nav-item" role="presentation">
                                                        <button className={`nav-link ${activeTab === "navpills2-11" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-11")} role="tab">Hub Configure</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button className={`nav-link ${activeTab === "navpills2-21" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-21")} role="tab">Upload Knowledge</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button className={`nav-link ${activeTab === "navpills2-22" ? "active" : ""}`} onClick={() => setActiveTab("navpills2-22")} role="tab">Assign Team</button>
                                                    </li>
                                                </ul>
                                            </span>
                                            <span>
                                                <button className='btn btn-primary' onClick={handleResetHub}>Synchronize Hub</button>
                                            </span>
                                        </div>
                                        <div class="tab-content">
                                            {activeTab === 'navpills2-11' && (
                                                <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                                    <div class="card-body">
                                                        <div class="basic-form">
                                                            <form>
                                                                <div class="row">
                                                                    <div class="mb-3 col-md-6">
                                                                        <label class="form-label">Hub Name</label>
                                                                        <input type="text" class="form-control" placeholder="Enter Hub Name" value={botname} onChange={(e) => setBotname(e.target.value)} />
                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label">Hub Color</label>
                                                                        <div className="input-group">
                                                                            <input type="color" className="form-control" value={color} onChange={handleColorChange} />
                                                                            <input type="text" className="form-control w-50" placeholder="#ffffff" value={color} onChange={handleHexInputChange} />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="mb-3 col-md-6">
                                                                        <label class="form-label">Font Size</label>
                                                                        <input type="number" class="form-control" placeholder="Font Size" value={fontsize} onChange={(e) => setFontsize(e.target.value)} />
                                                                    </div>
                                                                    <div className="mb-3 col-md-6">
                                                                        <label className="form-label">Font Family</label>
                                                                        <select
                                                                            className="form-control"
                                                                            value={fontfamily}
                                                                            onChange={(e) => setFontfamily(e.target.value)}
                                                                        >
                                                                            <option value="Arial">Arial</option>
                                                                            <option value="Verdana">Verdana</option>
                                                                            <option value="Times New Roman">Times New Roman</option>
                                                                            <option value="Georgia">Georgia</option>
                                                                            <option value="Courier New">Courier New</option>
                                                                            <option value="Tahoma">Tahoma</option>
                                                                            <option value="Trebuchet MS">Trebuchet MS</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="mb-3 col-md-12">
                                                                        <label class="form-label">Hub Welcome Message</label>
                                                                        <select
                                                                            class="form-control"
                                                                            value={welcomemsg}
                                                                            onChange={(e) => setWelcomemsg(e.target.value)}
                                                                        >
                                                                            <option value="Welcome">Welcome</option>
                                                                            <option value="Hello">Hello</option>
                                                                            <option value="Greetings">Greetings</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="mb-3 col-md-12">
                                                                        <label class="form-label">Hub Unknown Response</label>
                                                                        <select
                                                                            class="form-control"
                                                                            value={unknownmsg}
                                                                            onChange={(e) => setUnknownmsg(e.target.value)}
                                                                        >
                                                                            <option value="I don't understand">I don't understand</option>
                                                                            <option value="Can you rephrase?">Can you rephrase?</option>
                                                                            <option value="Sorry, I didn’t get that">Sorry, I didn’t get that</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="mb-3 col-md-12">
                                                                        <label class="form-label">Hub Disclaimer Message</label>
                                                                        <select
                                                                            class="form-control"
                                                                            value={disclimermsg}
                                                                            onChange={(e) => setDisclimermsg(e.target.value)}
                                                                        >
                                                                            <option value="This is a general disclaimer.">This is a general disclaimer.</option>
                                                                            <option value="Please verify all information.">Please verify all information.</option>
                                                                            <option value="No guarantees are provided.">No guarantees are provided.</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className='mt-2 text-center'>
                                                            <button type="button" class="btn btn-primary" onClick={handleSubmit}>Create Hub</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {activeTab === 'navpills2-21' && (
                                                <div id="navpills2-21" class="tab-pane active show" role="tabpanel">
                                                    <div className="card-body d-flex flex-wrap">
                                                        <div className="col-xl-6">
                                                            <div className="d-flex justify-content-center mb-2">
                                                                <h4 className="card-title">Upload your Documents</h4>
                                                            </div>
                                                            <div className="col-lg-12 px-4">
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="fileUpload"
                                                                    multiple
                                                                    onChange={handleFileUpload}
                                                                    style={{ display: "none" }}
                                                                />
                                                                <label
                                                                    htmlFor="fileUpload"
                                                                    className="drag-drop-area bg-success-light d-flex flex-column justify-content-between"
                                                                    style={{
                                                                        border: "0.2rem dashed #cccccc",
                                                                        borderRadius: "1vh",
                                                                        padding: "12vh",
                                                                        paddingBottom: "4vh",
                                                                        textAlign: "center",
                                                                        cursor: "pointer",
                                                                        minHeight: "200px",
                                                                    }}
                                                                    onDragOver={(e) => e.preventDefault()}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        const fileData = e.dataTransfer.getData('application/json');
                                                                        if (!fileData) return;
                                                                        e.preventDefault();
                                                                        const fileList = e.dataTransfer.files;
                                                                        if (fileList.length > 0) {
                                                                            const files = Array.from(fileList);
                                                                            setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
                                                                        }

                                                                        const file = JSON.parse(fileData);

                                                                        try {
                                                                            const base64Data = file.file_convertion;
                                                                            const byteCharacters = atob(base64Data);
                                                                            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
                                                                            const byteArray = new Uint8Array(byteNumbers);
                                                                            const blob = new Blob([byteArray], { type: "application/pdf" });
                                                                            const convertedFile = new File([blob], file.file_name, { type: "application/pdf" });

                                                                            setDroppedFiles((prevFiles) => [...prevFiles, convertedFile]);
                                                                            console.log("File successfully added to droppedFiles:", convertedFile);
                                                                        } catch (error) {
                                                                            Swal.fire("Error processing file!", "", "error");
                                                                            console.error("Error converting file_convertion to File:", error);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div onDrop={handleDrop}>
                                                                        <i className="fas fa-upload"></i>
                                                                        <p>Drag & Drop your files here or click to upload</p>

                                                                        <h6 className='mt-5'>Instructions</h6>
                                                                        <ul className='mx-auto text-center'>
                                                                            <li>Uploaded files must not exceed 20 pages.</li>
                                                                            <li>Please ensure that the file size is less than 50MB.</li>
                                                                        </ul>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-xl me-3">
                                                            <h5>New Files</h5>
                                                            <ul className="list-group" style={{ overflow: 'auto', maxHeight: '40vh' }}>
                                                                {uploadedFiles?.length > 0 ? (
                                                                    uploadedFiles.map((file, index) => (
                                                                        <li className="list-group-item" key={index}>
                                                                            <span className='d-flex align-items-center justify-content-between'>
                                                                                <strong>{file.name}</strong>
                                                                                <button
                                                                                    className="btn btn-danger btn-xs ms-2"
                                                                                    onClick={() => handleRemoveFile(file.name, 'uploaded')}
                                                                                >
                                                                                    <i className='fas fa-trash'></i>
                                                                                </button>
                                                                            </span>
                                                                            <small className="text-muted">{new Date().toLocaleString()}</small>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li className="list-group-item text-muted">No files uploaded yet</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl">
                                                            <h5>Existing Files</h5>
                                                            <ul className="list-group" style={{ overflow: 'auto', maxHeight: '40vh' }}>
                                                                {droppedFiles?.length > 0 ? (
                                                                    droppedFiles.map((file, index) => (
                                                                        <li className="list-group-item" key={index}>
                                                                            <span className='d-flex align-items-center justify-content-between'>
                                                                                <strong>{file.name}</strong>
                                                                                <button
                                                                                    className="btn btn-danger btn-xs ms-2"
                                                                                    onClick={() => handleRemoveFile(file.name, 'dropped')}
                                                                                >
                                                                                    <i className='fas fa-trash'></i>
                                                                                </button>
                                                                            </span>
                                                                            <small className="text-muted">{new Date().toLocaleString()}</small>
                                                                        </li>
                                                                    ))
                                                                ) : (
                                                                    <li className="list-group-item text-muted">No files uploaded yet</li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="button" className="btn btn-primary" onClick={uploadFiles}>
                                                            {loading.upload ? (
                                                                <ClipLoader size={20} color="#fff" />
                                                            ) : (
                                                                "Upload"
                                                            )}
                                                        </button>
                                                    </div>
                                                    <hr />
                                                    <div className="row" style={{ overflow: 'auto', maxHeight: '64vh' }}>
                                                        <div className="d-flex justify-content-center">
                                                            <h4 className="card-title mb-3">Existing Files</h4>
                                                        </div>
                                                        <hr />

                                                        {loadingData ? (
                                                            <div className="d-flex justify-content-center align-items-center my-3">
                                                                <ClipLoader size={50} color="#007bff" />
                                                            </div>
                                                        ) : pdfData?.length > 0 ? (
                                                            pdfData.map((file) => (
                                                                <div
                                                                    className="card col-xl-3 col-md-12 mx-xl-5"
                                                                    key={file.file_id}
                                                                    draggable
                                                                    onDragStart={(e) => e.dataTransfer.setData('application/json', JSON.stringify(file))}
                                                                >
                                                                    <div className="card-body text-center ai-icon text-primary">
                                                                        <svg
                                                                            id="pdf-icon"
                                                                            className="my-1"
                                                                            viewBox="0 0 24 24"
                                                                            width="60"
                                                                            height="60"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                        >
                                                                            <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                        <h5 className="my-1">{file.file_name}</h5>
                                                                        <div className="d-flex justify-content-center">
                                                                            <button
                                                                                className="btn btn-warning btn-xs my-1 mx-3"
                                                                                onClick={() => handleFileDropButton(file.file_id)}
                                                                            >
                                                                                Add
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary btn-xs my-1 mx-3"
                                                                                onClick={() => handleDownloadKnowledge(file.file_id)}
                                                                            >
                                                                                {loading.download === file.file_id ? (
                                                                                    <ClipLoader size={15} color="#fff" />
                                                                                ) : (
                                                                                    "Download"
                                                                                )}
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger btn-xs my-1 mx-3"
                                                                                onClick={() => handleDeleteKnowledge(file.file_id, file.file_location)}
                                                                            >
                                                                                {loading.delete === file.file_id ? (
                                                                                    <ClipLoader size={15} color="#fff" />
                                                                                ) : (
                                                                                    "Delete"
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className="text-center text-muted">No files available</p>
                                                        )}
                                                    </div>

                                                    <hr />
                                                    <div class="row mt-4">
                                                        <div class="col-xl-6">
                                                            <div class="card">
                                                                <div class="card-header ">
                                                                    <h4 class="card-title text-muted">Select Files For Knowledge Hub</h4>
                                                                </div>
                                                                <div class="card-body p-0">
                                                                    <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                                                        <ul class="timeline">
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="form-check custom-checkbox checkbox-success check-lg me-3">
                                                                                        <input type="checkbox" class="form-check-input" id="customCheckBox1" required="" />
                                                                                        <label class="form-check-label" for="customCheckBox1"></label>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Indian Politics.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                                                        <input type="checkbox" class="form-check-input" checked id="customCheckBox2" required="" />
                                                                                        <label class="form-check-label" for="customCheckBox2"></label>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Economic Survey.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                                                        <input type="checkbox" class="form-check-input" checked id="customCheckBox3" required="" />
                                                                                        <label class="form-check-label" for="customCheckBox3"></label>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Current Affairs.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                                                        <input type="checkbox" class="form-check-input" checked id="customCheckBox6" required="" />
                                                                                        <label class="form-check-label" for="customCheckBox6"></label>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Ethics.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6">
                                                            <div class="card">
                                                                <div class="card-header">
                                                                    <h4 class="card-title text-muted">Selected Files for Knowledge Hub</h4>
                                                                </div>
                                                                <div class="card-body p-0">
                                                                    <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                                                        <ul class="timeline">
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Current Affairs.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>

                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Economic Survey.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="timeline-panel">
                                                                                    <div class="media-body">
                                                                                        <h5 class="mb-0">Ethics.pdf</h5>
                                                                                        <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            )}
                                            {activeTab === 'navpills2-22' && (
                                                <div id="navpills2-22" class="tab-pane active show" role="tabpanel">

                                                    <div className='card-body'>
                                                        <div class="d-flex flex-wrap align-items-center justify-content-between">

                                                            <div class="align-items-center d-flex">
                                                                <div class="me-4">
                                                                    <label class="text-primary fw-bold ms-2 mb-0">Select Team</label>
                                                                    <select class="select form-control wide mt-1" value={team} onChange={(e) => setTeam(e.target.value)}>
                                                                        <option value="">Select</option>
                                                                        {options.map((option, index) => (
                                                                            <option value={option.value}>{option.label}</option>
                                                                        ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div class="">
                                                                    <button type="button" class="btn btn-primary mt-4" onClick={UpdateTeamData}>
                                                                        <i class="fas fa-add me-3"></i>Assign Team
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div class="card-body">
                                                            <div className="d-flex align-items-center justify-content-between mb-4">
                                                                <div className="col-xl-6">
                                                                    <div className="input-group mb-2">
                                                                        <div className="input-group-text"><i className="fas fa-search"></i></div>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control wide"
                                                                            placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                            value={searchTerm}
                                                                            onChange={handleSearch}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3">
                                                                    <select
                                                                        className="form-control wide"
                                                                        value={itemsPerPage}
                                                                        onChange={handleItemsPerPageChange}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <option value="5">5 - items per page</option>
                                                                        <option value="10">10 - items per page</option>
                                                                        <option value="15">15 - items per page</option>
                                                                        <option value="20">20 - items per page</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="table-responsive">
                                                                <table className="table table-responsive-md">
                                                                    <thead className="mx-auto text-center">
                                                                        <tr>
                                                                            <th>S.No</th>
                                                                            <th>Student Name</th>
                                                                            <th>Student ID</th>
                                                                            <th>Email ID</th>
                                                                            <th>Password</th>
                                                                            <th>Mobile Number</th>
                                                                            <th>Department</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="mx-auto text-center">
                                                                        {paginatedStudents.length > 0 ? (
                                                                            paginatedStudents.map((student, index) => (
                                                                                <tr key={student.id}>
                                                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                                    <td>{student.name}</td>
                                                                                    <td>{student.studentid}</td>
                                                                                    <td>{student.emailid}</td>
                                                                                    <td>{student.password}</td>
                                                                                    <td>{student.number}</td>
                                                                                    <td>{student.department}</td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="7">No students available</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                                <div className="text-dark">
                                                                    Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                                                    <b className="text-primary">{students.length}</b> entries
                                                                </div>
                                                                <div className="text-dark">
                                                                    <span className="me-4">
                                                                        Page <b className="text-primary">{currentPage}</b> of{" "}
                                                                        <b className="text-primary">{totalPages}</b>
                                                                    </span>
                                                                    <button
                                                                        className="btn btn-xs btn-primary"
                                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                                        disabled={currentPage === 1 || totalPages === 0}
                                                                    >
                                                                        <i className="fas fa-chevron-left"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-xs btn-primary ms-2"
                                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                                        disabled={currentPage === totalPages || totalPages === 0}
                                                                    >
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default KnowledgeBotConfig