import React, { useEffect, useState } from 'react';
import network from '../../imgs/entrepreneurship.gif';
import answer from '../../imgs/network.gif';
import star from '../../imgs/AIstart.svg';
import chat from '../../imgs/chat.gif';
import interview from '../../imgs/online-interview.gif';
// import vlogo from '../../imgs/verandaLog.png';
import vlogo from '../../imgs/backgroundPngLogo.png';
import ContentLoader, { Code } from 'react-content-loader'
import Typewriter from 'typewriter-effect';
import ai from '../../imgs/Group 200.png';
import assessment from '../../imgs/ai.gif';
import img1 from '../../imgs/img1.jpg';
import img2 from '../../imgs/img2.jpg';
import img3 from '../../imgs/img3.png';
import img4 from '../../imgs/img4.png';
import img5 from '../../imgs/img5.jpg';
import axios from 'axios';
import { apiURL, wssURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import DoneLoader from '../BotConfigure/DoneLoader';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ReactMarkdown from 'react-markdown';
import { BaseRoutes } from '../../CommonConfig/BaseRoutes';
import { Link } from 'react-router-dom';
import StudentDigitalLib from './StudentDigitalLib';

function KnowledgeScreen() {

  const MyLoader = () => <ContentLoader />;
  const MyCodeLoader = () => <DoneLoader />;

  const [typedContent, setTypedContent] = useState('');
  const [userInput, setUserInput] = useState('');
  const [currentData, setCurrentData] = useState({ response: "", file_paths: [], page_number: [], references: [], file_contents: [] });
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
  const [isSideVisible, setIsSideVisible] = useState(false);
  const [isHelpSectionVisible, setIsHelpSectionVisible] = useState(true);
  const [isactive, setIsactive] = useState(false);
  const [assigneddata, setAssigneddata] = useState([]);
  const [assignedHubdata, setAssignedHubdata] = useState([]);
  const [assignedHubId, setAssignedHubId] = useState(1);
  const navigate = useNavigate();
  const [showDescription, setShowDescription] = useState(false);
  const [genieAnswer, setGenieAnswer] = useState("");
  const [selectedHubId, setSelectedHubId] = useState(0);
  const [studentId, setStudentId] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [questionId, setQuestionId] = useState();
  const [feedback, setFeedback] = useState(null);
  const [todayLimit, setTodayLimit] = useState(5);
  const [yesterdayLimit, setYesterdayLimit] = useState(5);
  const [thisMonthLimit, setThisMonthLimit] = useState(5);
  const [thisYearLimit, setThisYearLimit] = useState(5);
  const [olderLimit, setOlderLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const verandaResponseForTitle = "Veranda response :";
  const syncvizResponceForTitle = "Syncviz response :";

  console.log(assigneddata, "assigneddata")

  const [activeMenu, setActiveMenu] = useState(null);

  const handleToggle = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const [isSidebarExpanded, setSidebarExpanded] = useState(false);

  const toggleSidebar = () => {
    setSidebarExpanded((prevState) => !prevState);
  };

  const handleClear = () => {
    setIsInfoBoxVisible(false);
    setIsSideVisible(false);
    setUserInput('');
    setIsactive(false)
    setCurrentData(null);
    setIsHelpSectionVisible(true);
    setShowDescription(false);
  };
  // useEffect(() => {
  //   console.log("Updated currentData Response:", currentData.response);
  //   console.log("Updated currentData FilePath:", currentData.file_paths);
  //   console.log("Updated currentData Page Number:", currentData.page_number);
  //   console.log("Updated currentData References:", currentData.references);
  //   console.log("Updated currentData File Contents:", currentData.file_contents);
  // }, [currentData]);

  const redirectToPage = (query, response) => {
    navigate(`/AssessmentMcq?query=${encodeURIComponent(query)}&response=${encodeURIComponent(response)}&hubId=${encodeURIComponent(selectedHubId)}`);
  };

  const handleLogoutClick = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleResponse = (verandaResponse, genieResponse = null) => {

    const studentId = sessionStorage.getItem("id");
    if (!studentId) {
      console.error("No student ID found in session storage.");
      return;
    }

    const payload = {
      studentid: studentId,
      chat_question: userInput.trim(),
      likes: 0,
      dislikes: 0,
      responses: {
        veranda_response: verandaResponse,
        genie_response: genieResponse || "",
      },
    };

    axios
      .post(`${apiURL}/chat-history/`, payload, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Message saved to chat history:", response.data);
          fetchChatHistory(studentId);

          if (response.data && response.data.id) {
            setQuestionId(response.data.id);
          }
        } else {
          console.warn("Unexpected response while saving chat:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error saving chat to history:", error);
      });
  };

  const handleSubmit = () => {
    if (!userInput.trim()) {
      console.warn("Cannot submit an empty message.");
      return;
    }

    // WebSocket Logic
    setIsactive(true);
    setLoading(true);
    setIsInfoBoxVisible(true);
    setIsSideVisible(false);
    setIsHelpSectionVisible(false);

    let ws;
    function connect() {
      ws = new WebSocket(wssURL);

      ws.onopen = function () {
        console.log("WebSocket connection opened.");
        if (userInput) {
          ws.send(
            JSON.stringify({
              senderId: 101,
              message: userInput,
              hub_id: selectedHubId,
            })
          );
        }
      };

      ws.onmessage = function (event) {
        try {
          const parsedData = JSON.parse(event.data);
          console.log(parsedData, "WebSocket response");

          setShowDescription(false);
          setGenieAnswer("");
          setCurrentData((prevData) => ({
            ...prevData,
            response: parsedData.response || "",
            file_paths: parsedData.file_paths || "",
            page_number: parsedData.page_number || "",
            references: parsedData.references || "",
            file_contents: parsedData.file_contents || "",
          }));

          fetchChatHistory(sessionStorage.getItem("id"));
          handleResponse(parsedData.response);
          setLoading(false);
        } catch (error) {
          console.error("Failed to parse response as JSON:", error);
        }
      };

      ws.onerror = function (error) {
        console.error("WebSocket Error: ", error);
      };

      ws.onclose = function () {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        setTimeout(connect, 5000);
      };
    }

    connect();
  };

  const toggleDescriptionFromGenie = async (question, answer) => {
    setIsLoading(true); // Start loading
    try {
      setGenieAnswer(''); // Clear the existing answer
      const encodedQuestion = encodeURIComponent(question);
      const encodedAnswer = encodeURIComponent(answer);
      const response = await axios.post(`${apiURL}/genie?question=${encodedQuestion}&answer=${encodedAnswer}`);

      if (response && response.data) {
        console.log("Response data:", response.data);
        let genieRawData = response.data.explanation;

        // Preserve markdown content
        const genieData = genieRawData
          .replace(/\\(.?)\\*/g, '$1') // Handle escaped characters
          .trim();

        setGenieAnswer(genieData); // Update the state with markdown content
        setShowDescription(true); // Display the description
        handleResponse(genieData); // Handle additional response logic if needed
      } else {
        console.error("No data received:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const handleFeedback = (type) => {
    if (!questionId || !sessionStorage.getItem("id")) {
      console.error("Missing questionId or studentId for feedback.");
      return;
    }

    const studentId = sessionStorage.getItem("id");
    const payload = {
      id: questionId, // Use the stored `id` from POST response
      studentid: studentId,
      likes: type === "like" ? 1 : 0,
      dislikes: type === "dislike" ? 1 : 0,
    };

    axios
      .put(`${apiURL}/chat-history/update/`, payload, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Feedback submitted successfully:", response.data);
        } else {
          console.warn("Unexpected response while submitting feedback:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };

  useEffect(() => {
    const id = sessionStorage.getItem("id");
    if (id) {
      setStudentId(id);
      fetchChatHistory(id);
    } else {
      console.error("No student ID found in session storage.");
    }
  }, []);

  const fetchChatHistory = async (student_id) => {
    try {
      const response = await axios.get(`${apiURL}/get_all_chat-history/?studentid=${student_id}`);
      if (response.status === 200) {
        const chatData = response.data;

        // Filter chat history into categories
        const filteredChatHistory = categorizeChatHistory(chatData);
        setChatHistory(filteredChatHistory);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const categorizeChatHistory = (chats) => {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const categorized = {
      today: [],
      tomorrow: [],
      thisMonth: [],
      thisYear: [],
      older: [],
    };

    chats.forEach(chat => {
      const chatDate = new Date(chat.created_date);

      if (chatDate >= startOfDay) {
        categorized.today.push(chat);
      } else if (chatDate >= new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000)) {
        categorized.tomorrow.push(chat); // If tomorrow data is required
      } else if (chatDate >= startOfMonth) {
        categorized.thisMonth.push(chat);
      } else if (chatDate >= startOfYear) {
        categorized.thisYear.push(chat);
      } else {
        categorized.older.push(chat);
      }
    });

    return categorized;
  };

  const openPdfPopup = (pdfPath, pageNumber) => {
    console.log(pdfPath);
    console.log(`${pdfPath}#page=${pageNumber}`);


    const pdfWindow = window.open('', '_blank', 'width=800,height=600');

    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    // Embed the PDF with the page number reference
    // You can use a query parameter for the page number or just pass it directly to a PDF viewer URL if you have one
    // iframe.src = `https://synviz-bucket.blr1.digitaloceanspaces.com/synviz-bucket/Knowledge_Hub/data/dummy.pdf`;  // Assuming the path can handle page numbers in the query string
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/emp_form/Moolaah_IFD_Empanelment_Form_piTuhXX_1.pdf#page=4`;  // Assuming the path can handle page numbers in the query string
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/data/1/data/NCERT-Class-9-Geography-1.pdf#page=${pageNumber}`;  // Assuming the path can handle page numbers in the query string
    iframe.src = `${pdfPath}#page=${pageNumber}`;  // Assuming the path can handle page numbers in the query string
    // iframe.src = `https://devmoolaah.s3.ap-south-1.amazonaws.com/bot-data/data/1/data/Enviornment_UPSC.pdf#page=${pageNumber}`
    // https://v2-devmoolaah.s3.ap-south-1.amazonaws.com/org_0/Knowledge-Hub/data/156/data/71-10th_Science.pdf

    // Append the iframe to the popup window
    pdfWindow.document.body.appendChild(iframe);
  };

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      getData();
      getHubData(sessionStorage.getItem('id'));
      setDataFetched(true);
      // openPdfPopup("paramOne", "paramTwo")
    }
  }, [dataFetched]);

  const getData = async () => {
    axios.get(`${apiURL}/get_assessment_team_data/${sessionStorage.getItem('id')}`)
      .then((response) => {
        if (response.data) {
          setAssigneddata(response.data);

          const firstItem = response.data[0];
          const assHubId = firstItem.id;
          const assHubName = firstItem.bot_name;
          sessionStorage.setItem("Ass_HubId", assHubId);
          sessionStorage.setItem("Ass_HubName", assHubName);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  const getHubData = async (student_id) => {
    try {
      const response = await axios.get(`${apiURL}/get_knowledge_team_data/${student_id}`);
      if (response.data) {
        const hubForUser = response.data.knowledge_data;
        setAssignedHubdata(hubForUser);
        if (hubForUser.length > 0) {
          setSelectedHubId(hubForUser[0].id);
        }
        console.log(hubForUser[0].id, 'know hub ID');

        // const firstItem = response.knowledge_data.data[0];
        // const knowHubId = firstItem.id;
        // const knowHubName = firstItem.bot_name;
        // sessionStorage.setItem("Know_HubId", knowHubId);
        // sessionStorage.setItem("Know_HubName", knowHubName);
        console.log("Assigned Hub Data:", hubForUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleIconClick = (type) => {
    setFeedback(type); // Update the state to reflect the feedback
    handleFeedback(type); // Call the feedback handler
  };

  const copyTextToClipboard = () => {

    toastr.options = {
      positionClass: 'toast-center', // Custom class for centering
      timeOut: 3000, // Auto close after 3 seconds
      closeButton: true, // Show close button
      progressBar: true, // Show progress bar
    };

    navigator.clipboard.writeText(currentData.response)
      .then(() => {
        toastr.info('Text copied to clipboard!');
      })
      .catch(err => {
        toastr.error('Failed to copy text: ' + err);
      });
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {

      loadMoreMessages();
    }
  };


  const loadMoreMessages = () => {
    setTodayLimit(prev => prev + 5);
    setYesterdayLimit(prev => prev + 5);
    setThisMonthLimit(prev => prev + 5);
    setThisYearLimit(prev => prev + 5);
    setOlderLimit(prev => prev + 5);
  };


  return (
    <>
      <style>{`
    .mm-show {
      display: block;
    }
    ul[aria-expanded="false"] {
      display: none;
    }
    .metismenu ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
    .metismenu ul.mm-show {
      max-height: 500px; /* Adjust based on the content */
    }
    body {
        background-color: #2b2d42;
        color: #edf2f4;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
        
    .dlabnav {
    background: rgba(43, 45, 66, 0.8); /* Semi-transparent background */
    border-right: 1px solid rgba(255, 255, 255, 0.2); /* Soft white border */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}
    .header{
    border-bottom: 1px solid #2ea87e;
    }

      /* Input section styles */
      .input-section {
        background: rgba(74, 78, 105, 0.7);
        backdrop-filter: blur(10px);
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 15px;
        display: flex;
      }

      .input-field {
        width: 100%;
        border: 1px solid #2ea87e;
        border-radius: 25px;
        padding: 10px 20px;
        transition: border-color 0.3s;
        background: rgba(52, 58, 64, 0.7);
        color: #edf2f4;
      }

      .input-field:focus {
        border-color: #007bff;
        outline: none;
      }

      /* Button styles */
      .btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s, transform 0.3s;
      }

      .btn:hover {
        background-color: rgba(0, 123, 255, 0.7);
        transform: scale(1.1);
      }

      .animate-icon {
        transition: transform 0.2s;
      }

      .animate-icon:hover {
        transform: rotate(20deg);
      }

      /* Add a glowing effect for AI theme */
      .btn-primary {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      .btn-secondary {
        box-shadow: 0 0 5px rgba(108, 117, 125, 0.5);
      }

      .ai-overview-section {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }

      .ai-overview-icon {
        width: 30px;
        height: 30px;
      }

      /* Heartbeat animation */
      .heartbeat-animation {
        animation: heartbeat 1.5s ease-in-out infinite;
      }

      /* Keyframes for heartbeat effect */
      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      body,
      html {
        scroll-behavior: smooth;
      }

      .info-box {
        background-color: unset;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        margin-left:10px;
      }

      .title {
        font-size: 1.3rem;
        margin-bottom: 20px;
        color: #ffffff;
      }

      .sources {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .source-item {
        background-color: #333333;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        color: #b3b3b3;
        border: 1px solid #2ea87e;
      }

      .source-item a {
        color: #7cc5ff;
        text-decoration: none;
        margin-bottom: 5px;
      }

      .source-name {
        font-size: 0.8rem;
        color: #999999;
      }

      .view-more {
        color: #7cc5ff;
        text-decoration: none;
        margin-top: 10px;
        display: block;
      }

      /* Icon and Text Container Styling */
      .icon-text {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        color: #b3b3b3;
        font-size: 1rem;
      }

      /* Icon Styling */
      .icon-text i {
        font-size: 1.5rem;
        color: #7cc5ff;
      }

      /* Description Styling */
      .description {
        background-color: #333333;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      .description-title {
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .collapse-btn {
        background: none;
        border: none;
        color: #7cc5ff;
        cursor: pointer;
      }

      /* New Thread Button */
      .new-thread {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333333;
        color: #ffffff;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 1rem;
      }

      .shortcut {
        background-color: #555555;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        color: #e0e0e0;
      }

      .active {
        background-color: #333333;
        color: #ffffff;
      }

      /* Auth Section */
      .auth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
      }

      .signup-btn {
        background-color: #f5f5f5;
        color: #333333;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
      }

      .login-link {
        color: #7cc5ff;
        text-decoration: none;
        font-size: 0.9rem;
      }

      .dropdown-item {
        color: #b3b3b3;
        text-decoration: none;
        padding: 8px 0;
        transition: color 0.3s;
      }

      .dropdown-item:hover {
        color: #ffffff;
      }

      .description {
        position: relative;
        margin-bottom: 20px;
      }

      .description-title {
        margin: 0;
      }

      .icon-container {
        display: flex;
        gap: 10px;
      }

      .icon-container i {
        cursor: pointer;
        font-size: 18px;
        color: #555;
        transition: color 0.3s;
      }

      .icon-container i:hover {
        color: #007bff; /* Change color on hover */
      }

      .like-icon {
        color: #28a745; /* Green color for like */
      }

      .dislike-icon {
        color: #dc3545; /* Red color for dislike */
      }

      .copy-icon {
        color: #6c757d; /* Gray color for copy */
      }

        .accordion-item {
          margin-bottom: 0px;
          border: 0;
          background-color: transparent;
   
}
         .sources {
    /* Add some spacing if necessary */
    margin: 20px;
}

.source-item {
    margin-bottom: 15px;
    position: relative; /* Needed for tooltip positioning */
}

.tooltip-link {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: #007bff; /* Link color */
    font-weight: normal;
}
    .tooltip-linkcolor {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: red; /* Link color */
    font-weight: bold;
}



.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.tooltip-content {
    color: #ffcc00; /* Set the color you want for content */
    font-weight: normal; /* Optional: make it bold */
    font-size: 16px;
}

.tooltip {
    visibility: hidden;
    width: 250px; /* Adjust width as needed */
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 8px; /* Slightly rounded corners */
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 250%; /* Position above the link */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.card-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-content ul li {
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.small-card {
  flex: 0 0 calc(33.333% - 16px);
  padding: 12px;
  text-align: center;
}



.image-card img,
.full-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
}

.watch-card {
  position: relative;
  text-align: center;
  padding: 12px;
}

.watch-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.watch-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.watch-button:hover {
  background-color: #0056b3;
}

.image-row-row {
  display: flex;
  gap: 5px; /* Space between images */
  margin-bottom: -80px;
}

.image-container {
  text-align: center;
}

.small-card-img {
  width: 100%; /* Adjust the width to make the images smaller */
  height: 50%;
  border-radius: 5px;
}
  .overlay-text {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 10px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;

}

.image-container {
  position: relative;
  display: inline-block;
}
  .big-image-container {
  text-align: center; /* centers the image horizontally */
  margin-bottom: 20px; /* adds some space below the large image */
}
  .big-card-img {
  width: 100%; /* makes the image responsive */
  max-width: 600px; /* ensures it doesn't get too large */
  height: 200px; /* maintains aspect ratio */
   border-radius: 5px;
}
   .accordion-collapse {
  max-height: 400px;  /* Set the maximum height for the content */
  overflow-y: auto;   /* Enable vertical scrolling if the content exceeds the max height */
}

.big-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-row-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.image-container {
  flex: 1;
  max-width: 48%;
  position: relative; /* Relative positioning for the icon overlay */
  text-align: center;
}

.small-card-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Adds rounded corners to the images */
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 5px;
}

.icon-overlay {
  position: absolute;
  top: 10px; /* Adjust for positioning */
  right: 10px; /* Adjust for positioning */
  font-size: 16px; /* Size of the icon */
  color: white; /* Color of the icon */
  background: rgba(0, 0, 0, 0.5); /* Background for visibility */
  border-radius: 50%; /* Circular background */
  padding: 5px; /* Padding for the icon background */
}


.ai-logo-img {
  width: 24px; /* Adjust the size of the AI logo */
  height: 24px; /* Adjust the size of the AI logo */
  cursor: pointer; /* Optional: pointer cursor for interactivity */
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // background-color: #1c1c1c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 2rem;
  border-radius: 8px;
}

.help-title {
  font-size: 30px !important; 
  margin-bottom: 1rem;
  color:#ffff;
}

.button-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}


.help-button {
  background-color: #333333; /* Button color */
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.help-button i {
  font-size: 18px; /* Icon size */
}

.help-button:hover {
  background-color: #555555; /* Lighter color on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.help-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}


.disclaimer {
  font-size: 14px;
  color: #aaaaaa; /* Lighter gray text for disclaimer */
}



/* Button Styling */
.assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 25px;
  padding: 10px 9px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect */
.assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(46, 168, 126, 0.7);
}

/* Dark Pattern Effect on Hover */
.assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}
/* Adjust for larger devices */
@media (min-width: 350px) {
  .content {
    max-width: 400px;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content {
    max-width: 960px;
  }
}

@media (min-width: 1500px) {
  .content {
    max-width: 1400px;
  }
}

@media (max-width: 576px) {
  .tooltip-content {
    font-size: 12px; /* Adjust text size for small devices */
  }
  .source-name {
    font-size: 14px; /* Adjust text size for page number */
  }
}

.icon-text:hover .genie-answer {
  color: #007bff; /* Change the text color on hover */
}

@media (max-width: 768px) {
  .assessment {
    display: none;
  }
}
.metismenu ul {
  overflow: auto;
  max-height: 30vh; /* Adjust as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent;
}

.metismenu ul::-webkit-scrollbar {
  width: 8px; /* Adjust the width */
}

.metismenu ul::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

.metismenu ul::-webkit-scrollbar-track {
  background: transparent;
}
  
/* Smaller Button Styling */
.small-assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 20px; /* Slightly smaller border radius */
  padding: 6px 8px; /* Reduced padding */
  color: white;
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect for smaller button */
.small-assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-2px); /* Slightly less lift */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

/* Focus effect */
.small-assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 8px rgba(46, 168, 126, 0.5);
}

/* Dark Pattern Effect on Hover */
.small-assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250%; /* Slightly smaller circle */
  height: 250%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.small-assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.toast-center {
  bottom: 10%;
  right: 0%;
  position: fixed;
  z-index: 9999;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2EA87E;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

 
    `}</style>

      <div id="main-wrapper">

        {/* navbar */}
        <div class="nav-header bg-green">
          <a href class="brand-logo">
            <img src={vlogo} style={{ width: "75%" }} />
          </a>
          <div class="nav-control">
            <div className="hamburger" onClick={toggleSidebar}>
              {isSidebarExpanded ? (
                <i className="fas fa-times" title="Collapse Sidebar"></i>
              ) : (
                <i className="fas fa-bars" title="Expand Sidebar"></i>
              )}
            </div>
          </div>
        </div>

        {/* header */}
        <div class="header bg-green">
          <div class="header-content">
            <nav class="navbar navbar-expand">
              <div class="collapse navbar-collapse justify-content-between">
                <div class="header-left">
                  <div class="dashboard_bar">
                  </div>
                </div>
                <ul class="navbar-nav header-right">
                  <div class="dashboard_bar d-flex flex-wrap justify-content-between align-items-center">
                    <h4>{sessionStorage.getItem('username')}</h4>
                    <li class="nav-item dropdown  header-profile">
                      <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                        <i className='fas fa-user fs-4 p-2'></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a href={`/ProfileOfStudent?studentId=${studentId}`} class="dropdown-item ai-icon">
                          <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                          <span class="ms-2">Profile </span>
                        </a>
                        <a href class="dropdown-item ai-icon" onClick={handleLogoutClick}>
                          <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                          <span class="ms-2">Logout </span>
                        </a>
                      </div>
                    </li>
                  </div>
                </ul>

              </div>
            </nav>
          </div>
        </div>

        {/* sidebar */}
        <div className="dlabnav">
          <div className="dlabnav-scroll">
            <ul className="metismenu" id="menu">
              <li>
                <button
                  className="small-assessment-button" style={{ cursor: 'pointer' }}
                  onClick={handleClear}
                  aria-expanded={activeMenu === 'knowledgeHub'}
                >
                  <span className="mx-2">New chat</span>
                  <span class="icon mx-2">
                    <i class="fas fa-plus"></i>
                  </span>
                </button>

              </li>
              {/* Knowledge Hub Menu */}
              <li>
                <a
                  className="has-arrow" style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('knowledgeHub')}
                  aria-expanded={activeMenu === 'knowledgeHub'}
                >
                  <img
                    src={chat}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Knowledge Hub</span>
                </a>
                <ul className={activeMenu === 'knowledgeHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'knowledgeHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                  {assignedHubdata.length > 0 ? (
                    assignedHubdata.map((hub) => (
                      <li key={hub.id}>
                        <a
                          href={`/KnowledgeScreen?id=${hub.id}`}
                          onClick={() => {
                            sessionStorage.setItem('hub_id', hub.id);
                            localStorage.setItem('hub_id', hub.id);
                            // setSelectedHubId(hub.id);
                          }}
                        >
                          {`${hub.bot_name}`}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>
                      <a className="">No assigned hubs found.</a>
                    </li>
                  )}
                </ul>
              </li>
              {/* Assessment Hub Menu */}
              <li>
                <a
                  className="has-arrow" style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('assessmentHub')}
                  aria-expanded={activeMenu === 'assessmentHub'}
                >
                  <img
                    src={chat}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Assessment Hub</span>
                </a>
                <ul className={activeMenu === 'assessmentHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'assessmentHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                  {assigneddata.length > 0 ? (
                    assigneddata.map((assigneddatas) => (
                      <li key={assigneddatas.id}>
                        <a href={`/InterviewBot?id=${assigneddatas.id}`}>
                          {`${assigneddatas.bot_name}`}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>
                      <a className="">No assigned hubs found.</a>
                    </li>
                  )}
                </ul>
              </li>

              {/* Chat History */}
              <li>
                <a
                  className="has-arrow"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('chatHistory')}
                  aria-expanded={activeMenu === 'chatHistory'}
                >
                  <img
                    src={interview}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Chat History</span>
                </a>
                <ul
                  className={activeMenu === 'chatHistory' ? 'mm-show' : ''}
                  aria-expanded={activeMenu === 'chatHistory'}
                  style={{ overflow: 'auto', maxHeight: '30vh' }}
                  onScroll={handleScroll}
                >
                  {chatHistory.today && chatHistory.today.length > 0 ? (
                    <>
                      <li className="messages-header mx-1">Today</li>
                      {chatHistory.today.slice(0, todayLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2 cursor-pointer">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.today.length > todayLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more...
                          </a>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="messages mt-2">
                      <a className="text-muted">No chat history for today.</a>
                    </li>
                  )}

                  {chatHistory.yesterday && chatHistory.yesterday.length > 0 && (
                    <>
                      <li className="messages-header mx-2">Yesterday</li>
                      {chatHistory.yesterday.slice(0, yesterdayLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.yesterday.length > yesterdayLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more...
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.thisMonth && chatHistory.thisMonth.length > 0 && (
                    <>
                      <li className="messages-header mx-2">This Month</li>
                      {chatHistory.thisMonth.slice(0, thisMonthLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.thisMonth.length > thisMonthLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.thisYear && chatHistory.thisYear.length > 0 && (
                    <>
                      <li className="messages-header mx-2">This Year</li>
                      {chatHistory.thisYear.slice(0, thisYearLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.thisYear.length > thisYearLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.older && chatHistory.older.length > 0 && (
                    <>
                      <li className="messages-header">Older</li>
                      {chatHistory.older.slice(0, olderLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.older.length > olderLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </li>

              {/* Assessment History */}
              <li>
                <a
                  onClick={() => {
                    window.location.href = `/ProfileOfStudent?studentId=${studentId}`;
                  }}
                  style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                  <img
                    src={interview}
                    style={{ width: '25px' }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Assessment History</span>
                </a>
              </li>
              {/* Digital library */}
              <li>
                <a
                  href={`/studentDigitalLib?studentId=${studentId}`}
                  className="has-arrow"
                  style={{ cursor: 'pointer' }}
                  aria-expanded="false"
                >
                  <img
                    src={chat}
                    style={{ width: '25px' }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Digital Library</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* content */}
        <div class="content-body default-height">
          <div class="container-fluid">
            <div className=''>
              {isactive && (
                <div className='row'>
                  <div className=" col-xl-10 d-flex align-items-center">
                    <div className="input-section d-flex align-items-center flex-grow-1 w-100">
                      <input
                        type="text"
                        className="input-field form-control w-100"
                        placeholder="Type your message here..."
                        id="messageInput"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      <button className="btn btn-primary mx-3" onClick={handleSubmit}>
                        <i className="fas fa-paper-plane animate-icon" title="Submit"></i>
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-xl ms-5 my-2 assessment">
                    <a href='/InterviewBot' className="btn mx-2 mb-2" id="botBtn">
                      <img
                        src={assessment}
                        style={{ width: "40px", marginRight: "8px" }}
                        alt="Icon"
                        className="rounded-circle"
                      />
                    </a>

                    <Typewriter
                      options={{
                        loop: true, // Loop indefinitely
                      }}
                      onInit={(typewriter) => {
                        typewriter.typeString('<span style="margin-left: -45px; font-size: 16px;">Self </span>')
                          .typeString('<span style="font-size: 16px;">Assessment</span>')
                          .pauseFor(500)
                          .typeString(' 💼')
                          .pauseFor(500)
                          .deleteChars(2)
                          .typeString(' 📚')
                          .pauseFor(500)
                          .deleteChars(2)
                          .typeString(' 💡')
                          .pauseFor(500)
                          .deleteChars(2)
                          .pauseFor(500)
                          .start();
                      }}
                    />
                  </div> */}
                </div>
              )}
              <div className='row'>
                <div className="col-xl-12 ai-overview-section d-flex align-items-center mt-3 mb-3">
                  <img
                    src={star}
                    alt="AI Icon"
                    className="ai-overview-icon mr-2 heartbeat-animation"
                  />
                  {/* <a className="ai-overview-link">AI Overview</a> */}
                </div>

              </div>
              {isHelpSectionVisible && (
                <div className="help-section">
                  <h2 className="help-title">
                    <Typewriter
                      options={{
                        loop: true,
                      }}
                      onInit={(typewriter) => {
                        typewriter.typeString('How can I be of assitance today?')
                          .pauseFor(2500)
                          .start();
                      }}
                    />
                  </h2>
                  <div className="col-xl-12 d-flex align-items-center">
                    {/* Input Section */}
                    <div className="input-section d-flex align-items-center flex-grow-1 w-100">
                      <input
                        type="text"
                        className="input-field form-control w-100"
                        placeholder="Type your message here..."
                        id="messageInput"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      <button className="btn btn-primary mx-3" onClick={handleSubmit}>
                        <i className="fas fa-paper-plane animate-icon" title="Submit"></i>
                      </button>
                      {/* <button
                        className="btn btn-secondary mx-2"
                        id="clearBtn"
                        onClick={handleClear} // Handle Clear button click
                      >
                        <i className="fas fa-trash animate-icon" title="Clear"></i>
                      </button> */}
                    </div>
                  </div>
                  <p className="disclaimer">Learning is the only thing the mind never exhausts, never fears, and never regrets</p>
                </div>
              )}

              {/* Response Section */}
              <div className='row' style={{ maxWidth: '100%' }}>
                {isInfoBoxVisible && (
                  <div className="col-xl-10 info-box mx-3">
                    {currentData && (
                      loading ? (
                        <MyCodeLoader />
                      ) : (
                        <>
                          <h4 className="title">{userInput}</h4>

                          <div className="icon-text">
                            <img
                              src={network}
                              style={{ width: "30px" }}
                              alt="Icon"
                              className="rounded-circle"
                            />
                            <span>Sources</span>
                          </div>
                          <div className="sources cursor-pointer" id="source-container" style={{ cursor: "pointer" }}>
                            {currentData?.page_number?.map((content, index) => {
                              const fileName = currentData.file_paths[index]?.split('/').pop();
                              return (
                                <div key={index} className="source-item">
                                  <a
                                    className="tooltip-link cursor-pointer"
                                    onClick={() => openPdfPopup(currentData.file_paths[index], currentData.page_number[index])}
                                    style={{ textDecoration: 'none', wordBreak: 'break-word' }} // Ensures long text doesn't overflow
                                  >
                                    {fileName}
                                    <span className="tooltip">
                                      <span className="tooltip-content">
                                        {currentData.response.length > 25 ? currentData.response.substring(0, 150) + "..." : currentData.response}
                                      </span>
                                    </span>
                                  </a>
                                  <span className="source-name cursor-pointer d-flex align-items-center mb-2">
                                    <img
                                      src={chat}
                                      style={{ width: "20px", marginRight: "5px", borderRadius: "50%" }}
                                      alt="Icon"
                                      className="file-icon img-fluid"
                                    />
                                    <span className="text-truncate" style={{ maxWidth: '100%' }}>
                                      {"Page Number - " + content}
                                    </span>
                                  </span>
                                </div>
                              );
                            })}
                          </div>

                          <div className="icon-text">
                            <img
                              src={answer}
                              style={{ width: "30px" }}
                              alt="Icon"
                              className="rounded-circle"
                            />
                            <span>Answer</span>
                          </div>
                          <div className="description">
                            <div className="row align-items-center mb-3">
                              <div className="col-auto">
                                <h3 className="description-title m-0">{syncvizResponceForTitle}</h3>
                              </div>
                              <div className="col-auto ms-auto">
                                <div className="icon-container">
                                  <i
                                    className={`fa fa-thumbs-up like-icon me-2 ${feedback === "like" ? "active" : ""}`}
                                    title="Like"
                                    onClick={() => handleIconClick("like")}
                                    style={{ color: feedback === "like" ? "aqua" : "inherit" }}
                                  ></i>
                                  <i
                                    className={`fa fa-thumbs-down dislike-icon me-2 ${feedback === "dislike" ? "active" : ""}`}
                                    title="Dislike"
                                    onClick={() => handleIconClick("dislike")}
                                    style={{ color: feedback === "dislike" ? "aqua" : "inherit" }}
                                  ></i>
                                  <i
                                    className="fa fa-copy copy-icon"
                                    title="Copy"
                                    onClick={copyTextToClipboard}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <h6 className="text-white">
                              <Typewriter
                                options={{
                                  delay: 0,
                                  deleteSpeed: 50,
                                  loop: false,
                                }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .typeString(currentData.response)
                                    .start();
                                }}
                              />
                            </h6>
                          </div>
                          <div
                            className="icon-text"
                            onClick={() => !isLoading && toggleDescriptionFromGenie(userInput, currentData.response)}
                            style={{ display: "inline-flex", alignItems: "center", cursor: isLoading ? "not-allowed" : "pointer" }}
                          >
                            {isLoading ? (
                              <div className="loader" style={{ width: "30px", height: "30px", marginRight: "8px" }}></div> // Add your loader component here
                            ) : (
                              <img
                                src={answer}
                                style={{ width: "30px", marginRight: "8px" }}
                                alt="Icon"
                                className="rounded-circle"
                              />
                            )}
                            <span className="genie-answer" style={{ cursor: isLoading ? "not-allowed" : "pointer" }}>
                              {isLoading ? "" : "Genie Answer"}
                            </span>
                          </div>
                          {showDescription && (
                            <div className="description">
                              <div className="row align-items-center mb-3">
                                <div className="col-auto">
                                  <h3 className="description-title m-0">Genie response :</h3>
                                </div>
                                <div className="col-auto ms-auto">
                                  <div className="icon-container">
                                    <i className="fa fa-thumbs-up like-icon me-2" title="Like"></i>
                                    <i className="fa fa-thumbs-down dislike-icon me-2" title="Dislike"></i>
                                    <i
                                      className="fa fa-copy copy-icon"
                                      title="Copy"
                                      onClick={copyTextToClipboard}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div className="markdown-content text-white">
                                <ReactMarkdown>{genieAnswer}</ReactMarkdown>
                              </div>
                            </div>
                          )}

                        </>
                      )
                    )}
                  </div>
                )}
                {isInfoBoxVisible && (
                  <div className="col-xl my-2">
                    <button className="assessment-button" onClick={() => redirectToPage(userInput, currentData.response, selectedHubId)}>
                      Quick Assessment
                    </button>
                  </div>
                )}

                {/* Ranking Section */}
                {isSideVisible && (
                  <div className="col-xl ms-3 mt-4">
                    <div className="accordion accordion-primary accordion-with-icon" id="accordion-six">
                      <div className="accordion-item">
                        <h2 className="accordion-header accordion-header-primary" id="headingOne61">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne6" aria-expanded="false">
                            <span className="fas fa-robot me-2"></span>
                            <span className="accordion-header-text">AI Assessment</span>
                          </button>
                        </h2>
                        <div id="collapseOne6" className="accordion-collapse collapse show" aria-labelledby="headingOne61" data-bs-parent="#accordion-six">
                          <div className="big-image-container">
                            <img src={img1} alt="Big Image" className="big-card-img img-fluid" />
                            <span className="icon-overlay">
                              <img src={star} alt="AI Logo" className="ai-logo-img" />
                            </span>
                          </div>
                          <div className="image-row-row">
                            <div className="image-container">
                              <img src={img2} alt="Active Listening Skills" className="small-card-img img-fluid" />
                              <span className="icon-overlay">
                                <img src={star} alt="AI Logo" className="ai-logo-img" />
                              </span>
                            </div>
                            <div className="image-container">
                              <img src={img3} alt="Active Listening Skills" className="small-card-img img-fluid" />
                              <span className="icon-overlay">
                                <img src={star} alt="AI Logo" className="ai-logo-img" />
                              </span>
                            </div>
                          </div>
                          <div className="image-row-row">
                            <div className="image-container">
                              <img src={img4} alt="Active Listening Skills" className="small-card-img img-fluid" />
                              <span className="icon-overlay">
                                <img src={star} alt="AI Logo" className="ai-logo-img" />
                              </span>
                            </div>
                            <div className="image-container">
                              <img src={img5} alt="Active Listening Skills" className="small-card-img img-fluid" />
                              <span className="icon-overlay">
                                <img src={star} alt="AI Logo" className="ai-logo-img" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h2 className="accordion-header accordion-header-info" id="headingTwo6">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo6" aria-expanded="false">
                            <span className="fa fa-medal mb-2"></span>
                            <span className="accordion-header-text">Levels</span>
                          </button>
                        </h2>
                        <div id="collapseTwo6" className="accordion-collapse collapse" aria-labelledby="headingTwo6" data-bs-parent="#accordion-six">
                          <div className="col-xl-12">
                            <div className="card active_users">
                              <div className="card-header bg-primary border-0 pb-0">
                                <h4 className="card-title text-white">Active Users</h4>
                                <span id="counter"></span>
                              </div>
                              <div className="bg-primary">
                                <canvas id="activeUser" width="30" height="80px" style={{ display: 'block', boxSizing: 'border-box', height: '30px', width: '80px' }}></canvas>
                              </div>
                              <div className="card-body pt-0">
                                <div className="list-group-flush mt-4">
                                  <div className="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 fw-semi-bold border-top-0 border-0 border-bottom">
                                    <p className="mb-0">Top Active Pages</p>
                                    <span className="fw-bold">120</span>
                                  </div>
                                  <div className="list-group-item bg-transparent d-flex justify-content-between px-0 py-1 fw-semi-bold border-top-0 border-0 border-bottom">
                                    <p className="mb-0">Unread messages</p>
                                    <span className="fw-bold">33</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="footer" style={{ backgroundColor: '#2b2d42' }}>
          <div class="copyright text-white">
            <p>Copyright © Designed &amp; Developed by <a className='text-green' href="https://datamoo.ai/" target="blank">DataMoo AI</a> 2025</p>
          </div>
        </div>
      </div>

    </>
  );
}

export default KnowledgeScreen;