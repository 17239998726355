import React, { useEffect, useState } from 'react'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import SideBar from '../../Layouts/SideBar'
import Footer from '../../Layouts/Footer'
import "react-datepicker/dist/react-datepicker.css";
import { apiURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import axios from 'axios';
import Select from 'react-select';
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';
import { BaseRoutes } from '../../CommonConfig/BaseRoutes'; 

function BatchConfig() {
    const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
    const [endDate, setEndDate] = useState("");
    const [selectedOption, setSelectedOption] = useState('');
    const [students, setStudents] = useState([]);
    const [studentBasedOnTeam, setStudentBasedOnTeam] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");

    const [teamSearchTerm, setTeamSearchTerm] = useState("");
    const [teamItemsPerPage, setTeamItemsPerPage] = useState(5);
    const [teamCurrentPage, setTeamCurrentPage] = useState(1);

    const [options, setOptions] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [teams, setTeams] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [team, setTeam] = useState(null);
    const [teamId, setTeamId] = useState(null);
    const [option, setOption] = useState([]);
    const [isNameValid, setIsNameValid] = React.useState(true);
    const [isCourseValid, setIsCourseValid] = React.useState(true);
    const [isFromDateValid, setIsFromDateValid] = React.useState(true);
    const [isToDateValid, setIsToDateValid] = React.useState(true);
    const [isSessionValid, setIsSessionValid] = React.useState(true);
    const [isBranchNameValid, setIsBranchNameValid] = React.useState(true);
    const [isTeamValid, setIsTeamValid] = React.useState(true); // Validation state for team
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        studentid: "",
        rollno: "",
        department: "",
        name: "",
        number: "",
        emailid: "",
        password: "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        getData();
    }, []);

    function reloadPage() {
        window.location.reload();
    }

    // const handleSubmit = async (e) => {

    //     if (uploadedFiles.length === 0) {
    //         showAlert('Please select at least one file to upload', 'warning');
    //         return;
    //     }

    //     const formData = new FormData();
    //     uploadedFiles.forEach((file) => {
    //         formData.append('file', file);
    //     });

    //     try {
    //         const response = await axios.post(`${apiURL}/upload_team_lists`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         if (response.status === 200) {
    //             if (response.data.file_url != "") {
    //                 showAlert('File(s) uploaded successfully!', 'success');
    //                 getData();
    //             } else {
    //                 showAlert('File upload failed', 'error');
    //             }

    //         } else {
    //             showAlert('File upload failed', 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //         Swal.fire({
    //             title: 'Error uploading file',
    //             text: error.message,
    //             icon: 'error',
    //             confirmButtonText: 'OK',
    //             confirmButtonColor: '#2EA87E',
    //         });
    //     }
    // };

    const currentDate = new Date().toISOString().split("T")[0];
    const [formData, setFormData] = useState({
        Name: "",
        Course: "",
        FromDate: currentDate,
        ToDate: "",
        Session: "",
        BranchName: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSessionChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            Session: e.target.value,
        }));
    };

    const handleCreateBatch = async (e) => {
        e.preventDefault();

        // Validate required fields before proceeding
        validateSession();
        validateBranchName();
        validateToDate();
        validateCourse();
        validateFromDate();
        validateName();
        validateCourse();

        // Check if all required fields are valid
        if (!isSessionValid || !isBranchNameValid || !formData.Session || !formData.BranchName.trim()) {
            // If validation fails, show error and stop submission
            showAlert("Please fill all required fields.", "error");
            return;
        }

        // If all validations pass, proceed with creating the batch
        const apiData = {
            ...formData,
            created_by: 0,
            org_id: 0,
            team_name: "",
            students: [],
        };

        try {
            const response = await axios.post(`${apiURL}/create_team`, apiData, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
            });




            if (response.status === 200 || response.status === 201) {
                showAlert('Batch Created successfully!', 'success');

                // Reset form data
                setFormData({
                    Name: "",
                    Course: "",
                    FromDate: currentDate,
                    ToDate: "",
                    Session: "",
                    BranchName: "",
                });

                // Close the modal
                const modal = document.getElementById('basicModal');
                modal.style.display = 'none';
                modal.setAttribute('aria-hidden', 'true');
                modal.classList.remove('show');


                getData();
                fetchData();
                reloadPage();


            } else {
                showAlert("Failed to create batch!", 'error');
            }
        } catch (error) {
            console.error("Error creating batch:", error);
            showAlert("An error occurred while creating the batch.", 'error');
        }
    };


    const handleCreateTeam = async (event) => {
        // event.preventDefault();

        const payload = {
            org_id: 0,
            team_name: teamName,
            students: selectedStudents.map(student => student.value),
            created_by: 0
        };

        try {
            const response = await fetch(`${apiURL}/create_team`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            if (result.id != null) {
                showAlert('Team created successfully', 'success');
                getData();
            } else {
                showAlert('Team created failed', 'error');
            }
        } catch (error) {
            Swal.fire({
                title: 'Error uploading file',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };

    const showAlert = (message, type) => {
        Swal.fire({
            title: message,
            icon: type,
            confirmButtonText: 'OK',
            confirmButtonColor: '#2EA87E',
        });
    };

    const handleFileChange = (e) => {
        setUploadedFiles(Array.from(e.target.files));
    };

    const handleStudentUpload = async () => {
        if (!team) {
            Swal.fire({
                title: 'Batch not selected',
                text: 'Please select a batch before uploading.',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
            return;
        }

        if (uploadedFiles.length === 0) {
            Swal.fire({
                title: 'No file selected',
                text: 'Please select at least one file to upload.',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
            return;
        }

        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            formData.append('file', file);
        });

        try {
            const response = await axios.post(
                `${apiURL}/upload_student_lists?team_name=${team}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('API Response:', response);

            if (response.status === 200 || response.data?.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'File(s) uploaded successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#2EA87E',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUploadedFiles([]);
                        getData();
                        reloadPage();

                        const modal = document.getElementById('basicModal1');
                        modal.style.display = 'none';
                        modal.setAttribute('aria-hidden', 'true');
                        modal.classList.remove('show');
                    }
                });
            } else {
                Swal.fire({
                    title: 'Upload Failed',
                    text: response.data?.message || 'File upload failed. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#2EA87E',
                });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };


    const getData = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_team`);

            if (response.status === 200 && response.data && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => b.id - a.id);
                setTeams(sortedData);
            } else {
                // toastr.error('Failed to load students');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            window.location.href = BaseRoutes.Error500;
        } finally {
        }
    };

    const handleTeamSearch = (e) => {
        setTeamSearchTerm(e.target.value);
        setTeamCurrentPage(1); // Reset to first page on new search
    };

    const handleTeamItemsPerPageChange = (e) => {
        setTeamItemsPerPage(Number(e.target.value));
        setTeamCurrentPage(1); // Reset to first page on items-per-page change
    };

    const handleTeamPageChange = (newPage) => {
        setTeamCurrentPage(newPage);
    };

    const filteredTeams = teams.filter(
        (team) =>
            team.team_name || team.Name.toLowerCase().includes(teamSearchTerm.toLowerCase()) ||
            team.BranchName.toLowerCase().includes(teamSearchTerm.toLowerCase())
    );

    const totalTeamItems = filteredTeams.length;
    const totalTeamPages = Math.ceil(totalTeamItems / teamItemsPerPage);
    const paginatedTeams = filteredTeams.slice(
        (teamCurrentPage - 1) * teamItemsPerPage,
        teamCurrentPage * teamItemsPerPage
    );

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles(files);
    };

    const fetchStudents = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_Students`);
            if (response.status === 200 && response.data && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => a.id - b.id);
                setStudents(sortedData);
                setOptions(
                    sortedData.map((student) => ({
                        value: student.id,
                        label: student.name,
                    }))
                );
            } else {
                console.error("Failed to load students.");
            }
        } catch (error) {
            console.error("Error fetching students:", error);
        }
    };

    const handleViewClick = (teamId) => {
        setSelectedTeamId(teamId);
        showAllStudentsBasedOnTeam(teamId);
    };

    const showAllStudentsBasedOnTeam = async (teamId) => {

        try {
            const response = await axios.get(`${apiURL}/get_team_by_id/${teamId}`);

            if (response.status === 200 && response.data && response.data.data) {
                const studentsListBasedOnTeam = response.data.data.students
                setStudentBasedOnTeam(studentsListBasedOnTeam);
                setSelectedTeamId(teamId);
            } else {
                console.error('Failed to load students');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredStudents = students.filter(
        (student) =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalItems = filteredStudents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHour = hours % 12 || 12;
        // - ${formattedHour}:${minutes} ${ampm}
        return `${day} / ${month} / ${year}`;
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiURL}/get_all_team`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.message === "Success") {
                const formattedOptions = result.data.map((team) => ({
                    value: team.id,
                    label: team.Name || team.team_name || "Unnamed Team", // Fallback for null/undefined names
                }));
                setOption(formattedOptions);
                console.log(formattedOptions, '- formatted team options');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const response = await fetch(`${apiURL}/get_all_team`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const result = await response.json();

                if (result.message === 'Success') {
                    const formattedOptions = result.data.map((team) => ({
                        value: team.id, // Pass team.id for API calls
                        label: team.Name || team.team_name || 'Unnamed Team', // Display name
                    }));
                    setOption(formattedOptions);
                }
            } catch (error) {
                console.error('Error fetching team options:', error);
            }
        };

        fetchOptions();
    }, []);



    const handleDownloadTemplate = () => {
        window.open(`${apiURL}/download-template`, "_blank");
    };

    const handleDeleteTeam = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action will permanently delete the batch.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2EA87E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${apiURL}/delete_team/${id}`);
                    if (response.status === 200) {
                        showAlert('Team/Batch deleted successfully!', 'success');
                        getData();
                    } else {
                        showAlert('Failed to delete team/batch', 'error');
                    }
                } catch (error) {
                    console.error('Error deleting team/batch:', error);
                    showAlert('Error occurred while deleting the team/batch', 'error');
                }
            }
        });
    };

    const handleAddStudents = async () => {
        if (!teamId || selectedStudents.length === 0) {
            Swal.fire({
                title: 'Error!',
                text: 'Please select a batch and students.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }


        const studentIds = selectedStudents.map((student) => student.value);
        console.log(studentIds, teamId, '- selected values to add to batch');


        try {
            const response = await axios.put(`${apiURL}/add_team_students`, {
                team_id: teamId,
                student_ids: studentIds,
            });


            if (response.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Students successfully added to the batch.',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const modal = document.getElementById('basicModal1');
                        modal.style.display = 'none';
                        modal.setAttribute('aria-hidden', 'true');
                        modal.classList.remove('show');
                        setTeamId('');
                        setSelectedStudents([]);
                        getData();
                        reloadPage(); // Call reloadPage() only after "Ok" is clicked
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to update team students.',
                    icon: 'error',
                    confirmButtonText: 'Try Again'
                });
            }
        } catch (error) {
            console.error('Error updating team students:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while updating the students.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };



    const validateName = () => {
        const nameRegex = /^[\s\S]*$/; // This allows all characters, including spaces and special characters
        if (!formData.Name || !nameRegex.test(formData.Name)) {
            setIsNameValid(false);
        } else {
            setIsNameValid(true);
        }
    };

    const validateCourse = () => {
        const courseRegex = /^[a-zA-Z\s]+$/; // Only letters and spaces allowed
        if (!formData.Course || !courseRegex.test(formData.Course)) {
            setIsCourseValid(false);
        } else {
            setIsCourseValid(true);
        }
    };
    const validateFromDate = () => {
        if (!formData.FromDate) {
            setIsFromDateValid(false);
        } else {
            setIsFromDateValid(true);
        }
    };

    const validateToDate = () => {
        if (!formData.ToDate || new Date(formData.ToDate) < new Date(formData.FromDate)) {
            setIsToDateValid(false);
        } else {
            setIsToDateValid(true);
        }
    };

    const validateSession = () => {
        if (!formData.Session) {
            setIsSessionValid(false);
        } else {
            setIsSessionValid(true);
        }
    };

    const validateBranchName = () => {
        if (!formData.BranchName.trim()) {
            setIsBranchNameValid(false);
        } else {
            setIsBranchNameValid(true);
        }
    };

    const validateTeam = () => {
        if (!team) {
            setIsTeamValid(false);
        } else {
            setIsTeamValid(true);
        }
    };

    const handleSubmit = async (e, selectedTeamId) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const studentData = Object.fromEntries(formData.entries());
        try {
            const response = await axios.post(
                `${apiURL}/create_team_student?team_id=${selectedTeamId}`,
                {
                    org_id: 0,
                    ...studentData,
                },
                { headers: { "Content-Type": "application/json" } }
            );
            Swal.fire({
                title: "Success!",
                text: "Student created successfully!",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#2EA87E",
            });
            e.target.reset();
        } catch (error) {
            console.error("Error creating student:", error);
            Swal.fire({
                title: "Error!",
                text: "Failed to create student. Please try again.",
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#E74C3C",
            });
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formValues.studentid.trim()) newErrors.studentid = "Student ID is required.";
        if (!formValues.rollno.trim()) newErrors.rollno = "Roll No is required.";
        if (!formValues.department.trim()) newErrors.department = "Department is required.";
        if (!formValues.name.trim()) newErrors.name = "Name is required.";
        if (!formValues.number.trim()) {
            newErrors.number = "Mobile No is required.";
        } else if (!/^\d{10}$/.test(formValues.number)) {
            newErrors.number = "Enter a valid 10-digit Mobile No.";
        }
        if (!formValues.emailid.trim()) {
            newErrors.emailid = "Email ID is required.";
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.emailid)
        ) {
            newErrors.emailid = "Enter a valid Email ID.";
        }
        if (!formValues.password.trim()) {
            newErrors.password = "Password is required.";
        } else if (formValues.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            handleSubmit(e, selectedTeamId, formValues);

            // Close the modal
            const modal = document.getElementById('basicModal2');
            if (modal) {
                modal.style.display = 'none';
                modal.setAttribute('aria-hidden', 'true');
                modal.classList.remove('show');
            }
            reloadPage()
        }
    };

    const handleDownloadBatchExcel = () => {
        // Ensure you're using the full data (e.g., `teams` in your state or the complete data array).
        const data = teams.map((team, index) => ({
            "S.No": index + 1,
            "Batch Name": team.Name || team.team_name,
            Branch: team.BranchName,
            Course: team.Course,
            Session: team.Session,
            "Student Count": Array.isArray(team.students) ? team.students.length : 0,
            "Valid Upto": formatDate(team.ToDate),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Batch Data");

        XLSX.writeFile(workbook, "BatchData_All.xlsx");
    };

    const [editableRowId, setEditableRowId] = useState(null);
    const [updatedTeamData, setUpdatedTeamData] = useState({});

    const handleEditClick = (teamId, team) => {
        setEditableRowId(teamId);
        setUpdatedTeamData(team);
    };

    const handleInputChangeEdit = (e, field) => {
        const { value } = e.target;
        setUpdatedTeamData((prev) => ({
            ...prev,
            [field]: value || "",
        }));
    };

    const handleSaveClick = async (teamId) => {

        const originalTeam = paginatedTeams.find((team) => team.id === teamId);

        const updatedTeam = {
            ...originalTeam,
            ...updatedTeamData, // Override only updated fields
        };

        try {
            const response = await axios.put(`${apiURL}/update_team/${teamId}`, updatedTeam);
            if (response.status === 200) {
                Swal.fire({
                    title: "Success!",
                    text: "Batch updated successfully.",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#2EA87E",
                });
                setEditableRowId(null);
                getData();
            }
        } catch (error) {
            console.error("Error updating batch:", error);
            Swal.fire({
                title: "Error!",
                text: "Failed to update batch.",
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#E74C3C",
            });
        }
    };

    const [visibleColumns, setVisibleColumns] = useState({
        serial: true,
        batchName: true,
        branch: true,
        course: true,
        session: true,
        studentCount: true,
        studentDetails: true,
        validUpto: true,
        actions: true,
    });

    const toggleColumnVisibility = (col) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [col]: !prevState[col], 
        }));
    };

    const deleteStudentFromTeam = async (teamId, studentId) => {
        if (!teamId || !studentId) {
            console.error("Team ID or Student ID is missing");
            return;
        }
    
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it',
            });
    
            if (!result.isConfirmed) return;
    
            const response = await axios.delete(
                `${apiURL}/delete_student?team_id=${teamId}&student_id=${studentId}`,
                {
                    headers: { accept: "application/json" },
                }
            );

            if (response.status === 200 && response.data.message.includes("removed successfully")) {
                Swal.fire('Deleted!', `Student ${studentId} has been removed from the team.`, 'success');
                setStudentBasedOnTeam((prevStudents) =>
                    prevStudents.filter((student) => student.id !== studentId)
                );
            } else {
                console.error("Failed to delete student:", response.data);
                Swal.fire('Error', 'Failed to delete student: ' + (response.data.message || 'Unknown error'), 'error');
            }
        } catch (error) {
            console.error("Error deleting student:", error.response?.data || error.message);
            Swal.fire('Error', 'There was an issue with the deletion', 'error');
        }
    };
    
    
    return (
        <div id="main-wrapper">
            <NavBar />
            <Header />
            <SideBar />
            <div class="content-body default-height">
                <div class="container-fluid">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Batch</h4>
                        </div>

                        <div class="card-body">
                            <div class="d-flex flex-wrap align-items-center justify-content-between">
                                <div class="align-items-center d-flex flex-wrap">
                                    <div>
                                        <button type="button" class="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">Create Batch</button>
                                        <button type="button" class="btn btn-primary mb-2 mx-4" data-bs-toggle="modal" data-bs-target="#basicModal1">Add Student</button>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div class="modal fade bd-example-modal-lg" id="basicModal" data-bs-backdrop="static" style={{ display: 'none' }} aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Create Batch</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleCreateBatch}>
                                                <div className="row">
                                                    {/* Name Field */}
                                                    <div className="mb-3 col-6">
                                                        <label className="form-label">
                                                            Batch Name <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${!isNameValid && 'is-invalid'}`}
                                                            placeholder="Enter Name"
                                                            name="Name"
                                                            value={formData.Name}
                                                            onChange={handleInputChange}
                                                            onBlur={validateName}
                                                        />
                                                        {!isNameValid && (
                                                            <div className="invalid-feedback">Name is required and should only contain letters.</div>
                                                        )}
                                                    </div>

                                                    {/* Course Field */}
                                                    <div className="mb-3 col-6">
                                                        <label className="form-label">
                                                            Course <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${!isCourseValid && 'is-invalid'}`}
                                                            placeholder="Enter Course"
                                                            name="Course"
                                                            value={formData.Course}
                                                            onChange={handleInputChange}
                                                            onBlur={validateCourse}
                                                        />
                                                        {!isCourseValid && (
                                                            <div className="invalid-feedback">Course is required and should only contain letters.</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* From Date Field */}
                                                    <div className="col-xl-6 col-md-6 mb-4">
                                                        <label className="form-label">
                                                            From Date <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className={`form-control ${!isFromDateValid && 'is-invalid'}`}
                                                            name="FromDate"
                                                            value={formData.FromDate}
                                                            onChange={handleInputChange}
                                                            onBlur={validateFromDate}
                                                        />
                                                        {!isFromDateValid && (
                                                            <div className="invalid-feedback">From Date is required.</div>
                                                        )}
                                                    </div>

                                                    {/* To Date Field */}
                                                    <div className="col-xl-6 col-md-6 mb-4">
                                                        <label className="form-label">
                                                            To Date <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className={`form-control ${!isToDateValid && 'is-invalid'}`}
                                                            name="ToDate"
                                                            value={formData.ToDate}
                                                            onChange={handleInputChange}
                                                            onBlur={validateToDate}
                                                        />
                                                        {!isToDateValid && (
                                                            <div className="invalid-feedback">
                                                                To Date is required and must be later than or equal to From Date.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* Session Field */}
                                                    <div className="mb-3 col-6">
                                                        <label className="form-label">
                                                            Session <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            className={`form-control ${!isSessionValid && 'is-invalid'}`}
                                                            name="Session"
                                                            value={formData.Session}
                                                            onChange={handleSessionChange}
                                                            onBlur={validateSession}
                                                        >
                                                            <option value="">Select Session</option>
                                                            <option value="FN">FN</option>
                                                            <option value="AN">AN</option>
                                                        </select>
                                                        {!isSessionValid && (
                                                            <div className="invalid-feedback">Please select a session.</div>
                                                        )}
                                                    </div>

                                                    {/* Branch Name Field */}
                                                    <div className="mb-3 col-6">
                                                        <label className="form-label">
                                                            Branch Name <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${!isBranchNameValid && 'is-invalid'}`}
                                                            placeholder="Enter Branch Name"
                                                            name="BranchName"
                                                            value={formData.BranchName}
                                                            onChange={handleInputChange}
                                                            onBlur={validateBranchName}
                                                        />
                                                        {!isBranchNameValid && (
                                                            <div className="invalid-feedback">Branch Name is required.</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary" style={{ float: 'right' }}>
                                                    Create
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="basicModal1" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Add Students</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                            </button>
                                        </div>
                                        <div class="">
                                            <div className="card-body">
                                                <div className="basic-form">
                                                    <div class="card-tabs">
                                                        <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                                            <li class=" nav-item" role="presentation">
                                                                <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab" tabindex="-1">Bulk Upload</a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Single Upload</a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div class="tab-content">
                                                        <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                                            <div class="row">
                                                                <div class="row" style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <div className='col-12 px-1'>
                                                                        <button
                                                                            type="submit"
                                                                            style={{ float: 'right' }}
                                                                            class="btn btn-primary btn-sm"
                                                                            onClick={handleDownloadTemplate}
                                                                        >
                                                                            Download Template
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div className="align-items-center d-flex flex-wrap">

                                                                    <div className="col-xl-12 me-4">
                                                                        <label className="text-primary fw-bold ms-2 mb-0">Select Batch</label>
                                                                        <select
                                                                            className={`select form-control wide mt-1 ${!isTeamValid ? 'border border-danger' : ''}`}
                                                                            value={team}
                                                                            onChange={(e) => setTeam(e.target.value)} // Pass name as value
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {option.map((opt, index) => (
                                                                                <option key={index} value={opt.label}> {/* Use label as value */}
                                                                                    {opt.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                {/* File Upload */}
                                                                <div className="row">
                                                                    <div className="mb-3 mt-4 col-md-12">
                                                                        <label className="text-primary fw-bold ms-2 mb-1">Upload Document</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            multiple
                                                                            onChange={handleFileChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 mt-3 mx-1 row d-flex flex-wrap justify-content-start">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        style={{}}
                                                                        onClick={handleStudentUpload}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div id="navpills2-21" class="tab-pane" role="tabpanel">
                                                            <div class="row">
                                                                <div className="basic-form">
                                                                    <div className="align-items-center d-flex flex-wrap">
                                                                        <div className="col-xl-12 me-4">
                                                                            <label className="text-primary fw-bold ms-2 mb-0">Select Batch</label>
                                                                            <select
                                                                                className="select form-control wide my-2"
                                                                                value={teamId}
                                                                                onChange={(e) => setTeamId(e.target.value)} // Pass ID as value
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {option.map((opt, index) => (
                                                                                    <option key={index} value={opt.value}> {/* Use value as ID */}
                                                                                        {opt.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    {/* Multi-Select for Students */}
                                                                    <div className="">
                                                                        <label className="text-primary fw-bold ms-2 mb-0">Select Student</label>
                                                                        <Select
                                                                            className="my-2"
                                                                            options={options} // Assumes fetched student options
                                                                            isMulti
                                                                            value={selectedStudents}
                                                                            onChange={setSelectedStudents}
                                                                            placeholder="Please select students"
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mt-2"
                                                                        onClick={handleAddStudents}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade bd-example-modal-lg" id="basicModal2" data-bs-backdrop="static" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Students</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                        </div>
                                        <form onSubmit={onSubmit}>
                                            <div className="modal-body">
                                                <div className="card-body">
                                                    <div className="basic-form">
                                                        <div className="row mt-3">
                                                            {[
                                                                { label: "Student ID", name: "studentid" },
                                                                { label: "Roll No", name: "rollno" },
                                                                { label: "Department", name: "department" },
                                                                { label: "Name", name: "name" },
                                                                { label: "Mobile No", name: "number" },
                                                                { label: "Email ID", name: "emailid" },
                                                                { label: "Password", name: "password" },
                                                            ].map((field, index) => (
                                                                <div className="mb-3 col-xl-6" key={index}>
                                                                    <label className="form-label">{field.label}</label>
                                                                    <input
                                                                        type={field.name === "password" ? "password" : "text"}
                                                                        className="form-control"
                                                                        placeholder={`Enter ${field.label}`}
                                                                        name={field.name}
                                                                        value={formValues[field.name]}
                                                                        onChange={onInputChange}
                                                                        required
                                                                    />
                                                                    {errors[field.name] && (
                                                                        <small className="text-danger">{errors[field.name]}</small>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Create
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="card-body">
                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                    <div className="col-xl-6">
                                        <div className="input-group mb-2">
                                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                                            <input
                                                type="text"
                                                className="form-control wide"
                                                placeholder="Search by Batch Name or Branch Name"
                                                value={teamSearchTerm}
                                                onChange={handleTeamSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <select
                                            className="form-control wide"
                                            value={teamItemsPerPage}
                                            onChange={handleTeamItemsPerPageChange}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <option value="5">5 - items per page</option>
                                            <option value="10">10 - items per page</option>
                                            <option value="15">15 - items per page</option>
                                            <option value="20">20 - items per page</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <div className="mb-3 d-flex align-items-center">

                                        <button
                                            className="btn btn-primary btn-sm me-3"
                                            onClick={handleDownloadBatchExcel}
                                            title="Download Excel"
                                        >
                                            <i className="fas fa-file-excel"></i> Download Excel
                                        </button>


                                        <div className="dropdown">
                                            <button
                                                className="btn btn-primary btn-sm dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Select Columns
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                {[
                                                    { label: "S.No", value: "serial" },
                                                    { label: "Batch Name", value: "batchName" },
                                                    { label: "Branch", value: "branch" },
                                                    { label: "Course", value: "course" },
                                                    { label: "Session", value: "session" },
                                                    { label: "Student Count", value: "studentCount" },
                                                    { label: "Student Details", value: "studentDetails" },
                                                    { label: "Valid Upto", value: "validUpto" },
                                                    { label: "Actions", value: "actions" },
                                                ].map(({ label, value }) => (
                                                    <li key={value}>
                                                        <label className="dropdown-item">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input me-2"
                                                                checked={visibleColumns[value]}
                                                                onChange={() => toggleColumnVisibility(value)}
                                                            />
                                                            {label}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <table className="table table-responsive-md">
                                        <thead className="mx-auto text-center">
                                            <tr>
                                                {visibleColumns.serial && <th>S.No</th>}
                                                {visibleColumns.batchName && <th>Batch Name</th>}
                                                {visibleColumns.branch && <th>Branch</th>}
                                                {visibleColumns.course && <th>Course</th>}
                                                {visibleColumns.session && <th>Session</th>}
                                                {visibleColumns.studentCount && <th>Student Count</th>}
                                                {visibleColumns.studentDetails && <th>Student Details</th>}
                                                {visibleColumns.validUpto && <th>Valid Upto</th>}
                                                {visibleColumns.actions && <th>Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody className="mx-auto text-center">
                                            {paginatedTeams.length > 0 ? (
                                                paginatedTeams.map((team, index) => (
                                                    <tr key={team.id}>
                                                        {visibleColumns.serial && (
                                                            <td>{(teamCurrentPage - 1) * teamItemsPerPage + index + 1}</td>
                                                        )}
                                                        {visibleColumns.batchName && (
                                                            <td>
                                                                {editableRowId === team.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={updatedTeamData.Name || ""}
                                                                        onChange={(e) => handleInputChangeEdit(e, "Name")}
                                                                    />
                                                                ) : (
                                                                    team.Name || team.team_name
                                                                )}
                                                            </td>
                                                        )}
                                                        {visibleColumns.branch && (
                                                            <td>
                                                                {editableRowId === team.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={updatedTeamData.BranchName || ""}
                                                                        onChange={(e) => handleInputChangeEdit(e, "BranchName")}
                                                                    />
                                                                ) : (
                                                                    team.BranchName
                                                                )}
                                                            </td>
                                                        )}
                                                        {visibleColumns.course && (
                                                            <td>
                                                                {editableRowId === team.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={updatedTeamData.Course || ""}
                                                                        onChange={(e) => handleInputChangeEdit(e, "Course")}
                                                                    />
                                                                ) : (
                                                                    team.Course
                                                                )}
                                                            </td>
                                                        )}
                                                        {visibleColumns.session && (
                                                            <td>
                                                                {editableRowId === team.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={updatedTeamData.Session || ""}
                                                                        onChange={(e) => handleInputChangeEdit(e, "Session")}
                                                                    />
                                                                ) : (
                                                                    team.Session
                                                                )}
                                                            </td>
                                                        )}
                                                        {visibleColumns.studentCount && <td>{team.students.length}</td>}
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs btn-success light"
                                                                data-bs-toggle="modal"
                                                                data-bs-target=".std-view-modal"
                                                                onClick={() => handleViewClick(team.id)}
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                        {visibleColumns.validUpto && (<td>{(formatDate(team.ToDate))}</td>)}
                                                        {visibleColumns.actions && (
                                                            <td>
                                                                {editableRowId === team.id ? (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-xs btn-success"
                                                                            onClick={() => handleSaveClick(team.id)}
                                                                        >
                                                                            <i className="fas fa-check"></i>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-xs btn-warning"
                                                                            onClick={() => setEditableRowId(null)}
                                                                        >
                                                                            <i className="fas fa-close"></i>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-xs btn-warning light"
                                                                            onClick={() => handleEditClick(team.id, team)}
                                                                        >
                                                                            <i className="fas fa-pen"></i>
                                                                        </button>
                                                                        {/* <button
                                                                            type="button"
                                                                            className="btn btn-xs btn-danger light"
                                                                            onClick={() => handleDeleteTeam(team.id)}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button> */}
                                                                    </>
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="9">No Batch available</td>
                                                </tr>
                                            )}
                                        </tbody>

                                    </table>
                                </div>

                                <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                    <div className="text-dark">
                                        Showing <b className="text-primary">{paginatedTeams.length}</b> of{" "}
                                        <b className="text-primary">{totalTeamItems}</b> entries
                                    </div>
                                    <div className="text-dark">
                                        <span className="me-4">
                                            Page <b className="text-primary">{teamCurrentPage}</b> of{" "}
                                            <b className="text-primary">{totalTeamPages}</b>
                                        </span>
                                        <button
                                            className="btn btn-xs btn-primary"
                                            onClick={() => handleTeamPageChange(teamCurrentPage - 1)}
                                            disabled={teamCurrentPage === 1 || totalTeamPages === 0}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button
                                            className="btn btn-xs btn-primary ms-2"
                                            onClick={() => handleTeamPageChange(teamCurrentPage + 1)}
                                            disabled={teamCurrentPage === totalTeamPages || totalTeamPages === 0}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>


                                {/* MODAL  TO  VIEW  STUDENT  DETAILS */}
                                <div class="modal fade std-view-modal" tabindex="-1" style={{ display: 'none' }} data-bs-backdrop="static" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h3 class="modal-title text-primary">Student Details</h3>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                </button>
                                            </div>
                                            <div class="d-flex flex-wrap align-items-center justify-content-between mt-4 mx-5">
                                                <div class="align-items-center d-flex flex-wrap">
                                                    <div>
                                                        <button type="button" class="btn btn-primary mb-2 mx-4" data-bs-toggle="modal" data-bs-target="#basicModal2">Add Student to Batch</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-body">
                                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                    <div className="col-xl-6">
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-text"><i className="fas fa-search"></i></div>
                                                            <input
                                                                type="text"
                                                                className="form-control wide"
                                                                placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                value={searchTerm}
                                                                onChange={handleSearch}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <select
                                                            className="form-control wide"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <option value="5">5 - items per page</option>
                                                            <option value="10">10 - items per page</option>
                                                            <option value="15">15 - items per page</option>
                                                            <option value="20">20 - items per page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="table-responsive" style={{ overflow: 'auto', minHeight: '50vh', maxHeight: '50vh' }}>
                                                    <table className="table table-responsive-md">
                                                        <thead className="mx-auto text-center">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Student Name</th>
                                                                <th>Roll Number</th>
                                                                <th>Email ID</th>
                                                                <th>Password</th>
                                                                <th>Mobile Number</th>
                                                                <th>Department</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="mx-auto text-center">
                                                            {studentBasedOnTeam?.map((student, index) => (
                                                                <tr key={student.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{student.name}</td>
                                                                    <td>{student.rollno}</td> {/* Display roll number */}
                                                                    <td>{student.emailid}</td>
                                                                    <td>{student.password}</td>
                                                                    <td>{student.number}</td>
                                                                    <td>{student.department}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-xs btn-danger light ms-2"
                                                                            title="Delete User"
                                                                            onClick={() => deleteStudentFromTeam(selectedTeamId, student.id)} // Pass `id` field
                                                                        >
                                                                            <i className="fas fa-times"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                    <div className="text-dark">
                                                        Showing <b className="text-primary">{studentBasedOnTeam.length}</b> of{" "}
                                                        <b className="text-primary">{studentBasedOnTeam.length}</b> entries
                                                    </div>
                                                    <div className="text-dark">
                                                        <span className="me-4">
                                                            Page <b className="text-primary">{currentPage}</b> of{" "}
                                                            <b className="text-primary">{totalPages}</b>
                                                        </span>
                                                        <button
                                                            className="btn btn-xs btn-primary"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1 || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-left"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-xs btn-primary ms-2"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages || totalPages === 0}
                                                        >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}

export default BatchConfig