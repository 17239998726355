import React from 'react'
import Header from '../../Layouts/Header'
import NavBar from '../../Layouts/NavBar'
import SideBar from '../../Layouts/SideBar'
import Footer from '../../Layouts/Footer'

function Error400() {
    return (
        <div id="main-wrapper">
            <NavBar />
            <Header />
            <SideBar />
            <div className="content-body default-height">
                <div className="container-fluid">
                    <div class="row d-flex justify-content-center align-items-center vh-100">
                        <div class="col-md-6">
                            <div class="form-input-content text-center error-page">
                                <h1 class="error-text font-weight-bold">400</h1>
                                <h4><i class="fa fa-thumbs-down text-danger"></i> Bad Request</h4>
                                <p>Your Request resulted in an error</p>
                                <div>
                                    <a class="btn btn-primary" href="index.html">Back to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Error400