import React, { useState } from 'react';
import blogo from '../../imgs/FullLogo.png';
import { BaseRoutes } from '../../CommonConfig/BaseRoutes';
import Swal from 'sweetalert2';
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';

function SignIn() {
    const [activeTab, setActiveTab] = useState('admin');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const credentials = {
        admin: { username: 'adminUser', password: 'admin123' },
    };

    const handleLogin = async (role) => {

        if (role === "user") {
            try {
                // Construct the URL with query parameters for email and password
                const response = await axios.post(
                    `${apiURL}/student_signin?emailid=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
                    null, // No body content for this request
                    { headers: { 'accept': 'application/json' } } // Set headers if needed
                );

                if (response.status === 200 && response.data) {
                    // Storing response data in sessionStorage and localStorage
                    sessionStorage.setItem('authToken', response.data.token);
                    sessionStorage.setItem('role', role);
                    sessionStorage.setItem('username', username);
                    sessionStorage.setItem('id', response.data.id);
                    sessionStorage.setItem('emailid', response.data.emailid);

                    localStorage.setItem('authToken', response.data.token);
                    localStorage.setItem('role', role);
                    localStorage.setItem('username', username);
                    localStorage.setItem('id', response.data.id);
                    localStorage.setItem('emailid', response.data.emailid);

                    // Assuming the hub_id is returned from the response
                    const hubId = response.data.hub_id || 1; // Fallback to 1 if hub_id is not available
                    sessionStorage.setItem('hub_id', hubId);
                    localStorage.setItem('hub_id', hubId);

                    // Redirect to the appropriate screen after successful login
                    window.location.href = BaseRoutes.KnowledgeScreen;
                } else {
                    Swal.fire('Login failed', 'Check username & password');
                }
            } catch (error) {
                console.error('Error making POST request:', error);
                Swal.fire('Login failed', 'Check username & password');
            }
        } else {
            if (username === credentials[role].username && password === credentials[role].password) {
                sessionStorage.setItem('authToken', 'dummyToken');
                sessionStorage.setItem('role', role);
                sessionStorage.setItem('username', username);
                localStorage.setItem('authToken', 'dummyToken');
                localStorage.setItem('role', role);
                localStorage.setItem('username', username);
                localStorage.setItem('id', 0); // Admin has no specific ID

                if (role === "admin") {
                    window.location.href = BaseRoutes.Dashboard;
                } else {
                    window.location.href = BaseRoutes.KnowledgeScreen;
                }
            } else {
                Swal.fire('Login failed', 'Invalid username or password');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="fix-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="card mb-0 h-auto">
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    <a href={BaseRoutes.Dashboard}>
                                        <img className="logo-auth" src={blogo} style={{ width: 200 }} alt="Logo" />
                                    </a>
                                </div>
                                <div className="card-body">
                                    <div className="card-tabs d-flex justify-content-center align-items-center">
                                        <ul className="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === 'admin' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('admin')}
                                                >
                                                    Sign In as Admin
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === 'user' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('user')}
                                                >
                                                    Sign In as User
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        <div
                                            id="navpills2-11"
                                            className={`tab-pane ${activeTab === 'admin' ? 'active show' : ''}`}
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <h4 className="text-center mb-4">Sign In as Admin</h4>
                                                <form autoComplete="off">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label" htmlFor="username">Username</label>
                                                        <input type="text" className="form-control" placeholder="Enter username"
                                                            id="username" autoComplete="username"
                                                            value={username} onChange={(e) => setUsername(e.target.value)} />
                                                    </div>
                                                    <div className="mb-sm-4 mb-3 position-relative">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        <input
                                                            type={passwordVisible ? 'text' : 'password'}
                                                            id="password" autoComplete="current-password"
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <span className="show-pass eye" onClick={togglePasswordVisibility}>
                                                            <i className={`fa ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`} />
                                                        </span>
                                                    </div>
                                                    <div className="text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-block"
                                                            onClick={() => handleLogin('admin')}
                                                        >
                                                            Sign in as Admin
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div
                                            id="navpills2-21"
                                            className={`tab-pane ${activeTab === 'user' ? 'active show' : ''}`}
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <h4 className="text-center mb-4">Sign In as User</h4>
                                                <form autoComplete="off">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label" htmlFor="username">E-Mail ID</label>
                                                        <input type="email" className="form-control" placeholder="Enter e-mail"
                                                            id="username" autoComplete="username"
                                                            value={username} onChange={(e) => setUsername(e.target.value)} />
                                                    </div>
                                                    <div className="mb-sm-4 mb-3 position-relative">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        <input
                                                            type={passwordVisible ? 'text' : 'password'}
                                                            id="password" autoComplete="current-password"
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <span className="show-pass eye" onClick={togglePasswordVisibility}>
                                                            <i className={`fa ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`} />
                                                        </span>
                                                    </div>
                                                    <div className="text-center">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-block"
                                                            onClick={() => handleLogin('user')}
                                                        >
                                                            Sign in as User
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;