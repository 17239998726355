import React from 'react';
import { BaseRoutes } from '../CommonConfig/BaseRoutes';

function SideBar({userRole}) {
    return (
        <div className="dlabnav">
            <div className="dlabnav-scroll">
                <ul className="metismenu" id="menu">
                    <li>
                        <a href={BaseRoutes.Dashboard} aria-expanded="false">
                            <i className="fas fa-home"></i>
                            <span className="nav-text">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a href={BaseRoutes.Adminsummary} aria-expanded="false">
                            <i className="fas fa-book"></i>
                            <span className="nav-text">Digital Library</span>
                        </a>
                    </li>
                     <li>
                        <a href={BaseRoutes.StudentProfile} aria-expanded="false">
                            <i className="fas fa-user"></i>
                            <span className="nav-text">Student Profile</span>
                        </a>
                    </li> 

                    {/* {sessionStorage.getItem('role') === "admin" ? (<li>
                        <a  aria-expanded="false">
                        <i className="fas fa-chart-line"></i>
                            <span className="nav-text">Analytics</span>
                        </a>
                        <ul aria-expanded="false">
                            <li><a href={BaseRoutes.StudentProfile}>Student Analytics</a></li>
                            <li><a href={BaseRoutes.HubProfile}>Hub Analytics</a></li>
                        </ul>
                        
                    </li>) : null} */}

                   {sessionStorage.getItem('role')=== "admin" ? ( <li>
                        <a  aria-expanded="false" style={{cursor:'pointer'}}>
                        <i className="fas fa-tools"></i>
                            <span className="nav-text">Configuration</span>
                        </a>
                        <ul aria-expanded="false">
                            <li><a href={BaseRoutes.StudentCreation}>Student</a></li>
                            <li><a href={BaseRoutes.BatchConfig}>Batch</a></li>
                            {/* <li><a href={BaseRoutes.CreateTeam}>Team Creation</a></li> */}
                            <li><a href={BaseRoutes.BotSummary}>Hub</a></li>
                            {/* <li><a href={BaseRoutes.KnowledgeBotConfig}>Knowledge Hub</a></li>
                            <li><a href={BaseRoutes.interviewBotCongfig}>Assessment Hub</a></li> */}
                            {/* <li><a href={BaseRoutes.HubInfo}>Training Summary</a></li> */}
                            <li><a href={BaseRoutes.AssignTeam}>Assign Batch</a></li>
                            {/* <li><a href={BaseRoutes.BotSummary}>Summary</a></li> */}
                        </ul>
                        
                    </li>) : null}
                  {sessionStorage.getItem('role') === "admin" ?(  <li>
                        <a  aria-expanded="false" style={{cursor:'pointer'}}>
                            <i className="fas fa-robot"></i>
                            <span className="nav-text">Summary</span>
                        </a>
                        <ul aria-expanded="false">
                            {/* <li><a href={BaseRoutes.BotSummary}>Summary</a></li> */}
                            <li><a href={BaseRoutes.DocSummary}>Document Summary</a></li>
                            {/* <li><a href={BaseRoutes.InterviewBot}>Assesment Hub</a></li> */}
                        </ul>
                        
                    </li>): null}
                    {/* <li>
                        <a href="#" aria-expanded="false">
                            <i className="fas fa-robot"></i>
                            <span className="nav-text">Bot</span>
                        </a>
                        <ul aria-expanded="false">
                            <li><a href={BaseRoutes.KnowledgeScreen}>Knowledge Hub</a></li>
                            <li><a href={BaseRoutes.InterviewBot}>Assesment Hub</a></li>
                        </ul>
                        
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

export default SideBar;