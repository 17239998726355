import React, { useEffect, useState } from 'react';
import chat from '../../imgs/chat.gif';
import interview from '../../imgs/online-interview.gif';
// import vlogo from '../../imgs/verandaLog.png';
import vlogo from '../../imgs/backgroundPngLogo.png';
import axios from 'axios';
import { apiURL, wssURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { Doughnut } from 'react-chartjs-2';
import aiicon from '../../imgs/AI_icon.png';
import { FaArrowLeft } from 'react-icons/fa'; // Font Awesome for back icon (optional)


function ProfileOfStudent() {



    const [userInput, setUserInput] = useState('');
    const [currentData, setCurrentData] = useState({ response: "", file_paths: [], page_number: [], references: [], file_contents: [] });
    const [isInfoBoxVisible, setIsInfoBoxVisible] = useState(false);
    const [isSideVisible, setIsSideVisible] = useState(false);
    const [isHelpSectionVisible, setIsHelpSectionVisible] = useState(true);
    const [isactive, setIsactive] = useState(false);
    const [assigneddata, setAssigneddata] = useState([]);
    const [assignedHubdata, setAssignedHubdata] = useState([]);
    const navigate = useNavigate();
    const [showDescription, setShowDescription] = useState(false);
    const [genieAnswer, setGenieAnswer] = useState("");
    const [selectedHubId, setSelectedHubId] = useState(0);
    const [studentId, setStudentId] = useState();
    const [chatHistory, setChatHistory] = useState([]);
    const [questionId, setQuestionId] = useState();
    const [todayLimit, setTodayLimit] = useState(5);
    const [yesterdayLimit, setYesterdayLimit] = useState(5);
    const [thisMonthLimit, setThisMonthLimit] = useState(5);
    const [thisYearLimit, setThisYearLimit] = useState(5);
    const [olderLimit, setOlderLimit] = useState(5);

    const [students, setStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [testData, setTestData] = useState({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
    const [testDataQuick, setTestDataQuick] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataAiDesc, setTestDataAiDesc] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataAiMcq, setTestDataAiMcq] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataQbankDesc, setTestDataQbankDesc] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataQbankMcq, setTestDataQbankMcq] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [selectedQuestionData, setSelectedQuestionData] = useState([]);
    const [selectedQuestionDataAiMcq, setSelectedQuestionDataAiMcq] = useState([]);
    const [selectedQuestionDataQbankMcq, setSelectedQuestionDataQbankMcq] = useState([]);
    const [selectedQuestionDataAiDesc, setSelectedQuestionDataAiDesc] = useState([]);
    const [selectedQuestionDataQbankDesc, setSelectedQuestionDataQbankDesc] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [aiSearchTerm, setAiSearchTerm] = useState("");
    const [aiCurrentPage, setAiCurrentPage] = useState(1);
    const [aiItemsPerPage, setAiItemsPerPage] = useState(5);
    const [mcqSearchTerm, setMcqSearchTerm] = useState("");
    const [mcqCurrentPage, setMcqCurrentPage] = useState(1);
    const [mcqItemsPerPage, setMcqItemsPerPage] = useState(5);
    const [qbankSearchTerm, setQbankSearchTerm] = useState("");
    const [qbankCurrentPage, setQbankCurrentPage] = useState(1);
    const [qbankItemsPerPage, setQbankItemsPerPage] = useState(5);

    const goBack = () => {
        navigate(-1); // Navigates to the previous page
    };
    useEffect(() => {
        const id = sessionStorage.getItem("id");
        setStudentId(id);

        if (id) {
            // Call functions to fetch data for the student
            fetchStudentDetails(id);
            fetchStudentTestData(id);
            fetchStudentQuickAssData(id);
            fetchStudentAIDescASSData(id);
            fetchStudentAIMCQASSData(id);
            fetchStudentQbankDesc(id);
            fetchStudentQbankMcq(id);
        }
    }, []);

    const verandaResponseForTitle = "Veranda response :";
    const syncvizResponceForTitle = "Syncviz response :";

    console.log(assigneddata, "assigneddata")

    const [activeMenu, setActiveMenu] = useState(null);

    const handleToggle = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };

    const [isSidebarExpanded, setSidebarExpanded] = useState(false);

    const toggleSidebar = () => {
        setSidebarExpanded((prevState) => !prevState);
    };

    const handleClear = () => {
        setIsInfoBoxVisible(false);
        setIsSideVisible(false);
        setUserInput('');
        setIsactive(false)
        setCurrentData(null);
        setIsHelpSectionVisible(true);
        setShowDescription(false);
    };
    // useEffect(() => {
    //   console.log("Updated currentData Response:", currentData.response);
    //   console.log("Updated currentData FilePath:", currentData.file_paths);
    //   console.log("Updated currentData Page Number:", currentData.page_number);
    //   console.log("Updated currentData References:", currentData.references);
    //   console.log("Updated currentData File Contents:", currentData.file_contents);
    // }, [currentData]);

    const redirectToPage = (query, response) => {
        navigate(`/AssessmentMcq?query=${encodeURIComponent(query)}&response=${encodeURIComponent(response)}&hubId=${encodeURIComponent(selectedHubId)}`);
    };

    const handleLogoutClick = () => {
        Swal.fire({
            title: 'Do you want to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                sessionStorage.clear();
                localStorage.clear();
                navigate('/');
            }
        });
    };



    const handleResponse = (verandaResponse, genieResponse = null) => {

        const studentId = sessionStorage.getItem("id");
        if (!studentId) {
            console.error("No student ID found in session storage.");
            return;
        }

        const payload = {
            studentid: studentId,
            chat_question: userInput.trim(),
            likes: 0,
            dislikes: 0,
            responses: {
                veranda_response: verandaResponse,
                genie_response: genieResponse || "",
            },
        };

        axios
            .post(`${apiURL}/chat-history/`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Message saved to chat history:", response.data);
                    fetchChatHistory(studentId);

                    if (response.data && response.data.id) {
                        setQuestionId(response.data.id);
                    }
                } else {
                    console.warn("Unexpected response while saving chat:", response.status);
                }
            })
            .catch((error) => {
                console.error("Error saving chat to history:", error);
            });
    };

    const handleFeedback = (type) => {
        if (!questionId || !sessionStorage.getItem("id")) {
            console.error("Missing questionId or studentId for feedback.");
            return;
        }

        const studentId = sessionStorage.getItem("id");
        const payload = {
            id: questionId, // Use the stored `id` from POST response
            studentid: studentId,
            likes: type === "like" ? 1 : 0,
            dislikes: type === "dislike" ? 1 : 0,
        };

        axios
            .put(`${apiURL}/chat-history/update/`, payload, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Feedback submitted successfully:", response.data);
                } else {
                    console.warn("Unexpected response while submitting feedback:", response.status);
                }
            })
            .catch((error) => {
                console.error("Error submitting feedback:", error);
            });
    };

    useEffect(() => {
        const id = sessionStorage.getItem("id");
        if (id) {
            setStudentId(id);
            fetchChatHistory(id);
        } else {
            console.error("No student ID found in session storage.");
        }
    }, []);

    const fetchChatHistory = async (student_id) => {
        try {
            const response = await axios.get(`${apiURL}/get_all_chat-history/?studentid=${student_id}`);
            if (response.status === 200) {
                const chatData = response.data;

                // Filter chat history into categories
                const filteredChatHistory = categorizeChatHistory(chatData);
                setChatHistory(filteredChatHistory);
            } else {
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const categorizeChatHistory = (chats) => {
        const today = new Date();
        const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const startOfYear = new Date(today.getFullYear(), 0, 1);

        const categorized = {
            today: [],
            tomorrow: [],
            thisMonth: [],
            thisYear: [],
            older: [],
        };

        chats.forEach(chat => {
            const chatDate = new Date(chat.created_date);

            if (chatDate >= startOfDay) {
                categorized.today.push(chat);
            } else if (chatDate >= new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000)) {
                categorized.tomorrow.push(chat); // If tomorrow data is required
            } else if (chatDate >= startOfMonth) {
                categorized.thisMonth.push(chat);
            } else if (chatDate >= startOfYear) {
                categorized.thisYear.push(chat);
            } else {
                categorized.older.push(chat);
            }
        });

        return categorized;
    };


    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        if (!dataFetched) {
            getData();
            getHubData(sessionStorage.getItem('id'));
            setDataFetched(true);
            // openPdfPopup("paramOne", "paramTwo")
        }
    }, [dataFetched]);

    const getData = async () => {
        axios.get(`${apiURL}/get_assessment_team_data/${sessionStorage.getItem('id')}`)
            .then((response) => {
                if (response.data) {
                    setAssigneddata(response.data);

                    const firstItem = response.data[0];
                    const assHubId = firstItem.id;
                    const assHubName = firstItem.bot_name;
                    sessionStorage.setItem("Ass_HubId", assHubId);
                    sessionStorage.setItem("Ass_HubName", assHubName);
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    const getHubData = async (student_id) => {
        try {
            const response = await axios.get(`${apiURL}/get_knowledge_team_data/${student_id}`);
            if (response.data) {
                const hubForUser = response.data.knowledge_data;
                setAssignedHubdata(hubForUser);
                if (hubForUser.length > 0) {
                    setSelectedHubId(hubForUser[0].id);
                }
                console.log(hubForUser[0].id, 'know hub ID');

                // const firstItem = response.knowledge_data.data[0];
                // const knowHubId = firstItem.id;
                // const knowHubName = firstItem.bot_name;
                // sessionStorage.setItem("Know_HubId", knowHubId);
                // sessionStorage.setItem("Know_HubName", knowHubName);
                console.log("Assigned Hub Data:", hubForUser);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom) {

            loadMoreMessages();
        }
    };


    const loadMoreMessages = () => {
        setTodayLimit(prev => prev + 5);
        setYesterdayLimit(prev => prev + 5);
        setThisMonthLimit(prev => prev + 5);
        setThisYearLimit(prev => prev + 5);
        setOlderLimit(prev => prev + 5);
    };

    useEffect(() => {
        axios.get(`${apiURL}/get_all_Students`)
            .then(response => {
                if (response.data.message === 'Success') {
                    setStudents(response.data.data);
                }
            })
            .catch(error => console.error('Error fetching students:', error));
    }, []);


    const fetchStudentDetails = (id) => {
        axios.get(`${apiURL}/get_Students_by_id/${id}`)
            .then(response => {
                if (response.data.message === 'Success') {
                    setStudentDetails(response.data.data);
                }
            })
            .catch(error => console.error('Error fetching student details:', error));
    };

    const fetchStudentQuickAssData = (id) => {
        axios.get(`${apiURL}/quick_assessment_student_analysis/${id}/results`)
            .then(response => {
                setTestDataQuick(response.data);
            })

            .catch(error => console.error('Error fetching student test data:', error));
    };

    const fetchStudentAIDescASSData = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/ai_student_analysis/${id}/results?question_type=ai_descriptive`);
            setTestDataAiDesc(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentAIMCQASSData = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/ai_student_analysis/${id}/results?question_type=ai_mcq`);
            setTestDataAiMcq(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentQbankDesc = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/qbank_student_analysis/${id}/results?question_type=qbank_descriptive`);
            setTestDataQbankDesc(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentQbankMcq = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/qbank_student_analysis/${id}/results?question_type=qbank_mcq`);
            setTestDataQbankMcq(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };


    const handleStudentChange = (e) => {
        const studentId = e.target.value;
        setSelectedStudentId(studentId);
        setTestDataQuick({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestData({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataAiDesc({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataAiMcq({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataQbankDesc({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataQbankMcq({ total_tests: 0, pass_count: 0, fail_count: 0 });

        if (studentId) {
            fetchStudentDetails(studentId);
            fetchStudentTestData(studentId);
            fetchStudentQuickAssData(studentId);
            fetchStudentAIDescASSData(studentId);
            fetchStudentAIMCQASSData(studentId);
            fetchStudentQbankDesc(studentId);
            fetchStudentQbankMcq(studentId);
        }
    };

    const total = testData.allpass_count + testData.allfail_count;
    const donutData = {
        labels: ['Passed', 'Failed'],
        datasets: [
            {
                label: 'In Assessment',
                data: [
                    (testData.allpass_count / total) * 100,
                    (testData.allfail_count / total) * 100,
                ],
                backgroundColor: ['#28a745', '#dc3545'],
                hoverOffset: 4,
            },
        ],
    };

    const donutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                    padding: 10,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw.toFixed(2);
                        return `${value}%`;
                    },
                },
            },
            title: {
                display: true,
                text: 'Performance Breakdown',
                font: {
                    size: 18,
                    weight: 'bold',
                },
            },
        },
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    const fetchStudentTestData = (id) => {
        axios.get(`${apiURL}/total_student_analysis/${id}/results`)
            .then(response => {
                if (response.status === 200 && response.data) {
                    setTestData({
                        alltotal_tests: response.data.total_tests || 0,
                        allpass_count: response.data.pass_count || 0,
                        allfail_count: response.data.fail_count || 0,
                    });
                } else {

                    setTestData({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
                }
            })
            .catch(error => {
                console.error('Error fetching student test data:', error);

                setTestData({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
            });
    };

    const handleViewDetails = (questionData) => {
        setSelectedQuestionData(questionData.questions);
        setSelectedQuestionDataAiMcq(questionData.questions);
        setSelectedQuestionDataQbankMcq(questionData.questions);
    };

    const handleViewDetailsDes = (questionData) => {
        setSelectedQuestionDataAiDesc(questionData);
    };

    const handleViewDetailsQbankDes = (questionData) => {
        setSelectedQuestionDataQbankDesc(questionData.questions);
    };

    const filteredData = selectedQuestionData.filter((question) =>
        [question.question, question.selected_answer, question.correct_answer]
            .some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Paginated data
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1); // Reset to first page on items per page change
    };

    const aiDataArray = Object.keys(selectedQuestionDataAiDesc).map((key) => ({
        question: selectedQuestionDataAiDesc[key]?.question || "N/A",
        answer: selectedQuestionDataAiDesc[key]?.answer || "N/A",
        total_score: selectedQuestionDataAiDesc[key]?.total_score || "N/A",
    }));

    // Filter data based on search term
    const aiFilteredData = aiDataArray.filter((item) =>
        [item.question, item.answer, item.total_score]
            .join(" ")
            .toLowerCase()
            .includes(aiSearchTerm.toLowerCase())
    );

    // Pagination logic
    const aiTotalPages = Math.ceil(aiFilteredData.length / aiItemsPerPage);
    const aiPaginatedData = aiFilteredData.slice(
        (aiCurrentPage - 1) * aiItemsPerPage,
        aiCurrentPage * aiItemsPerPage
    );

    const handleAiSearch = (e) => {
        setAiSearchTerm(e.target.value);
        setAiCurrentPage(1); // Reset to first page on search
    };

    const handleAiPageChange = (newPage) => {
        if (newPage > 0 && newPage <= aiTotalPages) {
            setAiCurrentPage(newPage);
        }
    };

    const handleAiItemsPerPageChange = (e) => {
        setAiItemsPerPage(parseInt(e.target.value, 10));
        setAiCurrentPage(1); // Reset to first page on items per page change
    };

    const mcqFilteredData = selectedQuestionDataAiMcq.filter((question) =>
        [
            question.question,
            question.options.option1,
            question.options.option2,
            question.options.option3,
            question.options.option4,
            question.correct_answer,
            question.submitted_answer,
            question.status,
        ]
            .join(" ")
            .toLowerCase()
            .includes(mcqSearchTerm.toLowerCase())
    );

    // Pagination logic
    const mcqTotalPages = Math.ceil(mcqFilteredData.length / mcqItemsPerPage);
    const mcqPaginatedData = mcqFilteredData.slice(
        (mcqCurrentPage - 1) * mcqItemsPerPage,
        mcqCurrentPage * mcqItemsPerPage
    );

    const handleMcqSearch = (e) => {
        setMcqSearchTerm(e.target.value);
        setMcqCurrentPage(1); // Reset to first page on search
    };

    const handleMcqPageChange = (newPage) => {
        if (newPage > 0 && newPage <= mcqTotalPages) {
            setMcqCurrentPage(newPage);
        }
    };

    const handleMcqItemsPerPageChange = (e) => {
        setMcqItemsPerPage(parseInt(e.target.value, 10));
        setMcqCurrentPage(1); // Reset to first page on items per page change
    };

    const qbankFilteredData = selectedQuestionDataQbankDesc.filter((question) =>
        [
            question.question,
            question.submitted_answer,
            question.correct_answer,
            question.score,
        ]
            .join(" ")
            .toLowerCase()
            .includes(qbankSearchTerm.toLowerCase())
    );

    // Pagination logic
    const qbankTotalPages = Math.ceil(qbankFilteredData.length / qbankItemsPerPage);
    const qbankPaginatedData = qbankFilteredData.slice(
        (qbankCurrentPage - 1) * qbankItemsPerPage,
        qbankCurrentPage * qbankItemsPerPage
    );

    const handleQbankSearch = (e) => {
        setQbankSearchTerm(e.target.value);
        setQbankCurrentPage(1); // Reset to the first page on search
    };

    const handleQbankPageChange = (newPage) => {
        if (newPage > 0 && newPage <= qbankTotalPages) {
            setQbankCurrentPage(newPage);
        }
    };

    const handleQbankItemsPerPageChange = (e) => {
        setQbankItemsPerPage(parseInt(e.target.value, 10));
        setQbankCurrentPage(1); // Reset to the first page on items per page change
    };


    return (
        <>
            <style>{`
    .mm-show {
      display: block;
    }
    ul[aria-expanded="false"] {
      display: none;
    }
    .metismenu ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
    .metismenu ul.mm-show {
      max-height: 500px; /* Adjust based on the content */
    }
    body {
        background-color: #2b2d42;
        color: #edf2f4;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
        
    .dlabnav {
    background: rgba(43, 45, 66, 0.8); /* Semi-transparent background */
    border-right: 1px solid rgba(255, 255, 255, 0.2); /* Soft white border */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}
    .header{
    border-bottom: 1px solid #2ea87e;
    }

      /* Input section styles */
      .input-section {
        background: rgba(74, 78, 105, 0.7);
        backdrop-filter: blur(10px);
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 15px;
        display: flex;
      }

      .input-field {
        width: 100%;
        border: 1px solid #2ea87e;
        border-radius: 25px;
        padding: 10px 20px;
        transition: border-color 0.3s;
        background: rgba(52, 58, 64, 0.7);
        color: #edf2f4;
      }

      .input-field:focus {
        border-color: #007bff;
        outline: none;
      }

      /* Button styles */
      .btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s, transform 0.3s;
      }

      .btn:hover {
        background-color: rgba(0, 123, 255, 0.7);
        transform: scale(1.1);
      }

      .animate-icon {
        transition: transform 0.2s;
      }

      .animate-icon:hover {
        transform: rotate(20deg);
      }

      /* Add a glowing effect for AI theme */
      .btn-primary {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      .btn-secondary {
        box-shadow: 0 0 5px rgba(108, 117, 125, 0.5);
      }

      .ai-overview-section {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }

      .ai-overview-icon {
        width: 30px;
        height: 30px;
      }

      /* Heartbeat animation */
      .heartbeat-animation {
        animation: heartbeat 1.5s ease-in-out infinite;
      }

      /* Keyframes for heartbeat effect */
      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      body,
      html {
        scroll-behavior: smooth;
      }

      .info-box {
        background-color: unset;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        margin-left:10px;
      }

      .title {
        font-size: 1.3rem;
        margin-bottom: 20px;
        color: #ffffff;
      }

      .sources {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .source-item {
        background-color: #333333;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        color: #b3b3b3;
        border: 1px solid #2ea87e;
      }

      .source-item a {
        color: #7cc5ff;
        text-decoration: none;
        margin-bottom: 5px;
      }

      .source-name {
        font-size: 0.8rem;
        color: #999999;
      }

      .view-more {
        color: #7cc5ff;
        text-decoration: none;
        margin-top: 10px;
        display: block;
      }

      /* Icon and Text Container Styling */
      .icon-text {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        color: #b3b3b3;
        font-size: 1rem;
      }

      /* Icon Styling */
      .icon-text i {
        font-size: 1.5rem;
        color: #7cc5ff;
      }

      /* Description Styling */
      .description {
        background-color: #333333;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      .description-title {
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .collapse-btn {
        background: none;
        border: none;
        color: #7cc5ff;
        cursor: pointer;
      }

      /* New Thread Button */
      .new-thread {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333333;
        color: #ffffff;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 1rem;
      }

      .shortcut {
        background-color: #555555;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        color: #e0e0e0;
      }

      .active {
        background-color: #333333;
        color: #ffffff;
      }

      /* Auth Section */
      .auth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
      }

      .signup-btn {
        background-color: #f5f5f5;
        color: #333333;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
      }

      .login-link {
        color: #7cc5ff;
        text-decoration: none;
        font-size: 0.9rem;
      }

      .dropdown-item {
        color: #b3b3b3;
        text-decoration: none;
        padding: 8px 0;
        transition: color 0.3s;
      }

      .dropdown-item:hover {
        color: #ffffff;
      }

      .description {
        position: relative;
        margin-bottom: 20px;
      }

      .description-title {
        margin: 0;
      }

      .icon-container {
        display: flex;
        gap: 10px;
      }

      .icon-container i {
        cursor: pointer;
        font-size: 18px;
        color: #555;
        transition: color 0.3s;
      }

      .icon-container i:hover {
        color: #007bff; /* Change color on hover */
      }

      .like-icon {
        color: #28a745; /* Green color for like */
      }

      .dislike-icon {
        color: #dc3545; /* Red color for dislike */
      }

      .copy-icon {
        color: #6c757d; /* Gray color for copy */
      }

        .accordion-item {
          margin-bottom: 0px;
          border: 0;
          background-color: transparent;
   
}
         .sources {
    /* Add some spacing if necessary */
    margin: 20px;
}

.source-item {
    margin-bottom: 15px;
    position: relative; /* Needed for tooltip positioning */
}

.tooltip-link {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: #007bff; /* Link color */
    font-weight: normal;
}
    .tooltip-linkcolor {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: red; /* Link color */
    font-weight: bold;
}



.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.tooltip-content {
    color: #ffcc00; /* Set the color you want for content */
    font-weight: normal; /* Optional: make it bold */
    font-size: 16px;
}

.tooltip {
    visibility: hidden;
    width: 250px; /* Adjust width as needed */
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 8px; /* Slightly rounded corners */
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 250%; /* Position above the link */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.card-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-content ul li {
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.small-card {
  flex: 0 0 calc(33.333% - 16px);
  padding: 12px;
  text-align: center;
}



.image-card img,
.full-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
}

.watch-card {
  position: relative;
  text-align: center;
  padding: 12px;
}

.watch-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.watch-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.watch-button:hover {
  background-color: #0056b3;
}

.image-row-row {
  display: flex;
  gap: 5px; /* Space between images */
  margin-bottom: -80px;
}

.image-container {
  text-align: center;
}

.small-card-img {
  width: 100%; /* Adjust the width to make the images smaller */
  height: 50%;
  border-radius: 5px;
}
  .overlay-text {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 10px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;

}

.image-container {
  position: relative;
  display: inline-block;
}
  .big-image-container {
  text-align: center; /* centers the image horizontally */
  margin-bottom: 20px; /* adds some space below the large image */
}
  .big-card-img {
  width: 100%; /* makes the image responsive */
  max-width: 600px; /* ensures it doesn't get too large */
  height: 200px; /* maintains aspect ratio */
   border-radius: 5px;
}
   .accordion-collapse {
  max-height: 400px;  /* Set the maximum height for the content */
  overflow-y: auto;   /* Enable vertical scrolling if the content exceeds the max height */
}

.big-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-row-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.image-container {
  flex: 1;
  max-width: 48%;
  position: relative; /* Relative positioning for the icon overlay */
  text-align: center;
}

.small-card-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Adds rounded corners to the images */
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 5px;
}

.icon-overlay {
  position: absolute;
  top: 10px; /* Adjust for positioning */
  right: 10px; /* Adjust for positioning */
  font-size: 16px; /* Size of the icon */
  color: white; /* Color of the icon */
  background: rgba(0, 0, 0, 0.5); /* Background for visibility */
  border-radius: 50%; /* Circular background */
  padding: 5px; /* Padding for the icon background */
}


.ai-logo-img {
  width: 24px; /* Adjust the size of the AI logo */
  height: 24px; /* Adjust the size of the AI logo */
  cursor: pointer; /* Optional: pointer cursor for interactivity */
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // background-color: #1c1c1c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 2rem;
  border-radius: 8px;
}

.help-title {
  font-size: 30px !important; 
  margin-bottom: 1rem;
  color:#ffff;
}

.button-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}


.help-button {
  background-color: #333333; /* Button color */
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.help-button i {
  font-size: 18px; /* Icon size */
}

.help-button:hover {
  background-color: #555555; /* Lighter color on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.help-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}


.disclaimer {
  font-size: 14px;
  color: #aaaaaa; /* Lighter gray text for disclaimer */
}



/* Button Styling */
.assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 25px;
  padding: 10px 9px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect */
.assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(46, 168, 126, 0.7);
}

/* Dark Pattern Effect on Hover */
.assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}
/* Adjust for larger devices */
@media (min-width: 350px) {
  .content {
    max-width: 400px;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content {
    max-width: 960px;
  }
}

@media (min-width: 1500px) {
  .content {
    max-width: 1400px;
  }
}

@media (max-width: 576px) {
  .tooltip-content {
    font-size: 12px; /* Adjust text size for small devices */
  }
  .source-name {
    font-size: 14px; /* Adjust text size for page number */
  }
}

.icon-text:hover .genie-answer {
  color: #007bff; /* Change the text color on hover */
}

@media (max-width: 768px) {
  .assessment {
    display: none;
  }
}
.metismenu ul {
  overflow: auto;
  max-height: 30vh; /* Adjust as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent;
}

.metismenu ul::-webkit-scrollbar {
  width: 8px; /* Adjust the width */
}

.metismenu ul::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

.metismenu ul::-webkit-scrollbar-track {
  background: transparent;
}
  
/* Smaller Button Styling */
.small-assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 20px; /* Slightly smaller border radius */
  padding: 6px 8px; /* Reduced padding */
  color: white;
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect for smaller button */
.small-assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-2px); /* Slightly less lift */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

/* Focus effect */
.small-assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 8px rgba(46, 168, 126, 0.5);
}

/* Dark Pattern Effect on Hover */
.small-assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250%; /* Slightly smaller circle */
  height: 250%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.small-assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.toast-center {
  bottom: 10%;
  right: 0%;
  position: fixed;
  z-index: 9999;
}

.card{
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

 
    `}</style>

            <div id="main-wrapper">

                {/* navbar */}
                <div class="nav-header bg-green">
                    <a href class="brand-logo">
                        <img src={vlogo} style={{ width: "75%" }} />
                    </a>
                    <div class="nav-control">
                        <div className="hamburger" onClick={toggleSidebar}>
                            {isSidebarExpanded ? (
                                <i className="fas fa-times" title="Collapse Sidebar"></i>
                            ) : (
                                <i className="fas fa-bars" title="Expand Sidebar"></i>
                            )}
                        </div>
                    </div>
                </div>

                {/* header */}
                <div class="header bg-green">
                    <div class="header-content">
                        <nav class="navbar navbar-expand">
                            <div class="collapse navbar-collapse justify-content-between">
                                <div class="header-left">
                                    <div class="dashboard_bar">
                                    </div>
                                </div>
                                <ul class="navbar-nav header-right">
                                    <div class="dashboard_bar d-flex flex-wrap justify-content-between align-items-center">
                                        <h4>{sessionStorage.getItem('username')}</h4>
                                        <li class="nav-item dropdown  header-profile">
                                            <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                                                <i className='fas fa-user fs-4 p-2'></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a href="/profile" class="dropdown-item ai-icon">
                                                    <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                    <span class="ms-2">Profile </span>
                                                </a>
                                                <a href="/" class="dropdown-item ai-icon" onClick={handleLogoutClick}>
                                                    <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                                    <span class="ms-2">Logout </span>
                                                </a>
                                            </div>
                                        </li>
                                    </div>
                                </ul>

                            </div>
                        </nav>
                    </div>
                </div>

                {/* sidebar */}
                <div className="dlabnav">
                    <div className="dlabnav-scroll">
                        <ul className="metismenu" id="menu">
                            <li>
                                <button
                                    className="small-assessment-button" style={{ cursor: 'pointer' }}
                                    onClick={handleClear}
                                    aria-expanded={activeMenu === 'knowledgeHub'}
                                >
                                    <span className="mx-2">New chat</span>
                                    <span class="icon mx-2">
                                        <i class="fas fa-plus"></i>
                                    </span>
                                </button>

                            </li>
                            {/* Knowledge Hub Menu */}
                            <li>
                                <a
                                    className="has-arrow" style={{ cursor: 'pointer' }}
                                    onClick={() => handleToggle('knowledgeHub')}
                                    aria-expanded={activeMenu === 'knowledgeHub'}
                                >
                                    <img
                                        src={chat}
                                        style={{ width: "25px" }}
                                        alt="Icon"
                                    />
                                    <span className="nav-text text-white ms-3">Knowledge Hub</span>
                                </a>
                                <ul className={activeMenu === 'knowledgeHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'knowledgeHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                                    {assignedHubdata.length > 0 ? (
                                        assignedHubdata.map((hub) => (
                                            <li key={hub.id}>
                                                <a
                                                    href={`/KnowledgeScreen?id=${hub.id}`}
                                                    onClick={() => {
                                                        sessionStorage.setItem('hub_id', hub.id);
                                                        localStorage.setItem('hub_id', hub.id);
                                                        // setSelectedHubId(hub.id);
                                                    }}
                                                >
                                                    {`${hub.bot_name}`}
                                                </a>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <a className="">No assigned hubs found.</a>
                                        </li>
                                    )}
                                </ul>
                            </li>
                            {/* Assessment Hub Menu */}
                            <li>
                                <a
                                    className="has-arrow" style={{ cursor: 'pointer' }}
                                    onClick={() => handleToggle('assessmentHub')}
                                    aria-expanded={activeMenu === 'assessmentHub'}
                                >
                                    <img
                                        src={chat}
                                        style={{ width: "25px" }}
                                        alt="Icon"
                                    />
                                    <span className="nav-text text-white ms-3">Assessment Hub</span>
                                </a>
                                <ul className={activeMenu === 'assessmentHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'assessmentHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                                    {assigneddata.length > 0 ? (
                                        assigneddata.map((assigneddatas) => (
                                            <li key={assigneddatas.id}>
                                                <a href={`/InterviewBot?id=${assigneddatas.id}`}>
                                                    {`${assigneddatas.bot_name}`}
                                                </a>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <a className="">No assigned hubs found.</a>
                                        </li>
                                    )}
                                </ul>
                            </li>

                            {/* Chat History */}
                            <li>
                                <a
                                    className="has-arrow"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleToggle('chatHistory')}
                                    aria-expanded={activeMenu === 'chatHistory'}
                                >
                                    <img
                                        src={interview}
                                        style={{ width: "25px" }}
                                        alt="Icon"
                                    />
                                    <span className="nav-text text-white ms-3">Chat History</span>
                                </a>
                                <ul
                                    className={activeMenu === 'chatHistory' ? 'mm-show' : ''}
                                    aria-expanded={activeMenu === 'chatHistory'}
                                    style={{ overflow: 'auto', maxHeight: '30vh' }}
                                    onScroll={handleScroll}
                                >
                                    {chatHistory.today && chatHistory.today.length > 0 ? (
                                        <>
                                            <li className="messages-header mx-1">Today</li>
                                            {chatHistory.today.slice(0, todayLimit).map((chat) => (
                                                <li key={chat.id} className="messages mt-2 cursor-pointer">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        title={chat.chat_question}
                                                    >
                                                        {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                                                    </a>
                                                </li>
                                            ))}
                                            {chatHistory.today.length > todayLimit && (
                                                <li className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={loadMoreMessages}
                                                    >
                                                        View more...
                                                    </a>
                                                </li>
                                            )}
                                        </>
                                    ) : (
                                        <li className="messages mt-2">
                                            <a className="text-muted">No chat history for today.</a>
                                        </li>
                                    )}

                                    {chatHistory.yesterday && chatHistory.yesterday.length > 0 && (
                                        <>
                                            <li className="messages-header mx-2">Yesterday</li>
                                            {chatHistory.yesterday.slice(0, yesterdayLimit).map((chat) => (
                                                <li key={chat.id} className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        title={chat.chat_question}
                                                    >
                                                        {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                                                    </a>
                                                </li>
                                            ))}
                                            {chatHistory.yesterday.length > yesterdayLimit && (
                                                <li className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={loadMoreMessages}
                                                    >
                                                        View more...
                                                    </a>
                                                </li>
                                            )}
                                        </>
                                    )}

                                    {chatHistory.thisMonth && chatHistory.thisMonth.length > 0 && (
                                        <>
                                            <li className="messages-header mx-2">This Month</li>
                                            {chatHistory.thisMonth.slice(0, thisMonthLimit).map((chat) => (
                                                <li key={chat.id} className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        title={chat.chat_question}
                                                    >
                                                        {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                                                    </a>
                                                </li>
                                            ))}
                                            {chatHistory.thisMonth.length > thisMonthLimit && (
                                                <li className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={loadMoreMessages}
                                                    >
                                                        View more
                                                    </a>
                                                </li>
                                            )}
                                        </>
                                    )}

                                    {chatHistory.thisYear && chatHistory.thisYear.length > 0 && (
                                        <>
                                            <li className="messages-header mx-2">This Year</li>
                                            {chatHistory.thisYear.slice(0, thisYearLimit).map((chat) => (
                                                <li key={chat.id} className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        title={chat.chat_question}
                                                    >
                                                        {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                                                    </a>
                                                </li>
                                            ))}
                                            {chatHistory.thisYear.length > thisYearLimit && (
                                                <li className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={loadMoreMessages}
                                                    >
                                                        View more
                                                    </a>
                                                </li>
                                            )}
                                        </>
                                    )}

                                    {chatHistory.older && chatHistory.older.length > 0 && (
                                        <>
                                            <li className="messages-header">Older</li>
                                            {chatHistory.older.slice(0, olderLimit).map((chat) => (
                                                <li key={chat.id} className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        title={chat.chat_question}
                                                    >
                                                        {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                                                    </a>
                                                </li>
                                            ))}
                                            {chatHistory.older.length > olderLimit && (
                                                <li className="messages mt-2">
                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={loadMoreMessages}
                                                    >
                                                        View more
                                                    </a>
                                                </li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </li>

                            {/* Assessment History */}
                            <li>
                                <a
                                    className="has-arrow" style={{ cursor: 'pointer' }}
                                    onClick={() => handleToggle('assessmentHistory')}
                                    aria-expanded={activeMenu === 'assessmentHistory'}
                                >
                                    <img
                                        src={interview}
                                        style={{ width: "25px" }}
                                        alt="Icon"
                                    />
                                    <span className="nav-text text-white ms-3">Assessment History</span>
                                </a>
                                <ul className={activeMenu === 'assessmentHistory' ? 'mm-show' : ''} aria-expanded={activeMenu === 'assessmentHistory'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                                    <li>
                                        <span className="text-muted"></span>
                                    </li>
                                </ul>
                            </li>
                            {/* Digital library */}
                            <li>
                                <a
                                    href={`/studentDigitalLib?studentId=${studentId}`}
                                    className="has-arrow"
                                    style={{ cursor: 'pointer' }}
                                    aria-expanded="false"
                                >
                                    <img
                                        src={chat}
                                        style={{ width: '25px' }}
                                        alt="Icon"
                                    />
                                    <span className="nav-text text-white ms-3">Digital Library</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* content */}
                <div className="content-body default-height">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                                <button
                                    onClick={goBack}
                                    className="btn btn-link text-white me-3"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <i className="fa fa-arrow-left" style={{ fontSize: '20px' }}></i>
                                </button>
                                <h4 className='text-white'>My Profile</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl card">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3 text-white"><CountUp key={testData.alltotal_tests} end={testData.alltotal_tests} duration={2} /></h5>
                                                <p className="text-white">Assessment Attended</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl card mx-xl-3">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3 text-white">
                                                    <CountUp key={testData.allpass_count} end={testData.allpass_count} duration={2} />
                                                </h5>
                                                <p className="text-white">Assessment Passed</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl card">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3 text-white">
                                                    <CountUp key={testData.allfail_count} end={testData.allfail_count} duration={2} />
                                                </h5>
                                                <p className="text-white">Assessment Failed</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5 card">
                                        <div class="mb-4">
                                            {studentDetails ? (
                                                <div className="text-center my-3">
                                                    <i className="fas fa-user-circle text-info fs-1 my-4"></i>
                                                    <p className="m-0 fs-4 fw-bold">{studentDetails.name}</p>
                                                    <p className="m-0 text-white fs-6">Student</p>
                                                    <div className="border-green rounded p-3">
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-white fs-5">ID</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold"># {studentDetails.id}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-white fs-5">Department</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.department}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-white fs-5">Email</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.emailid}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-white fs-5">Phone</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.number}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-white fs-5">Joining</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">
                                                                {new Date(studentDetails.created_at).toLocaleDateString('en-GB')}
                                                            </p>
                                                        </div>
                                                        <hr />
                                                        <div style={{ height: '250px' }}>
                                                            <Doughnut data={donutData} options={donutOptions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="text-center">Select a student to view details</p>
                                            )}
                                        </div>
                                    </div>
                                    <div class="col-xl-7">
                                        <div class="mb-4">
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Quick Assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQuick.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQuick.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white ">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQuick.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataQuick.results && testDataQuick.results.map((resultAss, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultAss.id}</td>
                                                                                        <td>{resultAss.pass_fail}</td>
                                                                                        <td>{resultAss.correct_count + resultAss.incorrect_count}</td>
                                                                                        <td>{resultAss.correct_count}</td>
                                                                                        <td>{resultAss.incorrect_count}</td>
                                                                                        <td>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-light"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target=".bd-example-modal-lg"
                                                                                                onClick={() => handleViewDetails(resultAss.question_data)}
                                                                                            >
                                                                                                <i className="fas fa-eye"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>{new Date(resultAss.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <div class="modal fade bd-example-modal-lg" tabindex="-1" aria-hidden="true" style={{ display: 'none' }}>
                                                                            <div class="modal-dialog modal-xl">
                                                                                <div class="modal-content">
                                                                                    <div class="modal-header">
                                                                                        <h3 class="modal-title text-primary">Ai Mcq</h3>
                                                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        {/* Search and Items per page */}
                                                                                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                            <div className="col-xl-6">
                                                                                                <div className="input-group mb-2">
                                                                                                    <div className="input-group-text">
                                                                                                        <i className="fas fa-search"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control wide"
                                                                                                        placeholder="Search by question, selected answer, correct answer"
                                                                                                        value={searchTerm}
                                                                                                        onChange={handleSearch}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-3">
                                                                                                <select
                                                                                                    className="form-control wide"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    value={itemsPerPage}
                                                                                                    onChange={handleItemsPerPageChange}
                                                                                                >
                                                                                                    <option value="5">5 - items per page</option>
                                                                                                    <option value="10">10 - items per page</option>
                                                                                                    <option value="15">15 - items per page</option>
                                                                                                    <option value="20">20 - items per page</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Students Table */}
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-responsive-md table-hover">
                                                                                                <thead className="mx-auto text-center">
                                                                                                    <tr>
                                                                                                        <th>S.No</th>
                                                                                                        <th>Question</th>
                                                                                                        <th>Option 1</th>
                                                                                                        <th>Option 2</th>
                                                                                                        <th>Option 3</th>
                                                                                                        <th>Option 4</th>
                                                                                                        <th>Correct Answer</th>
                                                                                                        <th>Selected Answer</th>
                                                                                                        <th>Status</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="mx-auto text-center">
                                                                                                    {paginatedData.map((question, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(currentPage - 1) * itemsPerPage + idx + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.options[0]}</td>
                                                                                                            <td>{question.options[1]}</td>
                                                                                                            <td>{question.options[2]}</td>
                                                                                                            <td>{question.options[3]}</td>
                                                                                                            <td>{question.correct_answer}</td>
                                                                                                            <td>{question.selected_answer}</td>
                                                                                                            <td>{question.status}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>

                                                                                        {/* Pagination and Entries Info */}
                                                                                        <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                            <div className="text-dark mb-2 mb-sm-0">
                                                                                                Showing <b className="text-info">{filteredData.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0}</b> to{" "}
                                                                                                <b className="text-info">
                                                                                                    {Math.min(currentPage * itemsPerPage, filteredData.length)}
                                                                                                </b>{" "}
                                                                                                of <b className="text-info">{filteredData.length}</b> entries
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className="text-dark me-3">
                                                                                                    Page <b className="text-info">{currentPage}</b> of <b className="text-info">{totalPages}</b>
                                                                                                </span>
                                                                                                <button
                                                                                                    className="btn btn-xs btn-primary"
                                                                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                                                                    disabled={currentPage === 1}
                                                                                                >
                                                                                                    <i className="fas fa-chevron-left"></i>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="btn btn-xs btn-primary ms-2"
                                                                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                                                                    disabled={currentPage === totalPages}
                                                                                                >
                                                                                                    <i className="fas fa-chevron-right"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Descriptive assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiDesc.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiDesc.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiDesc.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseTwo"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <table className="table table-responsive-md table-hover">
                                                                        <thead className="mx-auto text-center">
                                                                            <tr>
                                                                                <th>S.No</th>
                                                                                <th>ID</th>
                                                                                <th>Status</th>
                                                                                <th>Score</th>
                                                                                <th>View</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="mx-auto text-center">
                                                                            {testDataAiDesc.results && testDataAiDesc.results.map((resultAidesc, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{resultAidesc.id}</td>
                                                                                    <td>{resultAidesc.pass_fail}</td>
                                                                                    <td>{resultAidesc.total_score}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target=".bd-example-modal-lg2"
                                                                                            onClick={() => handleViewDetailsDes(resultAidesc.question_data)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{new Date(resultAidesc.created_at).toLocaleDateString()}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="modal fade bd-example-modal-lg2" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">AI-Descriptive assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Answer / Total Score"
                                                                                                    value={aiSearchTerm}
                                                                                                    onChange={handleAiSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={aiItemsPerPage}
                                                                                                onChange={handleAiItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Answer</th>
                                                                                                    <th>Total Score</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {aiPaginatedData.length > 0 ? (
                                                                                                    aiPaginatedData.map((item, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(aiCurrentPage - 1) * aiItemsPerPage + idx + 1}</td>
                                                                                                            <td>{item.question}</td>
                                                                                                            <td>{item.answer}</td>
                                                                                                            <td>{item.total_score}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="4">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark mb-2 mb-sm-0 col-12 col-sm-auto">
                                                                                            Showing <b className="text-info">{aiFilteredData.length > 0 ? (aiCurrentPage - 1) * aiItemsPerPage + 1 : 0}</b> to{" "}
                                                                                            <b className="text-info">
                                                                                                {Math.min(aiCurrentPage * aiItemsPerPage, aiFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-info">{aiFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark d-flex align-items-center col-12 col-sm-auto">
                                                                                            <span className="me-3">
                                                                                                Page <b className="text-info">{aiCurrentPage}</b> of <b className="text-info">{aiTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleAiPageChange(aiCurrentPage - 1)}
                                                                                                disabled={aiCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleAiPageChange(aiCurrentPage + 1)}
                                                                                                disabled={aiCurrentPage === aiTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Mcq assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiMcq.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiMcq.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataAiMcq.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseThree"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataAiMcq.results && testDataAiMcq.results.map((resultAiMcq, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultAiMcq.id}</td>
                                                                                        <td>{resultAiMcq.pass_fail}</td>
                                                                                        <td>{resultAiMcq.correct_count + resultAiMcq.incorrect_count}</td>
                                                                                        <td>{resultAiMcq.correct_count}</td>
                                                                                        <td>{resultAiMcq.incorrect_count}</td>
                                                                                        <td>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-light"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target=".bd-example-modal-lg1"
                                                                                                onClick={() => handleViewDetails(resultAiMcq.question_data)}
                                                                                            >
                                                                                                <i className="fas fa-eye"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>{new Date(resultAiMcq.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="modal fade bd-example-modal-lg1" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">AI-Mcq assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Options / Status"
                                                                                                    value={mcqSearchTerm}
                                                                                                    onChange={handleMcqSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={mcqItemsPerPage}
                                                                                                onChange={handleMcqItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Option 1</th>
                                                                                                    <th>Option 2</th>
                                                                                                    <th>Option 3</th>
                                                                                                    <th>Option 4</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Selected Answer</th>
                                                                                                    <th>Status</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {mcqPaginatedData.length > 0 ? (
                                                                                                    mcqPaginatedData.map((question, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(mcqCurrentPage - 1) * mcqItemsPerPage + idx + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.options.option1}</td>
                                                                                                            <td>{question.options.option2}</td>
                                                                                                            <td>{question.options.option3}</td>
                                                                                                            <td>{question.options.option4}</td>
                                                                                                            <td>{question.correct_answer || "N/A"}</td>
                                                                                                            <td>{question.submitted_answer || "N/A"}</td>
                                                                                                            <td>{question.status || "N/A"}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="9">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark mb-2 mb-sm-0 col-12 col-sm-auto">
                                                                                            Showing{" "}
                                                                                            <b className="text-info">
                                                                                                {mcqFilteredData.length > 0 ? (mcqCurrentPage - 1) * mcqItemsPerPage + 1 : 0}
                                                                                            </b>{" "}
                                                                                            to{" "}
                                                                                            <b className="text-info">
                                                                                                {Math.min(mcqCurrentPage * mcqItemsPerPage, mcqFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-info">{mcqFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark d-flex align-items-center col-12 col-sm-auto">
                                                                                            <span className="me-3">
                                                                                                Page <b className="text-info">{mcqCurrentPage}</b> of <b className="text-info">{mcqTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleMcqPageChange(mcqCurrentPage - 1)}
                                                                                                disabled={mcqCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleMcqPageChange(mcqCurrentPage + 1)}
                                                                                                disabled={mcqCurrentPage === mcqTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">Question bank Descriptive assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankDesc.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankDesc.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankDesc.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFour"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <table className="table table-responsive-md table-hover">
                                                                        <thead className="mx-auto text-center">
                                                                            <tr>
                                                                                <th>S.No</th>
                                                                                <th>ID</th>
                                                                                <th>Status</th>
                                                                                <th>Score</th>
                                                                                <th>View</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="mx-auto text-center">
                                                                            {testDataQbankDesc.results && testDataQbankDesc.results.map((resultQbankDesc, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{resultQbankDesc.id}</td>
                                                                                    <td>{resultQbankDesc.pass_fail}</td>
                                                                                    <td>{resultQbankDesc.total_score}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target=".bd-example-modal-lg6"
                                                                                            onClick={() => handleViewDetailsQbankDes(resultQbankDesc.question_data)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{new Date(resultQbankDesc.created_at).toLocaleDateString()}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="modal fade bd-example-modal-lg6" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">Question bank Descriptive assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Answer / Score"
                                                                                                    value={qbankSearchTerm}
                                                                                                    onChange={handleQbankSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={qbankItemsPerPage}
                                                                                                onChange={handleQbankItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Answer</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Score</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {qbankPaginatedData.length > 0 ? (
                                                                                                    qbankPaginatedData.map((question, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{(qbankCurrentPage - 1) * qbankItemsPerPage + index + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.submitted_answer}</td>
                                                                                                            <td>{question.correct_answer}</td>
                                                                                                            <td>{question.score}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="5">No questions available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark mb-2 mb-sm-0 col-12 col-sm-auto">
                                                                                            Showing{" "}
                                                                                            <b className="text-info">
                                                                                                {qbankFilteredData.length > 0 ? (qbankCurrentPage - 1) * qbankItemsPerPage + 1 : 0}
                                                                                            </b>{" "}
                                                                                            to{" "}
                                                                                            <b className="text-info">
                                                                                                {Math.min(qbankCurrentPage * qbankItemsPerPage, qbankFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-info">{qbankFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark d-flex align-items-center col-12 col-sm-auto">
                                                                                            <span className="me-3">
                                                                                                Page <b className="text-info">{qbankCurrentPage}</b> of <b className="text-info">{qbankTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleQbankPageChange(qbankCurrentPage - 1)}
                                                                                                disabled={qbankCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleQbankPageChange(qbankCurrentPage + 1)}
                                                                                                disabled={qbankCurrentPage === qbankTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">Q-bank MCQ assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankMcq.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankMcq.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3 text-white">
                                                                    <h5 className="mt-3 text-white">
                                                                        <CountUp end={testDataQbankMcq.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-white">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataQbankMcq.results && testDataQbankMcq.results.map((resultQbankMcq, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultQbankMcq.id}</td>
                                                                                        <td>{resultQbankMcq.pass_fail}</td>
                                                                                        <td>{resultQbankMcq.correct_count + resultQbankMcq.incorrect_count}</td>
                                                                                        <td>{resultQbankMcq.correct_count}</td>
                                                                                        <td>{resultQbankMcq.incorrect_count}</td>
                                                                                        <td>
                                                                                            <td>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-light"
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target=".bd-example-modal-lg5"
                                                                                                    onClick={() => handleViewDetails(resultQbankMcq.question_data)}
                                                                                                >
                                                                                                    <i className="fas fa-eye"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </td>
                                                                                        <td>{new Date(resultQbankMcq.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="modal fade bd-example-modal-lg5" tabindex="-1" aria-hidden="true" style={{ display: 'none' }}>
                                                                        <div class="modal-dialog modal-xl">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header">
                                                                                    <h3 class="modal-title text-primary">Q-bank MCQ assessment</h3>
                                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Option 1</th>
                                                                                                    <th>Option 2</th>
                                                                                                    <th>Option 3</th>
                                                                                                    <th>Option 4</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Selected Answer</th>
                                                                                                    <th>Status</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {selectedQuestionDataQbankMcq.map((question, idx) => (
                                                                                                    <tr key={idx}>
                                                                                                        <td>{idx + 1}</td>
                                                                                                        <td>{question.question}</td>
                                                                                                        <td>{question.options.Option1}</td>
                                                                                                        <td>{question.options.Option2}</td>
                                                                                                        <td>{question.options.Option3}</td>
                                                                                                        <td>{question.options.Option4}</td>
                                                                                                        <td>{question.correct_answer}</td>
                                                                                                        <td>{question.submitted_answer}</td>
                                                                                                        <td>{question.status}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark mb-2 mb-sm-0 col-12 col-sm-auto">
                                                                                            Showing <b className="text-info">1</b> of <b className="text-info">1</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark d-flex align-items-center col-12 col-sm-auto">
                                                                                            <span className="me-3">
                                                                                                Page <b className="text-info">1</b> of <b className="text-info">1</b>
                                                                                            </span>
                                                                                            <button className="btn btn-xs btn-primary" disabled>
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button className="btn btn-xs btn-primary ms-2" disabled>
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer" style={{ backgroundColor: '#2b2d42' }}>
                    <div class="copyright text-white">
                        <p>Copyright © Designed &amp; Developed by <a className='text-green' href="https://datamoo.ai/" target="blank">DataMoo AI</a> 2025</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ProfileOfStudent;