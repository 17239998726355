import React, { useEffect, useState } from 'react';
import Footer from '../Layouts/Footer';
import SideBar from '../Layouts/SideBar';
import Header from '../Layouts/Header';
import NavBar from '../Layouts/NavBar';
import CountUp from 'react-countup';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import axios from 'axios';
import aiicon from '../imgs/AI_icon.png';
import { apiURL } from '../CommonConfig/BaseUrl';
import Select from 'react-select';


Chart.register(ArcElement);

function StudentProfile() {
    const [students, setStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [testData, setTestData] = useState({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
    const [testDataQuick, setTestDataQuick] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataAiDesc, setTestDataAiDesc] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataAiMcq, setTestDataAiMcq] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataQbankDesc, setTestDataQbankDesc] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [testDataQbankMcq, setTestDataQbankMcq] = useState({ total_tests: 0, pass_count: 0, fail_count: 0 });
    const [selectedQuestionData, setSelectedQuestionData] = useState([]);
    const [selectedQuestionDataAiMcq, setSelectedQuestionDataAiMcq] = useState([]);
    const [selectedQuestionDataQbankMcq, setSelectedQuestionDataQbankMcq] = useState([]);
    const [selectedQuestionDataAiDesc, setSelectedQuestionDataAiDesc] = useState([]);
    const [selectedQuestionDataQbankDesc, setSelectedQuestionDataQbankDesc] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [aiSearchTerm, setAiSearchTerm] = useState("");
    const [aiCurrentPage, setAiCurrentPage] = useState(1);
    const [aiItemsPerPage, setAiItemsPerPage] = useState(5);
    const [mcqSearchTerm, setMcqSearchTerm] = useState("");
    const [mcqCurrentPage, setMcqCurrentPage] = useState(1);
    const [mcqItemsPerPage, setMcqItemsPerPage] = useState(5);
    const [qbankSearchTerm, setQbankSearchTerm] = useState("");
    const [qbankCurrentPage, setQbankCurrentPage] = useState(1);
    const [qbankItemsPerPage, setQbankItemsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');







    useEffect(() => {
        axios.get(`${apiURL}/get_all_Students`)
            .then(response => {
                if (response.data.message === 'Success') {
                    setStudents(response.data.data);
                }
            })
            .catch(error => console.error('Error fetching students:', error));
    }, []);


    const fetchStudentDetails = (id) => {
        axios.get(`${apiURL}/get_Students_by_id/${id}`)
            .then(response => {
                if (response.data.message === 'Success') {
                    setStudentDetails(response.data.data);
                }
            })
            .catch(error => console.error('Error fetching student details:', error));
    };

    const fetchStudentQuickAssData = (id) => {
        axios.get(`${apiURL}/quick_assessment_student_analysis/${id}/results`)
            .then(response => {
                setTestDataQuick(response.data);
            })

            .catch(error => console.error('Error fetching student test data:', error));
    };

    const fetchStudentAIDescASSData = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/ai_student_analysis/${id}/results?question_type=ai_descriptive`);
            setTestDataAiDesc(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentAIMCQASSData = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/ai_student_analysis/${id}/results?question_type=ai_mcq`);
            setTestDataAiMcq(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentQbankDesc = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/qbank_student_analysis/${id}/results?question_type=qbank_descriptive`);
            setTestDataQbankDesc(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };

    const fetchStudentQbankMcq = async (id) => {
        try {
            const response = await axios.get(`${apiURL}/qbank_student_analysis/${id}/results?question_type=qbank_mcq`);
            setTestDataQbankMcq(response.data);
        } catch (error) {
            console.error('Error fetching test data:', error);
        }
    };


    const handleStudentChange = (e) => {
        const studentId = e.target.value;
        setSelectedStudentId(studentId);
        setTestDataQuick({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestData({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataAiDesc({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataAiMcq({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataQbankDesc({ total_tests: 0, pass_count: 0, fail_count: 0 });
        setTestDataQbankMcq({ total_tests: 0, pass_count: 0, fail_count: 0 });

        if (studentId) {
            fetchStudentDetails(studentId);
            fetchStudentTestData(studentId);
            fetchStudentQuickAssData(studentId);
            fetchStudentAIDescASSData(studentId);
            fetchStudentAIMCQASSData(studentId);
            fetchStudentQbankDesc(studentId);
            fetchStudentQbankMcq(studentId);
        }
    };

    const total = testData.allpass_count + testData.allfail_count;
    const donutData = {
        labels: ['Passed', 'Failed'],
        datasets: [
            {
                label: 'In Assessment',
                data: [
                    (testData.allpass_count / total) * 100,
                    (testData.allfail_count / total) * 100,
                ],
                backgroundColor: ['#28a745', '#dc3545'],
                hoverOffset: 4,
            },
        ],
    };

    const donutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                    padding: 10,
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw.toFixed(2);
                        return `${value}%`;
                    },
                },
            },
            title: {
                display: true,
                text: 'Performance Breakdown',
                font: {
                    size: 18,
                    weight: 'bold',
                },
            },
        },
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    const fetchStudentTestData = (id) => {
        axios.get(`${apiURL}/total_student_analysis/${id}/results`)
            .then(response => {
                if (response.status === 200 && response.data) {
                    setTestData({
                        alltotal_tests: response.data.total_tests || 0,
                        allpass_count: response.data.pass_count || 0,
                        allfail_count: response.data.fail_count || 0,
                    });
                } else {

                    setTestData({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
                }
            })
            .catch(error => {
                console.error('Error fetching student test data:', error);

                setTestData({ alltotal_tests: 0, allpass_count: 0, allfail_count: 0 });
            });
    };

    const handleViewDetails = (questionData) => {
        setSelectedQuestionData(questionData.questions);
        setSelectedQuestionDataAiMcq(questionData.questions);
        setSelectedQuestionDataQbankMcq(questionData.questions);
    };

    const handleViewDetailsDes = (questionData) => {
        setSelectedQuestionDataAiDesc(questionData);
    };

    const handleViewDetailsQbankDes = (questionData) => {
        setSelectedQuestionDataQbankDesc(questionData.questions);
    };

    const filteredData = selectedQuestionData.filter((question) =>
        [question.question, question.selected_answer, question.correct_answer]
            .some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Paginated data
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1); // Reset to first page on items per page change
    };

    const aiDataArray = Object.keys(selectedQuestionDataAiDesc).map((key) => ({
        question: selectedQuestionDataAiDesc[key]?.question || "N/A",
        answer: selectedQuestionDataAiDesc[key]?.answer || "N/A",
        total_score: selectedQuestionDataAiDesc[key]?.total_score || "N/A",
    }));

    // Filter data based on search term
    const aiFilteredData = aiDataArray.filter((item) =>
        [item.question, item.answer, item.total_score]
            .join(" ")
            .toLowerCase()
            .includes(aiSearchTerm.toLowerCase())
    );

    // Pagination logic
    const aiTotalPages = Math.ceil(aiFilteredData.length / aiItemsPerPage);
    const aiPaginatedData = aiFilteredData.slice(
        (aiCurrentPage - 1) * aiItemsPerPage,
        aiCurrentPage * aiItemsPerPage
    );

    const handleAiSearch = (e) => {
        setAiSearchTerm(e.target.value);
        setAiCurrentPage(1); // Reset to first page on search
    };

    const handleAiPageChange = (newPage) => {
        if (newPage > 0 && newPage <= aiTotalPages) {
            setAiCurrentPage(newPage);
        }
    };

    const handleAiItemsPerPageChange = (e) => {
        setAiItemsPerPage(parseInt(e.target.value, 10));
        setAiCurrentPage(1); // Reset to first page on items per page change
    };

    const mcqFilteredData = selectedQuestionDataAiMcq.filter((question) =>
        [
            question.question,
            question.options.option1,
            question.options.option2,
            question.options.option3,
            question.options.option4,
            question.correct_answer,
            question.submitted_answer,
            question.status,
        ]
            .join(" ")
            .toLowerCase()
            .includes(mcqSearchTerm.toLowerCase())
    );

    // Pagination logic
    const mcqTotalPages = Math.ceil(mcqFilteredData.length / mcqItemsPerPage);
    const mcqPaginatedData = mcqFilteredData.slice(
        (mcqCurrentPage - 1) * mcqItemsPerPage,
        mcqCurrentPage * mcqItemsPerPage
    );

    const handleMcqSearch = (e) => {
        setMcqSearchTerm(e.target.value);
        setMcqCurrentPage(1); // Reset to first page on search
    };

    const handleMcqPageChange = (newPage) => {
        if (newPage > 0 && newPage <= mcqTotalPages) {
            setMcqCurrentPage(newPage);
        }
    };

    const handleMcqItemsPerPageChange = (e) => {
        setMcqItemsPerPage(parseInt(e.target.value, 10));
        setMcqCurrentPage(1); // Reset to first page on items per page change
    };

    const qbankFilteredData = selectedQuestionDataQbankDesc.filter((question) =>
        [
            question.question,
            question.submitted_answer,
            question.correct_answer,
            question.score,
        ]
            .join(" ")
            .toLowerCase()
            .includes(qbankSearchTerm.toLowerCase())
    );

    // Pagination logic
    const qbankTotalPages = Math.ceil(qbankFilteredData.length / qbankItemsPerPage);
    const qbankPaginatedData = qbankFilteredData.slice(
        (qbankCurrentPage - 1) * qbankItemsPerPage,
        qbankCurrentPage * qbankItemsPerPage
    );

    const handleQbankSearch = (e) => {
        setQbankSearchTerm(e.target.value);
        setQbankCurrentPage(1); // Reset to the first page on search
    };

    const handleQbankPageChange = (newPage) => {
        if (newPage > 0 && newPage <= qbankTotalPages) {
            setQbankCurrentPage(newPage);
        }
    };

    const handleQbankItemsPerPageChange = (e) => {
        setQbankItemsPerPage(parseInt(e.target.value, 10));
        setQbankCurrentPage(1); // Reset to the first page on items per page change
    };

    const options = students.map(student => ({
        value: student.id,
        label: `${student.name} - ${student.id}`,
    }));

    // Find the currently selected student
    const selectedOption = options.find(option => option.value === selectedStudentId);





    return (
        <>
            <div id='main-wrapper'>
                <NavBar />
                <Header />
                <SideBar />
                <div className="content-body default-height">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="m-0">Student Profile / Summary</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl me-3">
                                        <label className="h4 text-light ms-1">Student Name</label>
                                        <Select
                                            options={options}
                                            value={selectedOption || null}
                                            onChange={(selectedOption) =>
                                                handleStudentChange({ target: { value: selectedOption ? selectedOption.value : '' } })
                                            }
                                            isSearchable
                                            placeholder="Search by name or ID"
                                            className="wide"
                                            classNamePrefix="custom-select"
                                        />
                                    </div>
                                    <div className="col-xl card">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3"><CountUp key={testData.alltotal_tests} end={testData.alltotal_tests} duration={2} /></h5>
                                                <p className="text-dark">Assessment Attended</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl card mx-xl-3">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3">
                                                    <CountUp key={testData.allpass_count} end={testData.allpass_count} duration={2} />
                                                </h5>
                                                <p className="text-dark">Assessment Passed</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl card">
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                            <span className="ms-5">
                                                <h5 className="mt-3">
                                                    <CountUp key={testData.allfail_count} end={testData.allfail_count} duration={2} />
                                                </h5>
                                                <p className="text-dark">Assessment Failed</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5 card">
                                        <div class="mb-4">
                                            {studentDetails ? (
                                                <div className="text-center my-3">
                                                    <i className="fas fa-user-circle text-info fs-1 my-4"></i>
                                                    <p className="m-0 fs-4 fw-bold">{studentDetails.name}</p>
                                                    <p className="m-0 text-dark fs-6">Student</p>
                                                    <div className="border-green rounded p-3">
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-dark fs-5">ID</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold"># {studentDetails.id}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-dark fs-5">Department</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.department}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-dark fs-5">Email</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.emailid}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-dark fs-5">Phone</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">{studentDetails.number}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex align-items-center justify-content-between mx-4">
                                                            <p className="m-0 p-0 text-dark fs-5">Joining</p>
                                                            <p className="m-0 p-0 fs-5 fw-bold">
                                                                {new Date(studentDetails.created_at).toLocaleDateString('en-GB')}
                                                            </p>
                                                        </div>
                                                        <hr />
                                                        <div style={{ height: '250px' }}>
                                                            <Doughnut data={donutData} options={donutOptions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="text-center">Select a student to view details</p>
                                            )}
                                        </div>
                                    </div>
                                    <div class="col-xl-7">
                                        <div class="mb-4">
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Quick Assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQuick.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQuick.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQuick.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseOne"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingOne"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataQuick.results && testDataQuick.results.map((resultAss, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultAss.id}</td>
                                                                                        <td>{resultAss.pass_fail}</td>
                                                                                        <td>{resultAss.correct_count + resultAss.incorrect_count}</td>
                                                                                        <td>{resultAss.correct_count}</td>
                                                                                        <td>{resultAss.incorrect_count}</td>
                                                                                        <td>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-light"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target=".bd-example-modal-lg"
                                                                                                onClick={() => handleViewDetails(resultAss.question_data)}
                                                                                            >
                                                                                                <i className="fas fa-eye"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>{new Date(resultAss.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <div class="modal fade bd-example-modal-lg" tabindex="-1" aria-hidden="true" style={{ display: 'none' }}>
                                                                            <div class="modal-dialog modal-xl">
                                                                                <div class="modal-content">
                                                                                    <div class="modal-header">
                                                                                        <h3 class="modal-title text-primary">AI-Quick Assessment</h3>
                                                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        {/* Search and Items per page */}
                                                                                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                            <div className="col-xl-6">
                                                                                                <div className="input-group mb-2">
                                                                                                    <div className="input-group-text">
                                                                                                        <i className="fas fa-search"></i>
                                                                                                    </div>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control wide"
                                                                                                        placeholder="Search by question, selected answer, correct answer"
                                                                                                        value={searchTerm}
                                                                                                        onChange={handleSearch}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-3">
                                                                                                <select
                                                                                                    className="form-control wide"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    value={itemsPerPage}
                                                                                                    onChange={handleItemsPerPageChange}
                                                                                                >
                                                                                                    <option value="5">5 - items per page</option>
                                                                                                    <option value="10">10 - items per page</option>
                                                                                                    <option value="15">15 - items per page</option>
                                                                                                    <option value="20">20 - items per page</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* Students Table */}
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-responsive-md table-hover">
                                                                                                <thead className="mx-auto text-center">
                                                                                                    <tr>
                                                                                                        <th>S.No</th>
                                                                                                        <th>Question</th>
                                                                                                        <th>Option 1</th>
                                                                                                        <th>Option 2</th>
                                                                                                        <th>Option 3</th>
                                                                                                        <th>Option 4</th>
                                                                                                        <th>Correct Answer</th>
                                                                                                        <th>Selected Answer</th>
                                                                                                        <th>Status</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="mx-auto text-center">
                                                                                                    {paginatedData.map((question, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(currentPage - 1) * itemsPerPage + idx + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.options[0]}</td>
                                                                                                            <td>{question.options[1]}</td>
                                                                                                            <td>{question.options[2]}</td>
                                                                                                            <td>{question.options[3]}</td>
                                                                                                            <td>{question.correct_answer}</td>
                                                                                                            <td>{question.selected_answer}</td>
                                                                                                            <td>{question.status}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>

                                                                                        {/* Pagination and Entries Info */}
                                                                                        <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                            <div className="text-dark">
                                                                                                Showing <b className="text-primary">{filteredData.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0}</b> to{" "}
                                                                                                <b className="text-primary">
                                                                                                    {Math.min(currentPage * itemsPerPage, filteredData.length)}
                                                                                                </b>{" "}
                                                                                                of <b className="text-primary">{filteredData.length}</b> entries
                                                                                            </div>
                                                                                            <div className="text-dark">
                                                                                                <span className="me-4">
                                                                                                    Page <b className="text-primary">{currentPage}</b> of <b className="text-primary">{totalPages}</b>
                                                                                                </span>
                                                                                                <button
                                                                                                    className="btn btn-xs btn-primary"
                                                                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                                                                    disabled={currentPage === 1}
                                                                                                >
                                                                                                    <i className="fas fa-chevron-left"></i>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="btn btn-xs btn-primary ms-2"
                                                                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                                                                    disabled={currentPage === totalPages}
                                                                                                >
                                                                                                    <i className="fas fa-chevron-right"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Descriptive assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiDesc.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiDesc.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiDesc.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseTwo"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <table className="table table-responsive-md table-hover">
                                                                        <thead className="mx-auto text-center">
                                                                            <tr>
                                                                                <th>S.No</th>
                                                                                <th>ID</th>
                                                                                <th>Status</th>
                                                                                <th>Score</th>
                                                                                <th>View</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="mx-auto text-center">
                                                                            {testDataAiDesc.results && testDataAiDesc.results.map((resultAidesc, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{resultAidesc.id}</td>
                                                                                    <td>{resultAidesc.pass_fail}</td>
                                                                                    <td>{resultAidesc.total_score}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target=".bd-example-modal-lg2"
                                                                                            onClick={() => handleViewDetailsDes(resultAidesc.question_data)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{new Date(resultAidesc.created_at).toLocaleDateString()}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="modal fade bd-example-modal-lg2" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">AI-Descriptive assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Answer / Total Score"
                                                                                                    value={aiSearchTerm}
                                                                                                    onChange={handleAiSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={aiItemsPerPage}
                                                                                                onChange={handleAiItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Answer</th>
                                                                                                    <th>Total Score</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {aiPaginatedData.length > 0 ? (
                                                                                                    aiPaginatedData.map((item, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(aiCurrentPage - 1) * aiItemsPerPage + idx + 1}</td>
                                                                                                            <td>{item.question}</td>
                                                                                                            <td>{item.answer}</td>
                                                                                                            <td>{item.total_score}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="4">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark">
                                                                                            Showing <b className="text-primary">{aiFilteredData.length > 0 ? (aiCurrentPage - 1) * aiItemsPerPage + 1 : 0}</b> to{" "}
                                                                                            <b className="text-primary">
                                                                                                {Math.min(aiCurrentPage * aiItemsPerPage, aiFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-primary">{aiFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark">
                                                                                            <span className="me-4">
                                                                                                Page <b className="text-primary">{aiCurrentPage}</b> of <b className="text-primary">{aiTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleAiPageChange(aiCurrentPage - 1)}
                                                                                                disabled={aiCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleAiPageChange(aiCurrentPage + 1)}
                                                                                                disabled={aiCurrentPage === aiTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">AI-Mcq assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiMcq.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiMcq.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataAiMcq.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseThree"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataAiMcq.results && testDataAiMcq.results.map((resultAiMcq, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultAiMcq.id}</td>
                                                                                        <td>{resultAiMcq.pass_fail}</td>
                                                                                        <td>{resultAiMcq.correct_count + resultAiMcq.incorrect_count}</td>
                                                                                        <td>{resultAiMcq.correct_count}</td>
                                                                                        <td>{resultAiMcq.incorrect_count}</td>
                                                                                        <td>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-light"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target=".bd-example-modal-lg1"
                                                                                                onClick={() => handleViewDetails(resultAiMcq.question_data)}
                                                                                            >
                                                                                                <i className="fas fa-eye"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>{new Date(resultAiMcq.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="modal fade bd-example-modal-lg1" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">AI-Mcq assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Options / Status"
                                                                                                    value={mcqSearchTerm}
                                                                                                    onChange={handleMcqSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={mcqItemsPerPage}
                                                                                                onChange={handleMcqItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Option 1</th>
                                                                                                    <th>Option 2</th>
                                                                                                    <th>Option 3</th>
                                                                                                    <th>Option 4</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Selected Answer</th>
                                                                                                    <th>Status</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {mcqPaginatedData.length > 0 ? (
                                                                                                    mcqPaginatedData.map((question, idx) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td>{(mcqCurrentPage - 1) * mcqItemsPerPage + idx + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.options.option1}</td>
                                                                                                            <td>{question.options.option2}</td>
                                                                                                            <td>{question.options.option3}</td>
                                                                                                            <td>{question.options.option4}</td>
                                                                                                            <td>{question.correct_answer || "N/A"}</td>
                                                                                                            <td>{question.submitted_answer || "N/A"}</td>
                                                                                                            <td>{question.status || "N/A"}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="9">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark">
                                                                                            Showing{" "}
                                                                                            <b className="text-primary">
                                                                                                {mcqFilteredData.length > 0 ? (mcqCurrentPage - 1) * mcqItemsPerPage + 1 : 0}
                                                                                            </b>{" "}
                                                                                            to{" "}
                                                                                            <b className="text-primary">
                                                                                                {Math.min(mcqCurrentPage * mcqItemsPerPage, mcqFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-primary">{mcqFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark">
                                                                                            <span className="me-4">
                                                                                                Page <b className="text-primary">{mcqCurrentPage}</b> of <b className="text-primary">{mcqTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleMcqPageChange(mcqCurrentPage - 1)}
                                                                                                disabled={mcqCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleMcqPageChange(mcqCurrentPage + 1)}
                                                                                                disabled={mcqCurrentPage === mcqTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">Question bank Descriptive assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankDesc.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankDesc.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankDesc.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFour"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <table className="table table-responsive-md table-hover">
                                                                        <thead className="mx-auto text-center">
                                                                            <tr>
                                                                                <th>S.No</th>
                                                                                <th>ID</th>
                                                                                <th>Status</th>
                                                                                <th>Score</th>
                                                                                <th>View</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="mx-auto text-center">
                                                                            {testDataQbankDesc.results && testDataQbankDesc.results.map((resultQbankDesc, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{resultQbankDesc.id}</td>
                                                                                    <td>{resultQbankDesc.pass_fail}</td>
                                                                                    <td>{resultQbankDesc.total_score}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-light"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target=".bd-example-modal-lg6"
                                                                                            onClick={() => handleViewDetailsQbankDes(resultQbankDesc.question_data)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>{new Date(resultQbankDesc.created_at).toLocaleDateString()}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="modal fade bd-example-modal-lg6" tabindex="-1" aria-hidden="true" style={{ display: "none" }}>
                                                                        <div className="modal-dialog modal-xl">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h3 className="modal-title text-primary">Question bank Descriptive assessment</h3>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Question / Answer / Score"
                                                                                                    value={qbankSearchTerm}
                                                                                                    onChange={handleQbankSearch}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                value={qbankItemsPerPage}
                                                                                                onChange={handleQbankItemsPerPageChange}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Answer</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Score</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {qbankPaginatedData.length > 0 ? (
                                                                                                    qbankPaginatedData.map((question, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{(qbankCurrentPage - 1) * qbankItemsPerPage + index + 1}</td>
                                                                                                            <td>{question.question}</td>
                                                                                                            <td>{question.submitted_answer}</td>
                                                                                                            <td>{question.correct_answer}</td>
                                                                                                            <td>{question.score}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="5">No questions available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark">
                                                                                            Showing{" "}
                                                                                            <b className="text-primary">
                                                                                                {qbankFilteredData.length > 0 ? (qbankCurrentPage - 1) * qbankItemsPerPage + 1 : 0}
                                                                                            </b>{" "}
                                                                                            to{" "}
                                                                                            <b className="text-primary">
                                                                                                {Math.min(qbankCurrentPage * qbankItemsPerPage, qbankFilteredData.length)}
                                                                                            </b>{" "}
                                                                                            of <b className="text-primary">{qbankFilteredData.length}</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark">
                                                                                            <span className="me-4">
                                                                                                Page <b className="text-primary">{qbankCurrentPage}</b> of <b className="text-primary">{qbankTotalPages}</b>
                                                                                            </span>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary"
                                                                                                onClick={() => handleQbankPageChange(qbankCurrentPage - 1)}
                                                                                                disabled={qbankCurrentPage === 1}
                                                                                            >
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-xs btn-primary ms-2"
                                                                                                onClick={() => handleQbankPageChange(qbankCurrentPage + 1)}
                                                                                                disabled={qbankCurrentPage === qbankTotalPages}
                                                                                            >
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-stat card bg-primary mb-3">
                                                <div className="card-body p-4">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <img src={aiicon} style={{ width: '100%' }} alt="AI Icon" />
                                                            </span>
                                                            <div className="media-body text-white">
                                                                <h4 className="text-white fw-bold">Q-bank MCQ assessment</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl card mx-xl-3 mt-4 mx-1">
                                                        <div className="d-flex justify-content-start px-3">
                                                            <div className="d-flex align-items-center me-4">
                                                                <i className="fas fa-clock fs-1 border rounded p-2 text-warning bg-warning-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankMcq.total_tests} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment attended</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center me-4 mx-1">
                                                                <i className="fas fa-check-circle fs-1 border rounded p-2 text-success bg-success-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankMcq.pass_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment passed</p>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-1">
                                                                <i className="fas fa-times-circle fs-1 border rounded p-2 text-danger bg-danger-light"></i>
                                                                <span className="ms-3">
                                                                    <h5 className="mt-3">
                                                                        <CountUp end={testDataQbankMcq.fail_count} duration={4} />
                                                                    </h5>
                                                                    <p className="text-dark">Assessment Failed</p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFive"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    View Details
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id="collapseFive"
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo"
                                                                data-bs-parent="#accordionExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-responsive-md table-hover">
                                                                            <thead className="mx-auto text-center">
                                                                                <tr>
                                                                                    <th>S.No</th>
                                                                                    <th>ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Total</th>
                                                                                    <th>Correct</th>
                                                                                    <th>Incorrect</th>
                                                                                    <th>View</th>
                                                                                    <th>Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="mx-auto text-center">
                                                                                {testDataQbankMcq.results && testDataQbankMcq.results.map((resultQbankMcq, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{resultQbankMcq.id}</td>
                                                                                        <td>{resultQbankMcq.pass_fail}</td>
                                                                                        <td>{resultQbankMcq.correct_count + resultQbankMcq.incorrect_count}</td>
                                                                                        <td>{resultQbankMcq.correct_count}</td>
                                                                                        <td>{resultQbankMcq.incorrect_count}</td>
                                                                                        <td>
                                                                                            <td>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-light"
                                                                                                    data-bs-toggle="modal"
                                                                                                    data-bs-target=".bd-example-modal-lg5"
                                                                                                    onClick={() => handleViewDetails(resultQbankMcq.question_data)}
                                                                                                >
                                                                                                    <i className="fas fa-eye"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </td>
                                                                                        <td>{new Date(resultQbankMcq.created_at).toLocaleDateString()}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="modal fade bd-example-modal-lg5" tabindex="-1" aria-hidden="true" style={{ display: 'none' }}>
                                                                        <div class="modal-dialog modal-xl">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header">
                                                                                    <h3 class="modal-title text-primary">Q-bank MCQ assessment</h3>
                                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {/* Search and Items per page */}
                                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                                        <div className="col-xl-6">
                                                                                            <div className="input-group mb-2">
                                                                                                <div className="input-group-text">
                                                                                                    <i className="fas fa-search"></i>
                                                                                                </div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control wide"
                                                                                                    placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <select
                                                                                                className="form-control wide"
                                                                                                style={{ cursor: "pointer" }}
                                                                                            >
                                                                                                <option value="5">5 - items per page</option>
                                                                                                <option value="10">10 - items per page</option>
                                                                                                <option value="15">15 - items per page</option>
                                                                                                <option value="20">20 - items per page</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Students Table */}
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-responsive-md table-hover">
                                                                                            <thead className="mx-auto text-center">
                                                                                                <tr>
                                                                                                    <th>S.No</th>
                                                                                                    <th>Question</th>
                                                                                                    <th>Option 1</th>
                                                                                                    <th>Option 2</th>
                                                                                                    <th>Option 3</th>
                                                                                                    <th>Option 4</th>
                                                                                                    <th>Correct Answer</th>
                                                                                                    <th>Selected Answer</th>
                                                                                                    <th>Status</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="mx-auto text-center">
                                                                                                {selectedQuestionDataQbankMcq.map((question, idx) => (
                                                                                                    <tr key={idx}>
                                                                                                        <td>{idx + 1}</td>
                                                                                                        <td>{question.question}</td>
                                                                                                        <td>{question.options.Option1}</td>
                                                                                                        <td>{question.options.Option2}</td>
                                                                                                        <td>{question.options.Option3}</td>
                                                                                                        <td>{question.options.Option4}</td>
                                                                                                        <td>{question.correct_answer}</td>
                                                                                                        <td>{question.submitted_answer}</td>
                                                                                                        <td>{question.status}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>

                                                                                    {/* Pagination and Entries Info */}
                                                                                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                                                        <div className="text-dark">
                                                                                            Showing <b className="text-primary">1</b> of <b className="text-primary">1</b> entries
                                                                                        </div>
                                                                                        <div className="text-dark">
                                                                                            <span className="me-4">
                                                                                                Page <b className="text-primary">1</b> of <b className="text-primary">1</b>
                                                                                            </span>
                                                                                            <button className="btn btn-xs btn-primary" disabled>
                                                                                                <i className="fas fa-chevron-left"></i>
                                                                                            </button>
                                                                                            <button className="btn btn-xs btn-primary ms-2" disabled>
                                                                                                <i className="fas fa-chevron-right"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    );
}

export default StudentProfile;
