import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import vlogo from '../../imgs/backgroundPngLogo.png';
import DoneLoader from '../BotConfigure/DoneLoader';
import ReactConfetti from 'react-confetti';
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Typewriter from 'typewriter-effect';
import { useRef } from "react";

function InterviewBot() {

  const location = useLocation();
  const [startInterview, setStartInterview] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [responseData, setResponseData] = useState();
  const [responseCount, setResponseCount] = useState();
  const [tabSwitchCount, setTabSwitchCount] = useState(0)
  const [timeLeft, setTimeLeft] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState(Array(questions.length).fill(""));
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [score, setScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeTaken, setTimeTaken] = useState(null);
  const [testFinished, setTestFinished] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(true);
  const totalTime = questions.length * 60;

  const MyCodeLoader = () => <DoneLoader />;
  const [questionTimes, setQuestionTimes] = useState({});
  const [questionStartTime, setQuestionStartTime] = useState(null);

  console.log(questionTimes, "check questions");

  const [username, setUsername] = useState('');
  const [hubName, setHubName] = useState('');
  const [studentId, setStudentId] = useState();
  const [hubId, setHubId] = useState();

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    setUsername(storedUsername || 'UserName');
    const storedHubName = sessionStorage.getItem('Ass_HubName');
    setHubName(storedHubName || 'Assessment Hub');
    const storedStudentId = sessionStorage.getItem('id');
    setStudentId(storedStudentId || '1');
  }, []);

  const handleNextClick = () => {
    startTimer();
    if (questions.length === 0) return;
    setQuestionStartTime(Date.now());
    handleNextInstructions();
  };

  const handleNextInstructions = () => {
    setShowInstructionsModal(false);
    setStartInterview(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('query');
    const response = new URLSearchParams(location.search).get('response');
    const knowId = new URLSearchParams(location.search).get('hubId');
    setHubId(knowId);
    console.log(query, response, knowId, 'URL decoded values');

    if (query && response && knowId) {
      getQuickData(query, response, knowId);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  const getQuickData = (query, response, knowId) => {
    setLoading(true);
    axios
      .post(`${apiURL}/generate-quick-assessment-mcqs`, { query, response, knowId })
      .then((response) => {
        if (response.status === 200 && response.data.mcqs) {
          const transformedQuestions = response.data.mcqs.map((item) => ({
            id: item.id,
            question: item.question,
            options: item.options,
            answer: item.answer,
            difficulty: item.difficulty || "Not specified",
          }));
          setQuestions(transformedQuestions);
          console.log(transformedQuestions, "check response of fetched qn");
          setUserResponses(Array(transformedQuestions.length).fill(""));
        } else {
          toastr.error("Failed to load questions. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toastr.error("Error connecting to the server.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setTabSwitchCount((prevCount) => prevCount + 1);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const handleAnswer = (index, answer) => {
    const updatedResponses = [...userResponses];
    updatedResponses[index] = answer;
    setUserResponses(updatedResponses);
  };

  const handleNextQuestion = () => {
    setIsLoading(true);

    if (!userResponses[currentQuestionIndex]) {
      toastr.warning("Please select an answer before proceeding.");
      setIsLoading(false);
      return;
    }

    // Record time spent on current question
    const timeSpentOnQuestion = Date.now() - questionStartTime;
    setQuestionTimes(prev => ({
      ...prev,
      [questions[currentQuestionIndex].id]: timeSpentOnQuestion / 1000, // Store in seconds
    }));

    // Move to next question
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setQuestionStartTime(Date.now()); // Start timer for the next question
      toastr.success("Answer saved.");
    } else {
      setShowFinishModal(true);
    }

    setIsLoading(false);
  };


  const handleFinishTest = async () => {
    setIsLoading(true);

    if (testFinished) return;
    setTestFinished(true);

    const timeSpent = totalTime - timeLeft;
    setTimeTaken(timeSpent);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    // if (userResponses.some((response) => response === null || response === "")) {
    //   toastr.warning("Please answer all MCQ questions before finishing the test.");
    //   setIsLoading(false);
    //   return;
    // }

    try {
      const formattedAnswers = questions.reduce((acc, question, index) => {
        acc[question.id] = userResponses[index];
        return acc;
      }, {});

      const payload = {
        hub_id: hubId,
        student_id: studentId,
        answers: formattedAnswers,
      };

      const response = await fetch(`${apiURL}/validate-quick-assessment-mcqs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Error while submitting the answers");
      }

      const data = await response.json();
      setScore(data.validation_result.total_score);
      toastr.success("Assessment completed. Check your score!");

      const detailsArray = Object.entries(data.validation_result.details).map(([key, value]) => ({
        question_id: key,
        status: value.user_answer === value.correct_answer ? "Correct" : "Incorrect",
        selected_answer: value.user_answer,
        correct_answer: value.correct_answer,
        question: value.question,
        options: value.options,
        feedback: value.feedback,
      }));

      setResponseData(detailsArray);
      setResponseCount(data.validation_result);
      setShowFinishModal(true);
      setStartInterview(false);

      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);

    } catch (error) {
      console.error("Error:", error);
      toastr.error("Failed to submit answers. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const timerRef = useRef(null);

  const startTimer = () => {
    const totalTime = questions.length * 60;
    setTimeLeft(totalTime);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft === null || testFinished) return;

    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    // Avoid time-up logic if test is already finished
    if (testFinished) return;

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    Swal.fire({
      title: "Time Out!",
      text: "Your assessment time has ended. Submitting your answers.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      handleFinishTest();
    });
  }, [timeLeft, testFinished]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <style>
        {`
  body {
    background-color: #2b2d42;
    color: #edf2f4;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .text-green {
    color: #2ea87e;
  }
  .border-green {
    border: 1px solid #2ea87e;
  }
  .border-top-green {
    border-top: 1px solid #2ea87e;
  }
  .card {
    background: rgba(58, 59, 60, 0.7);
    backdrop-filter: blur(10px);
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  .card-header {
    background: rgba(74, 78, 105, 0.7);
    color: #edf2f4;
    font-weight: bold;
  }
  `}
      </style>

      <div className='card-header bg-primary py-3 d-flex flex-wrap justify-content-between'>
        <img src={vlogo} style={{ maxWidth: '9%' }} />
        <h4 className='text-white m-0 p-0'>User : {username}</h4>
      </div>
      <div className='card-body'>
        {/* Main Assessment Content */}
        {startInterview && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <div className='mx-4'>
                    <span className='d-flex flex-wrap align-items-center flex-wrap justify-content-between'>
                      <h4 className="m-0 p-0 text-primary">Hub Name : {hubName}</h4>
                      <h5 className="m-0 p-0 text-warning">
                        Time Remaining
                        <span className="text-danger">
                          {" - "}
                          {formatTime(timeLeft)}
                          <img
                            src="https://cdn-icons-gif.flaticon.com/8721/8721066.gif"
                            style={{ width: '25px' }}
                            alt="Icon"
                            className="rounded-circle ms-3"
                          />
                        </span>
                      </h5>
                    </span>
                  </div>
                  <hr />

                  {/* Hub question and user response component */}
                  <div className='card-body' style={{ maxHeight: '50vh', minHeight: '50vh', overflow: 'auto' }}>
                    {currentQuestionIndex < questions.length ? (
                      <div className="text-center">
                        <p className="mb-4 fs-4 text-white">{questions[currentQuestionIndex].question}</p>
                        <div className="row mb-4 mx-xl-5 mx-1 px-xl-5">
                          {/* Display MCQ options for the current question */}
                          {questions[currentQuestionIndex].options.map((option, index) => (
                            <div className="col-xl-6 mb-3" key={index}>
                              <Button
                                className={`btn bg-${userResponses[currentQuestionIndex] === option ? "green" : "white"} text-${userResponses[currentQuestionIndex] === option ? "white" : "dark"} border-green w-100`}
                                onClick={() => handleAnswer(currentQuestionIndex, option)}
                              >
                                {option}
                              </Button>
                            </div>
                          ))}
                        </div>
                        <div className="mx-auto text-center mt-5">
                          {/* {currentQuestionIndex > 0 && (
                              <div
                                className="btn btn-primary me-4"
                                onClick={() => !isLoading && setCurrentQuestionIndex(currentQuestionIndex - 1)}
                                role="button"
                                aria-label="Previous"
                              >
                                {isLoading && <span className="" role="status" aria-hidden="true"></span>}
                                Previous
                              </div>
                            )} */}
                          {currentQuestionIndex < questions.length - 1 ? (
                            <div
                              className="btn btn-primary"
                              onClick={() => !isLoading && handleNextQuestion()}
                              role="button"
                              aria-label="Next"
                            >
                              {isLoading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
                                </>
                              ) : (
                                'Next'
                              )}
                            </div>
                          ) : (
                            <div
                              className="btn btn-warning"
                              onClick={() => !isLoading && handleNextQuestion()}
                              role="button"
                              aria-label="Finish"
                            >
                              {isLoading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
                                </>
                              ) : (
                                'Finish'
                              )}
                            </div>
                          )}
                        </div>
                        <Modal className="mt-xl-5 mt-3" show={showInstructionsModal} onHide={() => setShowInstructionsModal(false)} backdrop="static">
                          <Modal.Header className="bg-primary">
                            <Modal.Title className="d-flex flex-wrap align-items-center">
                              <img src="https://cdn-icons-gif.flaticon.com/16059/16059787.gif" style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
                              <h4 className="text-white m-0 p-0 ms-3">Quick Assessment</h4>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="bg-dark">
                            <p className='text-white fs-6 text-center'>Are you sure ? <br /> To start a quick assessment based on your last query !?</p>
                            <hr />
                            <div className="d-flex flex-wrap justify-content-between">
                              <a className="btn btn-primary" href="/KnowledgeScreen">
                                Cancel
                              </a>
                              <div className="btn btn-primary" onClick={() => handleNextClick()}>
                                Start Test
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    ) : (
                      // <h6 className="m-0 p-0 text-danger text-center fs-3">Fetching Assessment Questions, Please Wait...</h6>
                      <MyCodeLoader />
                    )}
                  </div>
                  <div className='card-footer border-top-green'>
                    <div className="border-green rounded p-3">
                      <div className="d-flex flex-wrap align-items-center justify-content-between mx-4">
                        <p className="m-0 p-0 text-white fs-6">Questions Remaining :</p>
                        <p className="m-0 p-0 text-warning fs-6 fw-bold">
                          {questions.length - currentQuestionIndex} / {questions.length}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex flex-wrap align-items-center justify-content-between mx-4">
                        <p className="m-0 p-0 text-white fs-6">Tabs Switched :</p>
                        <p className="m-0 p-0 text-warning fs-6 fw-bold">
                          {tabSwitchCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Finish Confirmation Modal */}
      <Modal show={showFinishModal} onHide={() => setShowFinishModal(false)} backdrop="static">
        <Modal.Header className='bg-primary'>
          <Modal.Title className='d-flex flex-wrap align-items-center'>
            <img src='https://cdn-icons-gif.flaticon.com/15578/15578568.gif' style={{ width: "40px" }} alt="Icon" className="rounded-circle" />
            <h4 className='text-white m-0 p-0 ms-3'>Finish Assessment ?</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=' bg-dark'>
          <p className='text-white fs-6'>Are you sure you want to finish the Assessment ?</p>
          <hr className='text-green' />
          <div className="d-flex flex-wrap justify-content-between">
            <div className="btn btn-primary" onClick={() => setShowFinishModal(false)}>
              Cancel
            </div>
            <div className="btn btn-primary" onClick={() => !isLoading && handleFinishTest()} role="button" aria-label="Finish">
              {isLoading ? (
                <span className="spinner-border spinner-border-sm mx-auto text-center" role="status" aria-hidden="true"></span>
              ) : (
                'Finish'
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Confetti */}
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}
      {/* Summary Modal */}
      <Modal show={score !== null} onHide={() => setScore(null)} backdrop="static">
        <Modal.Header className="bg-primary">
          <Modal.Title className="d-flex align-items-center">
            <h4 className="text-white m-0 p-0 me-5">Assessment Summary</h4>
            <img
              src="https://cdn-icons-gif.flaticon.com/10971/10971313.gif"
              style={{ width: "40px" }}
              alt="Icon"
              className="rounded-circle"
            />
            <a className="btn btn-primary ms-xl-5 ps-xl-5" href="/KnowledgeScreen">
              <i className="fas fa-close ms-5 ps-4"></i>
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          <div className="text-white">
            <span className="d-flex flex-wrap align-items-center justify-content-between">
              <p className="mb-1">Correct answers :</p>
              <b className="text-success">{responseCount?.correct_count}</b>
            </span>
            <span className="d-flex flex-wrap align-items-center justify-content-between">
              <p className="mb-1">Incorrect answers :</p>
              <b className="text-danger">{responseCount?.incorrect_count}</b>
            </span>
            {timeTaken !== null && (
              <span className="d-flex flex-wrap align-items-center justify-content-between">
                <p className="mb-1">Time taken :</p>
                <b className="text-light">
                  <span className="text-white">
                    {Math.floor(Object.values(questionTimes).reduce((total, time) => total + parseFloat(time), 0) / 60)}
                  </span>{" "}
                  minutes{" "}
                  <span className="text-white">
                    {Math.floor(Object.values(questionTimes).reduce((total, time) => total + parseFloat(time), 0) % 60)}
                  </span>{" "}
                  seconds
                </b>
              </span>
            )}
            <hr className="text-green" />
            {questionTimes && (
              <div className="mt-3">
                <h5 className="text-center text-warning">Time Breakdown</h5>
                <ul className="list-group">
                  {Object.entries(questionTimes).map(([id, time], index) => (
                    <li key={id} className="list-group-item bg-dark text-light d-flex justify-content-between align-items-center">
                      <span>Question {index + 1} </span> {/* Question ID : {id} */}
                      <span className="badge bg-primary text-white">
                        {parseFloat(time).toFixed(2)} seconds
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <hr className="text-green" />
            {/* Detailed response breakdown */}
            <div className="mt-3">
              <h5 className="text-center text-warning">Summary</h5>
              {responseData?.map((item, index) => (
                <div key={index} className="bg-dark p-3 rounded my-2">
                  <h6 className="text-white">Question {index + 1} :</h6>
                  <p
                    className={`text-${item.status === "Correct" ? "success" : "danger"}`}
                  >
                    <span className="text-light">Status : </span>{item.status}
                  </p>
                  <p className="text-secondary">
                    Submitted Answer : <span className="text-light">{item.selected_answer}</span>
                  </p>
                  <p className="text-success">
                    Correct Answer : <span className="text-light">{item.correct_answer}</span>
                  </p>
                </div>
              ))}
            </div>
            <hr className="text-green" />
            <span className="mx-auto text-center fs-6 text-warning">
              <b>
                <Typewriter
                  options={{
                    delay: 10,
                    deleteSpeed: 50,
                    loop: false,
                  }}
                  onInit={(typewriter) => {
                    typewriter.typeString("Thank you for participating in the Assessment !!").start();
                  }}
                />
              </b>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-dark">
          <a className="btn btn-primary text-center mx-auto" href="/KnowledgeScreen">
            Close
          </a>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default InterviewBot;