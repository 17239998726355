import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BaseRoutes } from './Components/CommonConfig/BaseRoutes'; 
import Dashboard from './Components/Screens/Dashboard';
import PreLoader from './Components/Layouts/PreLoader'; 
import InterviewBot from './Components/Screens/Bots/Interviewbot';
import AssessmentMcq from './Components/Screens/Bots/AssessmentMcq';
import InterviewBotConfig from './Components/Screens/BotConfigure/InterviewBotConfig';
import BotSummary from './Components/Screens/BotConfigure/BotSummary';
import KnowledgeScreen from './Components/Screens/Bots/KnowledgeScreen';
import KnowledgeBotConfig from './Components/Screens/BotConfigure/KnowledgeBotConfig';
import StudentProfile from './Components/Screens/StudentProfile';
import HubInfo from './Components/Screens/BotConfigure/HubInfo';
import HubProfile from './Components/Screens/HubProfile';
import StudentCreation from './Components/Screens/BotConfigure/StudentCreation';
import CreateTeam from './Components/Screens/BotConfigure/CreateTeam';
import SignIn from './Components/Screens/UserLogins/SignIn';
import PrivateRoute from './Components/AuthScreens/PrivateRoute';
import DocSummary from './Components/Screens/BotConfigure/DocSummary';
import InterviewSummary from './Components/Screens/BotConfigure/InterviewSummary';
import AssignTeam from './Components/Screens/BotConfigure/AssignTeam';
import BatchConfig from './Components/Screens/BotConfigure/BatchConfig';
import DoneLoader from './Components/Screens/BotConfigure/DoneLoader';
import StudentDetails from './Components/Screens/StudentDetails';
import Adminsummary from './Components/Screens/BotConfigure/Adminsummary';
import StudentDigitalLib from './Components/Screens/Bots/StudentDigitalLib';
import ProfileOfStudent from './Components/Screens/Bots/ProfileOfStudent';
import Error400 from './Components/Screens/ErrorTemplates/Error400';
import Error500 from './Components/Screens/ErrorTemplates/Error500';




// hello world

function App() {
  return (
    <div className="App">
      <PreLoader />
      <BrowserRouter>
        <Routes>
          <Route path={BaseRoutes.Dashboard} element={<PrivateRoute element={<Dashboard />} />}></Route>
          <Route path={BaseRoutes.InterviewBot} element={<PrivateRoute element={<InterviewBot />} />}></Route>
          <Route path={BaseRoutes.AssessmentMcq} element={<PrivateRoute element={<AssessmentMcq />} />}></Route>
          <Route path={BaseRoutes.KnowledgeBotConfig} element={<PrivateRoute element={<KnowledgeBotConfig />} />}></Route>
          <Route path={BaseRoutes.interviewBotCongfig} element={<PrivateRoute element={<InterviewBotConfig />} />}></Route>
          <Route path={BaseRoutes.BotSummary} element={<PrivateRoute element={<BotSummary />} />}></Route>
          <Route path={BaseRoutes.KnowledgeScreen} element={<PrivateRoute element={<KnowledgeScreen />} />}></Route>
          <Route path={BaseRoutes.StudentProfile} element={<PrivateRoute element={<StudentProfile />} />}></Route>
          <Route path={BaseRoutes.HubInfo} element={<PrivateRoute element={<HubInfo />} />}></Route>
          <Route path={BaseRoutes.HubProfile} element={<PrivateRoute element={<HubProfile />} />}></Route>
          <Route path={BaseRoutes.StudentCreation} element={<PrivateRoute element={<StudentCreation />} />}></Route>
          <Route path={BaseRoutes.CreateTeam} element={<PrivateRoute element={<CreateTeam />} />}></Route>
          <Route path={BaseRoutes.SignIn} element={<SignIn />}></Route>
          <Route path={BaseRoutes.DocSummary} element={<DocSummary />}></Route>
          <Route path={BaseRoutes.InterviewSummary} element={<InterviewSummary />}></Route>
          <Route path={BaseRoutes.AssignTeam} element={<AssignTeam />}></Route>
          <Route path={BaseRoutes.BatchConfig} element={<BatchConfig />}></Route>
          <Route path={BaseRoutes.DoneLoader} element={<DoneLoader />}></Route>
          <Route path={BaseRoutes.Adminsummary} element={<Adminsummary />}></Route>
          <Route path={`${BaseRoutes.StudentDetails}/:id`} element={<StudentDetails />} />
          <Route path={BaseRoutes.StudentDigitalLib} element={<StudentDigitalLib />}></Route>
          <Route path={BaseRoutes.ProfileOfStudent} element={<ProfileOfStudent />}></Route>
          <Route path={BaseRoutes.Error400} element={<Error400 />}></Route>
          <Route path={BaseRoutes.Error500} element={<Error500 />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
