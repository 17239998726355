import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { apiURL, botApiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';

function DocSummary() {


    const [knowledges, setKnowledges] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQueryAssess, setSearchQueryAssess] = useState("");

    useEffect(() => {
        fetchKnowledgeData();
        fetchFiles();
    }, []);

    const fetchKnowledgeData = async () => {
        const response = await fetch(`${botApiURL}/vbot_getall_files/`);
        const data = await response.json();
        setKnowledges(data.db_vbot_knowledge);
        console.log(data, knowledges, "KnowledgeData");
    };

    const handleDeleteKnowledge = async (fileId, file_location) => {
        console.log(fileId, "file id");

        const confirmDelete = window.confirm(`Are you sure you want to delete the file with ID: ${fileId}?`);
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`${botApiURL}/deleteuplodfiles/?file_id=${fileId}&file_location=${file_location}`);

            if (response.status === 200) {
                alert('File deleted successfully.');
                setKnowledges((prevData) => prevData.filter(file => file.file_id !== fileId));
            } else {
                alert('Failed to delete the file.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('An error occurred while deleting the file. Please try again.');
        }
    };

    const handleDownloadKnowledge = async (fileId, fileName) => {
        try {

            const response = await axios.get(`${botApiURL}/Filedownload/${fileId}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('An error occurred while downloading the file. Please try again.');
        }
    };

    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_assessment_files`, {
                headers: {
                    accept: "application/json",
                },
            });
            if (response.data && response.data.data) {
                setAssessments(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching assessment files:", error);
        }
    };

    const handleDownloadAssessment = (id, fileName) => {
        const file = assessments.find((file) => file.id === id);
        if (file) {
            window.open(file.file_location, "_blank");
        }
    };


    const handleDeleteAssessment = (id, fileLocation) => {
        if (window.confirm("Are you sure you want to delete this file?")) {
            // Perform delete logic (API call or state update)
            setAssessments(assessments.filter((file) => file.id !== id));
            console.log(`Deleted file at location: ${fileLocation}`);
        }
    };

    const filteredFiles = knowledges.filter(file =>
        file.file_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredAssessments = assessments.filter(file =>
        file.file_name.toLowerCase().includes(searchQueryAssess.toLowerCase())
    );



    return (
        <div>
            <div id="main-wrapper">
                <NavBar />
                <Header />
                <SideBar />
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Document Summary</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="card-tabs">
                                            <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                                <li class=" nav-item" role="presentation">
                                                    <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">Knowledge Documents</a>
                                                </li>
                                                <li class="nav-item" role="presentation">

                                                    <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Assessment Documents</a>

                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-content">
                                            <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                                <div className="row">
                                                    {/* Search Bar */}
                                                    <div className="col-4 mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search by file name"
                                                            value={searchQuery}
                                                            onChange={e => setSearchQuery(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="d-flex justify-content-center">
                                                        <h4 className="card-title mb-3">Existing Files</h4>
                                                    </div>
                                                    <hr />

                                                    {/* Display filtered files */}
                                                    {filteredFiles.length > 0 ? (
                                                        filteredFiles.map((file, index) => (
                                                            <div
                                                                className={`col-xl-3 col-md-4 col-sm-6 mb-4 ${index >= 8 && !showMore ? "d-none" : ""}`}
                                                                key={file.file_id}
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body text-center ai-icon text-primary">
                                                                        <svg
                                                                            id="pdf-icon"
                                                                            className="my-1"
                                                                            viewBox="0 0 24 24"
                                                                            width="60"
                                                                            height="60"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M6 2H16L20 6V22H4V2H6Z"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M16 2V10H20"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M8 12H16"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M8 16H12"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                        <h5
                                                                            className="my-1"
                                                                            title={file.file_name} // Full name as tooltip
                                                                        >
                                                                            {file.file_name.length > 20
                                                                                ? `${file.file_name.substring(0, 10)}...`
                                                                                : file.file_name}
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-xs my-1 mx-3"
                                                                            onClick={() =>
                                                                                handleDeleteKnowledge(file.file_id, file.file_location)
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            className="btn btn-primary btn-xs my-1 px-3"
                                                                            onClick={() => handleDownloadKnowledge(file.file_id, file.file_name)}
                                                                        >
                                                                            Download
                                                                        </a>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="col-12 text-center">
                                                            <p>No files found</p>
                                                        </div>
                                                    )}

                                                    {/* Toggle Button */}
                                                    {filteredFiles.length > 8 && (
                                                        <div className="col-12 text-center mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowMore(!showMore)}
                                                            >
                                                                {showMore ? "Minimize" : "Show More"}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div id="navpills2-21" class="tab-pane" role="tabpanel">
                                                <div className="row">
                                                    {/* Search Bar */}
                                                    <div className="col-4 mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search by file name"
                                                            value={searchQueryAssess}
                                                            onChange={e => setSearchQueryAssess(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="d-flex justify-content-center">
                                                        <h4 className="card-title mb-3">Existing Files</h4>
                                                    </div>
                                                    <hr />

                                                    {/* Display filtered assessments */}
                                                    {filteredAssessments.length > 0 ? (
                                                        filteredAssessments.map((file, index) => (
                                                            <div
                                                                className={`col-xl-3 col-md-4 col-sm-6 mb-4 ${index >= 8 && !showMore ? "d-none" : ""}`}
                                                                key={file.id}
                                                            >
                                                                <div className="card">
                                                                    <div className="card-body text-center ai-icon text-primary">
                                                                        <svg
                                                                            id="pdf-icon"
                                                                            className="my-1"
                                                                            viewBox="0 0 24 24"
                                                                            width="60"
                                                                            height="60"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M6 2H16L20 6V22H4V2H6Z"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M16 2V10H20"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M8 12H16"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                            <path
                                                                                d="M8 16H12"
                                                                                strokeWidth="1"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                        <h5
                                                                            className="my-1"
                                                                            title={file.file_name} // Full name as tooltip
                                                                        >
                                                                            {file.file_name.length > 20
                                                                                ? `${file.file_name.substring(0, 20)}...`
                                                                                : file.file_name}
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-xs my-1 mx-3"
                                                                            onClick={() =>
                                                                                handleDeleteAssessment(file.id, file.file_location)
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            className="btn btn-primary btn-xs my-1 px-3"
                                                                            onClick={() => handleDownloadAssessment(file.id, file.file_name)}
                                                                        >
                                                                            Download
                                                                        </a>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="col-12 text-center">
                                                            <p>No files found</p>
                                                        </div>
                                                    )}

                                                    {/* Toggle Button */}
                                                    {filteredAssessments.length > 8 && (
                                                        <div className="col-12 text-center mt-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => setShowMore(!showMore)}
                                                            >
                                                                {showMore ? "Minimize" : "Show More"}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default DocSummary