import React, { useState, useEffect } from 'react';
import { FaRocket, FaUser, FaMapMarkerAlt, FaCheckCircle } from 'react-icons/fa';
import SideBar from '../Layouts/SideBar';
import Header from '../Layouts/Header';
import NavBar from '../Layouts/NavBar';
import PreLoader from '../Layouts/PreLoader';
import Footer from '../Layouts/Footer';
import vlogo from '../imgs/verandaLog1.png';
import axios from 'axios';
import moment from "moment";
import { apiURL } from '../CommonConfig/BaseUrl';
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {

  const [knowledgeData, setKnowledgeData] = useState([]);
  const [AssessmentData, setAssessmentData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermByAssessment, setSearchTermByAssessment] = useState("");
  const [searchAllHubs, setSearchAllHubs] = useState("");

  const [totalHubs, setTotalHubs] = useState(0);
  const [totalAssesmentHubs, setTotalAssessmentHubs] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [chatData, setChatData] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [currentMonthHubs, setCurrentMonthHubs] = useState([]);
  const [activeTab, setActiveTab] = useState("monthly");
  const [filter, setFilter] = useState('monthly');
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    fetchData();
    fetchStudents();
    fetchDataAllAssessment();
  }, []);

  const filteredData = knowledgeData.filter((item) =>
    item.bot_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredDataAssessment = AssessmentData.filter((item) =>
    item.bot_name.toLowerCase().includes(searchTermByAssessment.toLowerCase())
  );

  const allData = [...knowledgeData, ...AssessmentData];

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_knowledge`, {
        headers: {
          accept: "application/json",
        },
      });
      setKnowledgeData(response.data.data);
      setTotalHubs(response.data.data.length);


      const startOfMonth = moment().startOf("month");
      const endOfMonth = moment().endOf("month");

      
      const hubsThisMonth = data.filter((hub) => {
        const createdAt = moment(hub.created_at, moment.ISO_8601, true);
        return (
          createdAt.isValid() &&
          createdAt.isSameOrAfter(startOfMonth) &&
          createdAt.isSameOrBefore(endOfMonth)
        );
      });
      setCurrentMonthHubs(hubsThisMonth);
      setKnowledgeData(data);

    } catch (error) {
      console.error("Error fetching knowledge data:", error);
    }
  };

  const fetchDataAllAssessment = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_interview`, {
        headers: {
          accept: "application/json",
        },
      });
      setAssessmentData(response.data.data);
      setTotalAssessmentHubs(response.data.data.length);
    } catch (error) {
      console.error("Error fetching knowledge data:", error);
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${apiURL}/get_all_Students`, {
        headers: {
          accept: "application/json",
        },
      });

      if (response.data && response.data.data) {
        setTotalStudents(response.data.data.length);
      }
    } catch (error) {
      console.error("Error fetching students data:", error);
    }
  };

  const groupedData = [];
  for (let i = 0; i < filteredData.length; i += 3) {
    groupedData.push(filteredData.slice(i, i + 3));
  }

  const groupedDataAssessment = [];
  for (let i = 0; i < filteredDataAssessment.length; i += 3) {
    groupedDataAssessment.push(filteredDataAssessment.slice(i, i + 3));
  }

  const chunkedData = [];
  for (let i = 0; i < allData.length; i += 3) {
    chunkedData.push(allData.slice(i, i + 3));
  }

  const filteredAllHubs = allData.filter((item) =>
    item.bot_name.toLowerCase().includes(searchAllHubs.toLowerCase())
  );

  const groupedAllHubs = [];
  for (let i = 0; i < filteredAllHubs.length; i += 3) {
    groupedAllHubs.push(filteredAllHubs.slice(i, i + 3));
  }

  const progressWidth = Math.min((totalHubs / 200) * 100, 100);
  const progressWidthStudent = Math.min((totalStudents / 4000) * 100, 100);
  const combinedHubs = totalHubs + totalAssesmentHubs;
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log('API URL:', apiUrl);
  const env_name = process.env.REACT_APP_ENV;
  console.log('ENV NAME:', env_name);
  // const role = localStorage.getItem('role')
  const role = localStorage.getItem('role') || 'guest';
  console.log(role, "user role when logged in.");

  const data = {
    labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "saturday"],
    datasets: [
      {
        label: "Queries",
        backgroundColor: "#4CAF50",
        borderColor: "#4CAF50",
        borderWidth: 1,
        hoverBackgroundColor: "#45A049",
        hoverBorderColor: "#45A049",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // var sparkLine12 = function(){
  // 	if(jQuery('#sparkline12').length > 0 ){
  // 		//Pie
  // 		$("#sparkline12").sparkline([24, 61, 51], {
  // 			type: "pie",
  // 			height: "100",
  // 			resize: !0,
  // 			sliceColors: ["rgba(179, 204, 255, 1)", "rgba(157, 189, 255, 1)", "rgba(112, 153, 237, 1)"]
  // 		});
  // 	}	
  // }

  useEffect(() => {
    fetch(`${apiURL}/chat-history/most-frequent/`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => b.question_count - a.question_count).slice(0, 3);
        setFaqs(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching FAQ data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiURL}/get_all_chat-history/`)
      .then((response) => {
        if (response.status === 200) {
          setChatData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching chat data:", error);
      });
  }, []);

  const filterChats = (filterType) => {
    const now = moment();
    if (filterType === "monthly") {
      return chatData.filter((chat) =>
        moment(chat.created_date).isSame(now, "month")
      );
    }
    if (filterType === "weekly") {
      return chatData.filter((chat) =>
        moment(chat.created_date).isSame(now, "week")
      );
    }
    if (filterType === "today") {
      return chatData.filter((chat) =>
        moment(chat.created_date).isSame(now, "day")
      );
    }
    return [];
  };

  useEffect(() => {
    filterData();
  }, [filter, chatData]);

  const filterData = () => {
    const now = new Date();
    let filtered = [];

    if (filter === 'monthly') {
      filtered = chatData.filter((item) => {
        const itemDate = new Date(item.created_date);
        return (
          itemDate.getFullYear() === now.getFullYear() &&
          itemDate.getMonth() === now.getMonth()
        );
      });
    } else if (filter === 'weekly') {
      const today = new Date(); // Keep the original date intact
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)
    
      filtered = chatData.filter((item) => {
        const itemDate = new Date(item.created_date);
        return itemDate >= startOfWeek && itemDate <= today; // Compare to today's original date
      });
    } else if (filter === 'today') {
      filtered = chatData.filter((item) => {
        const itemDate = new Date(item.created_date);
        return (
          itemDate.toDateString() === now.toDateString()
        );
      });
    }

    setDisplayedData(filtered);
  };


  const getLikes = () => displayedData.reduce((acc, item) => acc + (item.likes || 0), 0);
  const getDislikes = () => displayedData.reduce((acc, item) => acc + (item.dislikes || 0), 0);
  const getResponseCount = () => displayedData.length;
  const getResponseCountTotalConv = () => filterChats(activeTab).length;

  return (
    <div>
      <div id="main-wrapper">
        <NavBar />
        <Header />
        <SideBar userRole={role} />
        <div class="content-body default-height">
          <div class="container-fluid">
            <div className="row d-flex justify-content-center">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">{role === 'admin' ? 'Admin' : 'Welcome, Nishanth'}</h4>
                  </div>
                  <div class="card-body">
                    <div class="card-tabs">
                      <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                        <li class=" nav-item" role="presentation">
                          <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab">All Info</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Knowledge Hub Info</a>
                        </li>
                        <li class="nav-item" role="presentation">

                          <a href="#navpills2-31" class="nav-link" data-bs-toggle="tab" aria-expanded="true" aria-selected="false" role="tab" tabindex="-1">Assessment Hub info</a>

                        </li>
                      </ul>
                    </div>

                    <div class="tab-content">
                      <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                        <div class="row">
                          {/* <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                            <div class="widget-stat card bg-primary">
                              <div class="card-header border-0 pb-0">
                                <h3 class="card-title text-white">Total Students</h3>
                                <h5 class="text-white mb-0"><i class="fa fa-caret-up"></i> 422</h5>
                              </div>
                              <div class="card-body text-center">
                                <div class="ico-sparkline">
                                  <div id="sparkline12"><canvas width="100" height="100" style={{display: 'inline-block', width: '100px', height: '100px', verticalAlign: 'top'}}></canvas></div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div class="col-xl-4">
                            <div class="card">
                              <div class="card-header">
                                <h4 class="card-title">Total Conversation</h4>
                              </div>
                              <div class="card-body">
                                <div className="card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "monthly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("monthly")}
                                      >
                                        Monthly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "weekly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("weekly")}
                                      >
                                        Weekly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "today" ? "active" : ""}`}
                                        onClick={() => setActiveTab("today")}
                                      >
                                        Today
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                <div id="morris_donught" class="morris_chart_height">
                                  <svg
                                    height="210"
                                    width="556.35"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    style={{ overflow: 'hidden', position: 'relative', left: '-0.5px', top: '-0.3875px', marginLeft: "-127px" }}
                                  >
                                    <desc>Created with Raphaël 2.2.0</desc>
                                    <defs></defs>
                                    <path
                                      fill="none"
                                      stroke="#ffa7d7"
                                      d="M278.175,168.33333333333334A63.333333333333336,63.333333333333336,0,0,0,338.26029334662786,125.02170408733464"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffa7d7"
                                      stroke="#ffffff"
                                      d="M278.175,171.33333333333334A66.33333333333334,66.33333333333334,0,0,0,341.1064388209418,125.9701005967347L363.5593642294185,133.45189528200186A90,90,0,0,1,278.175,195Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ff5c00"
                                      d="M338.26029334662786,125.02170408733464A63.333333333333336,63.333333333333336,0,0,0,221.39122936749996,76.95156860238461"
                                      strokeWidth="2"
                                      opacity="1"
                                    ></path>
                                    <path
                                      fill="#ff5c00"
                                      stroke="#ffffff"
                                      d="M341.1064388209418,125.9701005967347A66.33333333333334,66.33333333333334,0,0,0,218.70147181122363,75.62295869407652L192.99934405124992,62.92735290357693A95,95,0,0,1,368.30294001994173,135.03255613100197Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ffaa2b"
                                      d="M221.39122936749996,76.95156860238461A63.333333333333336,63.333333333333336,0,0,0,278.1551032468546,168.33333020795862"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffaa2b"
                                      stroke="#ffffff"
                                      d="M218.70147181122363,75.62295869407652A66.33333333333334,66.33333333333334,0,0,0,278.154160769074,171.33333005991457L278.1467256665828,194.99999555867805A90,90,0,0,1,197.48227331171046,65.14170275075709Z"
                                      strokeWidth="3"
                                    ></path>
                                    <text
                                      x="278.175"
                                      y="95"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="15px"
                                      stroke="none"
                                      fill="#000000"
                                      fontWeight="800"
                                      transform="matrix(0.7342,0,0,0.7342,73.9277,28.1174)"
                                      strokeWidth="1.3619517276161597"
                                    >
                                      <tspan dy="5.999995231628418">&nbsp; &nbsp; Total &nbsp; &nbsp;</tspan>
                                    </text>
                                    <text
                                      x="278.175"
                                      y="115"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="14px"
                                      stroke="none"
                                      fill="#000000"
                                      transform="matrix(1.3194,0,0,1.3194,-88.8952,-34.1806)"
                                      strokeWidth="0.7578947368421053"
                                    >
                                      <tspan dy="4.799995422363281">{getResponseCountTotalConv()}</tspan>
                                    </text>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-8">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Conversation Statistics</h4>
                              </div>
                              <div className='row mt-3 d-flex justify-content-end'>
                                <div className="col-4 card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item" style={{cursor:'pointer'}}>
                                      <a
                                        className={`nav-link ${filter === 'monthly' ? 'active' : ''}`}
                                        onClick={() => setFilter('monthly')}
                                        role="tab"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                    <li className="nav-item" style={{cursor:'pointer'}}>
                                      <a
                                        className={`nav-link ${filter === 'weekly' ? 'active' : ''}`}
                                        onClick={() => setFilter('weekly')}
                                        role="tab"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item" style={{cursor:'pointer'}}>
                                      <a
                                        className={`nav-link ${filter === 'today' ? 'active' : ''}`}
                                        onClick={() => setFilter('today')}
                                        role="tab"
                                      >
                                        Today
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="card-body d-flex justify-content-around align-items-center">
                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-up fa-3x text-success stat-icon"></i>
                                  <h6 className="text-dark">Likes</h6>
                                  <span className="text-success fw-bold fs-4 counter">{getLikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-down fa-3x text-danger stat-icon"></i>
                                  <h6 className="text-dark">Dislikes</h6>
                                  <span className="text-danger fw-bold fs-4 counter">{getDislikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-file-pdf fa-3x text-warning stat-icon"></i>
                                  <h6 className="text-dark">Response</h6>
                                  <span className="text-warning fw-bold fs-4 counter">{getResponseCount()}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-12">
                            <div style={{ width: '100%' }}>
                              <div style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <div style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>
                                  <h4 style={{ margin: 0 }}>Student Analytics</h4>
                                </div>
                                <div style={{ padding: '15px' }}>
                                  <div style={{ overflowX: 'auto' }}>
                                    <table style={{ width: '100%', borderSpacing: 0, borderCollapse: 'collapse' }}>
                                      <thead>
                                        <tr>
                                          <th style={{ width: '80px', padding: '10px', textAlign: 'left' }}>#</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>Student Name</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>ID</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>Reference</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>Grade</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>topic</th>
                                          <th style={{ padding: '10px', textAlign: 'left' }}>History</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {[
                                          { id: '01', client: 'Mr. Bobby', name: '100', date: 'Economic', status: 'A', price: 'Economic Servey', statusColor: '#28a745' },
                                          { id: '02', client: 'Mr. Bobby', name: '150', date: 'Indian Polity', status: 'o', price: 'Fundamental rights ', statusColor: '#28a745' },
                                          { id: '03', client: 'Mr. Bobby', name: '220', date: 'History', status: 'C', price: 'Carnatic War', statusColor: '#28a745' }
                                        ].map((row, index) => (
                                          <tr key={index}>
                                            <td style={{ padding: '10px', color: '#000' }}><strong>{row.id}</strong></td>
                                            <td style={{ padding: '10px' }}>{row.client}</td>
                                            <td style={{ padding: '10px' }}>{row.name}</td>
                                            <td style={{ padding: '10px' }}>{row.date}</td>
                                            <td style={{ padding: '10px' }}>
                                              <span style={{ color: row.statusColor, padding: '5px 10px', borderRadius: '5px', backgroundColor: `${row.statusColor}20` }}>{row.status}</span>
                                            </td>
                                            <td style={{ padding: '10px' }}>{row.price}</td>
                                            <td style={{ padding: '10px' }}>
                                              <div style={{ position: 'relative' }}>
                                                <button style={{ background: row.statusColor, border: 'none', borderRadius: '50%', padding: '10px', cursor: 'pointer' }}>
                                                  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                      <rect x="0" y="0" width="24" height="24"></rect>
                                                      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                    </g>
                                                  </svg>
                                                </button>
                                                <div style={{ position: 'absolute', top: '40px', left: '0', background: '#fff', border: '1px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '4px', display: 'none' }}>
                                                  <a  style={{ display: 'block', padding: '10px', textDecoration: 'none', color: '#000' }}>Edit</a>
                                                  <a style={{ display: 'block', padding: '10px', textDecoration: 'none', color: '#000' }}>Delete</a>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div class='row'>
                          <h4 class="card-title mx-3 my-3">Most Viewed Subjects</h4>
                            <div class="col-xl-4 col-xxl-4 col-lg-3 col-sm-4">
                            <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-xxl-4 col-lg-3 col-sm-4">
                            <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-4 col-xxl-4 col-lg-4 col-sm-4">
                            <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class="col-xl-4 col-xxl-4 col-lg-3 col-sm-4">
                              <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="modal fade" id="exampleModalCenter" style={{display: 'none'}} aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">Modal title</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-xl-4 col-xxl-4 col-lg-3 col-sm-4">
                              <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              
                            </div>
                            <div class="col-xl-4 col-xxl-4 col-lg-3 col-sm-4">
                              <div
                                class="widget-stat card"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalCenter"
                                style={{cursor: 'pointer'}}>
                                <div class="card-body p-4">
                                  <div class="media ai-icon">
                                    <span class="me-3 bgl-primary text-primary">
                                      <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                      </svg>
                                    </span>
                                    <div class="media-body">
                                      <p class="mb-1">Patient</p>
                                      <h4 class="mb-0">3280</h4>
                                      <span class="badge badge-primary">+3.5%</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              
                            </div>
                          </div> */}
                          <div class="row">
                            <div className="row mx-2">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title">Total Hubs - {combinedHubs}</h4>
                                <input
                                  type="text"
                                  className="form-control w-25"
                                  placeholder="Search Hubs..."
                                  value={searchAllHubs}
                                  onChange={(e) => setSearchAllHubs(e.target.value)}
                                />
                              </div>

                              {allData.length > 0 ? (
                                <div
                                  id="hubsCarousel"
                                  className="carousel slide py-2"
                                  data-bs-ride="carousel"
                                >
                                  <div className="carousel-inner py-2 px-3">
                                    {groupedAllHubs.map((group, groupIndex) => (
                                      <div
                                        className={`carousel-item ${groupIndex === 0 ? "active" : ""}`}
                                        key={groupIndex}
                                      >
                                        <div className="row">
                                          {group.map((item) => (
                                            <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
                                              <div className="card overflow-hidden">
                                                <div className="card-body">
                                                  <div className="text-center">
                                                    <div className="profile-photo">
                                                      <img
                                                        src={vlogo}
                                                        width="100"
                                                        className="img-fluid rounded-circle"
                                                        alt={item.bot_name || item.assessment_name}
                                                      />
                                                    </div>
                                                    <h3 className="mt-4 mb-1">
                                                      {item.bot_name || item.assessment_name}
                                                    </h3>
                                                    <p className="text-muted">
                                                      {item.bot_name
                                                        ? `${item.bot_name} Hub`
                                                        : `${item.assessment_name} Assessment`}
                                                    </p>
                                                    <a href='/BotSummary'
                                                      className="btn btn-outline-primary btn-rounded mt-3 px-5"

                                                    >
                                                      View
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="card-footer pt-0 pb-0 text-center">
                                                  <div className="row">
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">
                                                        {item.students_count || 0}
                                                      </h3>
                                                      <span>Students</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">{item.team_count || 0}</h3>
                                                      <span>Teams</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3">
                                                      <h3 className="mb-1">0</h3>
                                                      <span>Response</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                  <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#hubsCarousel"
                                    data-bs-slide="prev"
                                  >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#hubsCarousel"
                                    data-bs-slide="next"
                                  >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              ) : (
                                <div className="text-center py-5">
                                  <h4 className="text-muted">No Data Found</h4>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="card">
                                <div class="card-header border-0 pb-0 flex-wrap">
                                  <h4 class="card-title">Hubs Statistics</h4>
                                  <div class="d-flex align-items-center mt-3 project-tab">
                                    <div class="card-tabs mt-sm-0 me-3">
                                      <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                          <a class="nav-link active" data-bs-toggle="tab" href="#monthly" role="tab">Monthly</a>
                                        </li>
                                        <li class="nav-item">
                                          <a class="nav-link" data-bs-toggle="tab" href="#weekly" role="tab">Weekly</a>
                                        </li>
                                        <li class="nav-item">
                                          <a class="nav-link" data-bs-toggle="tab" href="#today" role="tab">Today</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="dropdown ms-2">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div class="d-flex justify-content-between align-items-center flex-wrap">
                                    <div class="d-flex">
                                      <div class="d-inline-block position-relative donut-chart-sale mb-3">
                                        <span class="donut1" data-peity='{ "fill": ["rgba(136,108,192,1)", "rgba(241, 234, 255, 1)"],   "innerRadius": 20, "radius": 15}'>5/8</span>
                                      </div>
                                      <div class="ms-3">
                                        <h4 class="fs-24 mb-0">246</h4>
                                        <p class="mb-0">Total Access</p>
                                      </div>
                                    </div>
                                    <div class="d-flex">
                                      <div class="d-flex me-5">
                                        <div class="mt-2">
                                          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="6.5" cy="6.5" r="6.5" fill="#FFCF6D" />
                                          </svg>
                                        </div>
                                        <div class="ms-3">
                                          <h4 class="fs-24 mb-0 ">246</h4>
                                          <p class="mb-0">Knowledge Accessed by Students</p>
                                        </div>
                                      </div>
                                      <div class="d-flex">
                                        <div class="mt-2">
                                          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="6.5" cy="6.5" r="6.5" fill="#FFA7D7" />
                                          </svg>

                                        </div>
                                        <div class="ms-3">
                                          <h4 class="fs-24 mb-0">28</h4>
                                          <p class="mb-0">Assesement Accessed by Students</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="chartBar" class="chartBar"></div>
                                  <div class="d-flex align-items-center">
                                    <label class="form-check-label form-label mb-0" for="flexSwitchCheckChecked1">Number</label>
                                    <div class="form-check form-switch toggle-switch">
                                      <input class="form-check-input custome" type="checkbox" id="flexSwitchCheckChecked1" checked />

                                    </div>
                                    <label class="form-check-label form-label mb-0 ms-3" for="flexSwitchCheckChecked2">Analytics</label>
                                    <div class="form-check form-switch toggle-switch">
                                      <input class="form-check-input custome" type="checkbox" id="flexSwitchCheckChecked2" checked />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="card">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-xl-6 col-sm-6">
                                      <div class=" owl-carousel card-slider">
                                        <div class="items">
                                          <h4 class="card-title mb-4">Total Hub Utilization</h4>
                                          <span class="fs-14 font-w400 text-black">5% of the tokens have been allocated to the TNPC hub, 15% to the NEET hub, and 10% to the SSC hub. These allocations reflect a strategic distribution of resources to support the development and operations of each respective hub. </span>
                                        </div>
                                        <div class="items">
                                          <h4 class="fs-20 font-w700 mb-4">Total Hub Utilization</h4>
                                          <span class="fs-14 font-w400 text-black">5% of the tokens have been allocated to the TNPC hub, 15% to the NEET hub, and 10% to the SSC hub. These allocations reflect a strategic distribution of resources to support the development and operations of each respective hub.  </span>
                                        </div>
                                        <div class="items">
                                          <h4 class="fs-20 font-w700 mb-4">Total Hub Utilization</h4>
                                          <span class="fs-14 font-w400 text-black">5% of the tokens have been allocated to the TNPC hub, 15% to the NEET hub, and 10% to the SSC hub. These allocations reflect a strategic distribution of resources to support the development and operations of each respective hub. </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-6 redial col-sm-6 align-self-center">
                                      <div id="redial"></div>
                                      <span class="text-center d-block fs-18 font-w600">Total Utilized <small class="text-success">70%</small></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="card">
                                <div class="card-header border-0 pb-0">
                                  <h4 class="card-title mb-0">Total conversation rate</h4>
                                  <div class="dropdown ">
                                    <div class="btn-link" data-bs-toggle="dropdown">
                                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                        <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                        <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                      </svg>
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right">
                                      <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                      <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                    </div>
                                  </div>
                                </div>
                                <div class="card-body pb-0">
                                  <div id="revenueMap" class="revenueMap"></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-12">
                              <div class="row">
                                <div class="col-xl-6 col-xxl-6 col-lg-6 col-sm-6">
                                  <div class="widget-stat card bg-secondary">
                                    <div class="card-body p-4">
                                      <div class="media">
                                        <span class="me-3">
                                          <i class="la la-graduation-cap"></i>
                                        </span>
                                        <div class="media-body text-white">
                                          <p class="mb-1">Total Course</p>
                                          <h3 class="text-white">28</h3>
                                          <div class="progress mb-2 bg-primary">
                                            <div class="progress-bar progress-animated bg-white" style={{ width: `${progressWidth}%` }}></div>
                                          </div>
                                          <small>76% Increase in 20 Days</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xl-6 col-xxl-6 col-lg-6 col-sm-6">
                                  <div class="widget-stat card bg-primary">
                                    <div class="card-body  p-4">
                                      <div class="media">
                                        <span class="me-3">
                                          <i class="la la-users"></i>
                                        </span>
                                        <div class="media-body text-white">
                                          <p class="mb-1">Total Students</p>
                                          <h3 class="text-white counter">{totalStudents}</h3>
                                          <div class="progress mb-2 bg-secondary">
                                            <div class="progress-bar progress-animated bg-white" style={{ width: `${progressWidthStudent}%` }}></div>
                                          </div>
                                          <small>80% Increase in 20 Days</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xl-6 col-sm-6">
                                  <div class="card">
                                    <div class="card-body d-flex px-4  justify-content-between">
                                      <div>
                                        <div class="">
                                          <h2 class="fs-32 font-w700 counter">{combinedHubs}</h2>
                                          <h4 class="mb-0 text-nowrap">Total Hubs</h4>
                                          <p class="mb-0"><strong class="text-danger">-2%</strong> than last month</p>
                                        </div>
                                      </div>
                                      <div id="NewCustomers"></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xl-6 col-sm-6">
                                  <div class="card">
                                    <div class="card-body d-flex px-4  justify-content-between">
                                      <div>
                                        <div class="">
                                          <h2 class="fs-32 font-w700 counter">{currentMonthHubs}</h2>
                                          <h4 class="mb-0 text-nowrap">New Hubs</h4>
                                          <p class="mb-0"><strong class="text-success">+2%</strong> than last month</p>
                                        </div>
                                      </div>
                                      <div id="NewCustomers1"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>



                            {/* <div class="col-xl-12 col-lg-12">
                              <div class="card">
                                <div class="card-header pb-0 border-0">
                                  <div>
                                    <h4 class="card-title">Messages</h4>
                                    <p class="mb-0">Lorem ipsum dolor sit amet</p>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-rounded" data-bs-toggle="modal" data-bs-target="#sendMessageModal" >+ New Messages</a>
                                  </div>
                                </div>
                                <div class="card-body px-0 pt-0">
                                  <div class="msg-bx d-flex justify-content-between align-items-center">
                                    <div class="msg d-flex align-items-center w-100">
                                      <div class="image-box active">
                                        <img src="../../assets/images/profile/small/pic6.jpg" alt="" />
                                      </div>
                                      <div class="ms-3 w-100 ">
                                        <a href="#"><h5 class="mb-1">Maren Rosser</h5></a>
                                        <div class="d-flex justify-content-between">
                                          <p class="me-auto mb-0 text-black">Hei, dont forget to clear server cache!</p>
                                          <small class="me-4">25min ago</small>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="dropdown">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="msg-bx d-flex justify-content-between align-items-center">
                                    <div class="msg d-flex align-items-center w-100">
                                      <div class="image-box">
                                        <img src="../../assets/images/profile/small/pic7.jpg" alt="" />
                                      </div>
                                      <div class="ms-3 w-100">
                                        <a href="#"><h5 class="mb-1">Kaiya Bergson</h5></a>
                                        <div class="d-flex justify-content-between">
                                          <p class="me-auto mb-0 text-black">I remember that project due is tomorrow.</p>
                                          <small class="me-4">Yesterday, 8:24 AM</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="dropdown">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="msg-bx d-flex justify-content-between align-items-center">
                                    <div class="msg d-flex align-items-center w-100">
                                      <div class="image-box active">
                                        <img src="../../assets/images/profile/small/pic4.jpg" alt="" />
                                      </div>
                                      <div class="ms-3 w-100">
                                        <a href="#"><h5 class="mb-1">Ruben Press</h5></a>
                                        <div class="d-flex justify-content-between">
                                          <p class="me-auto mb-0 text-black">Ok sir. I will fix it as soon as possible</p>
                                          <small class="me-4">December 12th, 2020  10:24 AM</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="dropdown">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="msg-bx d-flex justify-content-between align-items-center">
                                    <div class="msg d-flex align-items-center w-100">
                                      <div class="image-box active">
                                        <img src="../../assets/images/profile/small/pic3.jpg" alt="" />
                                      </div>
                                      <div class="ms-3 w-100">
                                        <a href="#"><h5 class="mb-1">Cristofer Torff</h5></a>
                                        <div class="d-flex justify-content-between">
                                          <p class="me-auto mb-0 text-black">Maybe we should schedule that meeting</p>
                                          <small class="me-4">December 12th, 2020  10:24 AM</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="dropdown">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="msg-bx d-flex justify-content-between align-items-center">
                                    <div class="msg d-flex align-items-center w-100">
                                      <div class="image-box active">
                                        <img src="../../assets/images/profile/small/pic5.jpg" alt="" />
                                      </div>
                                      <div class="ms-3 w-100">
                                        <a href="#"><h5 class="mb-1">Ann Rosser</h5></a>
                                        <div class="d-flex justify-content-between">
                                          <p class="me-auto mb-0 text-black">I dont’t know where that files saved dude.</p>
                                          <small class="me-4">Yesterday, 8:24 AM</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="dropdown">
                                      <div class="btn-link" data-bs-toggle="dropdown">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                          <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                        </svg>
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Most Viewed Subjects</h4>
                              </div>
                              <div class="card-body pb-0">
                                <div id="emailchart"> </div>
                                <div class="mb-3 mt-4">
                                  <h4>Legend</h4>
                                </div>
                                <div class="email-lagend mb-5">
                                  <div class="d-flex align-items-center justify-content-between mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#886CC0" />
                                      </svg>
                                      Economics (27%)
                                    </span>
                                    <h5 class="mb-0 font-w600">763</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#26E023" />
                                      </svg>
                                      History (11%)
                                    </span>
                                    <h5 class="mb-0 font-w600">321</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#61CFF1" />
                                      </svg>
                                      Geography (22%)
                                    </span>
                                    <h5 class="mb-0 font-w600">69</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FFDA7C" />
                                      </svg>
                                      Indian Polity (15%)
                                    </span>
                                    <h5 class="mb-0 font-w600">154</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-0 spam">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FF86B1" />
                                      </svg>
                                      Current Affairs (25%)
                                    </span>
                                    <h5 class="mb-0 font-w600">696</h5>
                                  </div>
                                </div>

                              </div>
                              {/* <div class="card-footer border-0 pt-0">
                                <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded d-block">Update Progress</a>

                              </div> */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Daily Stats Chart</h4>
                              </div>
                              <div className="card-body">
                                <div style={{ height: "278px", width: "100%" }}>
                                  <Bar data={data} options={options} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="card">
                              <div className="card-header d-block">
                                <h4 className="card-title">Frequently Asked Questions</h4>
                              </div>
                              <div className="card-body">
                                <div className="accordion accordion-primary" id="accordion-one">
                                  {faqs.map((faq, index) => (
                                    <div className="accordion-item" key={faq.id}>
                                      <h2 className="accordion-header">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse-${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse-${index}`}
                                        >
                                          {faq.chat_question}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapse-${index}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordion-one"
                                      >
                                        <div className="accordion-body">
                                          <span className='d-flex align-items-center justify-content-around'>
                                            {/* <h6 className='text-success'><span className='text-dark'>Likes : </span> {faq.likes}</h6>
                                            <h6 className='text-danger'><span className='text-dark'>Dislikes : </span> {faq.dislikes}</h6> */}
                                            <h6 className='text-dark'>Frequently asked <span className='text-warning'> {faq.question_count}</span> times</h6>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {faqs.length === 0 && <p>No FAQs available.</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">Frequently Asked Topics </h4>
                              </div>
                              <div class="card-body">
                                <div class="bootstrap-badge">
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Monetary policy</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Economic Survey</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Inflation</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>ocean currents</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Fundamental Rights</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="navpills2-21" class="tab-pane" role="tabpanel">
                        <div class="row">
                          <div class="col-xl-4">
                          <div class="card">
                              <div class="card-header">
                                <h4 class="card-title">Total Conversation</h4>
                              </div>
                              <div class="card-body">
                                <div className="card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "monthly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("monthly")}
                                      >
                                        Monthly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "weekly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("weekly")}
                                      >
                                        Weekly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "today" ? "active" : ""}`}
                                        onClick={() => setActiveTab("today")}
                                      >
                                        Today
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                <div id="morris_donught" class="morris_chart_height">
                                  <svg
                                    height="210"
                                    width="556.35"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    style={{ overflow: 'hidden', position: 'relative', left: '-0.5px', top: '-0.3875px', marginLeft: "-127px" }}
                                  >
                                    <desc>Created with Raphaël 2.2.0</desc>
                                    <defs></defs>
                                    <path
                                      fill="none"
                                      stroke="#ffa7d7"
                                      d="M278.175,168.33333333333334A63.333333333333336,63.333333333333336,0,0,0,338.26029334662786,125.02170408733464"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffa7d7"
                                      stroke="#ffffff"
                                      d="M278.175,171.33333333333334A66.33333333333334,66.33333333333334,0,0,0,341.1064388209418,125.9701005967347L363.5593642294185,133.45189528200186A90,90,0,0,1,278.175,195Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ff5c00"
                                      d="M338.26029334662786,125.02170408733464A63.333333333333336,63.333333333333336,0,0,0,221.39122936749996,76.95156860238461"
                                      strokeWidth="2"
                                      opacity="1"
                                    ></path>
                                    <path
                                      fill="#ff5c00"
                                      stroke="#ffffff"
                                      d="M341.1064388209418,125.9701005967347A66.33333333333334,66.33333333333334,0,0,0,218.70147181122363,75.62295869407652L192.99934405124992,62.92735290357693A95,95,0,0,1,368.30294001994173,135.03255613100197Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ffaa2b"
                                      d="M221.39122936749996,76.95156860238461A63.333333333333336,63.333333333333336,0,0,0,278.1551032468546,168.33333020795862"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffaa2b"
                                      stroke="#ffffff"
                                      d="M218.70147181122363,75.62295869407652A66.33333333333334,66.33333333333334,0,0,0,278.154160769074,171.33333005991457L278.1467256665828,194.99999555867805A90,90,0,0,1,197.48227331171046,65.14170275075709Z"
                                      strokeWidth="3"
                                    ></path>
                                    <text
                                      x="278.175"
                                      y="95"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="15px"
                                      stroke="none"
                                      fill="#000000"
                                      fontWeight="800"
                                      transform="matrix(0.7342,0,0,0.7342,73.9277,28.1174)"
                                      strokeWidth="1.3619517276161597"
                                    >
                                      <tspan dy="5.999995231628418">&nbsp; &nbsp; Total &nbsp; &nbsp;</tspan>
                                    </text>
                                    <text
                                      x="278.175"
                                      y="115"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="14px"
                                      stroke="none"
                                      fill="#000000"
                                      transform="matrix(1.3194,0,0,1.3194,-88.8952,-34.1806)"
                                      strokeWidth="0.7578947368421053"
                                    >
                                      <tspan dy="4.799995422363281">{getResponseCountTotalConv()}</tspan>
                                    </text>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-8">
                          <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Conversation Statistics</h4>
                              </div>
                              <div className='row mt-3 d-flex justify-content-end'>
                                <div className="col-4 card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'monthly' ? 'active' : ''}`}
                                        onClick={() => setFilter('monthly')}
                                        role="tab"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'weekly' ? 'active' : ''}`}
                                        onClick={() => setFilter('weekly')}
                                        role="tab"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'today' ? 'active' : ''}`}
                                        onClick={() => setFilter('today')}
                                        role="tab"
                                      >
                                        Today
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="card-body d-flex justify-content-around align-items-center">
                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-up fa-3x text-success stat-icon"></i>
                                  <h6 className="text-dark">Likes</h6>
                                  <span className="text-success fw-bold fs-4 counter">{getLikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-down fa-3x text-danger stat-icon"></i>
                                  <h6 className="text-dark">Dislikes</h6>
                                  <span className="text-danger fw-bold fs-4 counter">{getDislikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-file-pdf fa-3x text-warning stat-icon"></i>
                                  <h6 className="text-dark">Response</h6>
                                  <span className="text-warning fw-bold fs-4 counter">{getResponseCount()}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            {/* <div class="card-header border-0 pb-0 mb-3">
                              <div>
                                <h4 class="card-title">Subject Wise</h4>

                              </div>
                            </div> */}

                            <div className="row mx-2">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title">Total Hubs - {totalHubs}</h4>
                                <input
                                  type="text"
                                  className="form-control w-25"
                                  placeholder="Search Hubs..."
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                />
                              </div>
                              {groupedData.length === 0 ? (
                                <div className="text-center py-5">
                                  <h5>No data found</h5>
                                </div>
                              ) : (
                                <div id="hubsCarouselKnowledge" className="carousel slide py-2" data-bs-ride="carousel">
                                  <div className="carousel-inner py-2 px-3">
                                    {groupedData.map((group, groupIndex) => (
                                      <div
                                        className={`carousel-item ${groupIndex === 0 ? "active" : ""}`}
                                        key={groupIndex}
                                      >
                                        <div className="row">
                                          {group.map((item) => (
                                            <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
                                              <div className="card overflow-hidden">
                                                <div className="card-body">
                                                  <div className="text-center">
                                                    <div className="profile-photo">
                                                      <img
                                                        src={vlogo}
                                                        width="100"
                                                        className="img-fluid rounded-circle"
                                                        alt={item.bot_name}
                                                      />
                                                    </div>
                                                    <h3 className="mt-4 mb-1">{item.bot_name}</h3>
                                                    <p className="text-muted">{item.bot_name + " Hub"}</p>
                                                    <a href='/BotSummary'
                                                      className="btn btn-outline-primary btn-rounded mt-3 px-5"

                                                    >
                                                      View
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="card-footer pt-0 pb-0 text-center">
                                                  <div className="row">
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">{item.students_count || 0}</h3>
                                                      <span>Student</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">{item.team_count || 0}</h3>
                                                      <span>Teams</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3">
                                                      <h3 className="mb-1">0</h3>
                                                      <span>Response</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#hubsCarouselKnowledge"
                                    data-bs-slide="prev"
                                  >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#hubsCarouselKnowledge"
                                    data-bs-slide="next"
                                  >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              )}
                            </div>


                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Most Viewed Subjects</h4>
                              </div>
                              <div class="card-body pb-0">
                                <div id="emailchart"> </div>
                                <div class="mb-3 mt-4">
                                  <h4>Legend</h4>
                                </div>
                                <div class="email-lagend mb-5">
                                  <div class="d-flex align-items-center justify-content-between mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#886CC0" />
                                      </svg>
                                      Economics (27%)
                                    </span>
                                    <h5 class="mb-0 font-w600">763</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#26E023" />
                                      </svg>
                                      History (11%)
                                    </span>
                                    <h5 class="mb-0 font-w600">321</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#61CFF1" />
                                      </svg>
                                      Geography (22%)
                                    </span>
                                    <h5 class="mb-0 font-w600">69</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FFDA7C" />
                                      </svg>
                                      Indian Polity (15%)
                                    </span>
                                    <h5 class="mb-0 font-w600">154</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-0 spam">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FF86B1" />
                                      </svg>
                                      Current Affairs (25%)
                                    </span>
                                    <h5 class="mb-0 font-w600">696</h5>
                                  </div>
                                </div>

                              </div>
                              {/* <div class="card-footer border-0 pt-0">
                                <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded d-block">Update Progress</a>

                              </div> */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Daily Stats Chart</h4>
                              </div>
                              <div className="card-body">
                                <div style={{ height: "278px", width: "100%" }}>
                                  <Bar data={data} options={options} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="card">
                              <div className="card-header d-block">
                                <h4 className="card-title">Frequently Asked Questions</h4>
                              </div>
                              <div className="card-body">
                                <div className="accordion accordion-primary" id="accordion-two">
                                  {faqs.map((faq, index) => (
                                    <div className="accordion-item" key={faq.id}>
                                      <h2 className="accordion-header">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse-${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse-${index}`}
                                        >
                                          {faq.chat_question}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapse-${index}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordion-two"
                                      >
                                        <div className="accordion-body">
                                          <span className='d-flex align-items-center justify-content-around'>
                                            {/* <h6 className='text-success'><span className='text-dark'>Likes : </span> {faq.likes}</h6>
                                            <h6 className='text-danger'><span className='text-dark'>Dislikes : </span> {faq.dislikes}</h6> */}
                                            <h6 className='text-dark'>Frequently asked <span className='text-warning'> {faq.question_count}</span> times</h6>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {faqs.length === 0 && <p>No FAQs available.</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">Frequently Asked Topics </h4>
                              </div>
                              <div class="card-body">
                                <div class="bootstrap-badge">
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Monetary policy</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Economic Survey</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Inflation</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>ocean currents</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Fundamental Rights</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="row">
                          <div class="col-xl-12">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">Default Accordion</h4>
                                <p class="m-0 subtitle">Default accordion. Add <code>accordion</code> class in root</p>
                              </div>
                              <div class="card-body">
                                <div class="accordion accordion-primary" id="accordion-one">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#default-collapseOne" aria-expanded="false" aria-controls="default-collapseOne">
                                        Accordion Header One
                                      </button>
                                    </h2>
                                    <div id="default-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordion-one">
                                      <div class="accordion-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#default-collapseTwo" aria-expanded="false" aria-controls="default-collapseTwo">
                                        Accordion Header Two
                                      </button>
                                    </h2>
                                    <div id="default-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion-one">
                                      <div class="accordion-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#default-collapseThree" aria-expanded="false">
                                        Accordion Header Three
                                      </button>
                                    </h2>
                                    <div id="default-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordion-one">
                                      <div class="accordion-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                            <div class="widget-stat card">
                              <div class="card-body p-4">
                                <h4 class="card-title">Total Students</h4>
                                <h3>3280</h3>
                                <div class="progress mb-2">
                                  <div class="progress-bar progress-animated bg-primary" style={{ width: '80%' }}></div>
                                </div>
                                <small>80% Increase in 20 Days</small>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                            <div class="widget-stat card">
                              <div class="card-body p-4">
                                <h4 class="card-title">New Students</h4>
                                <h3>245</h3>
                                <div class="progress mb-2">
                                  <div class="progress-bar progress-animated bg-warning" style={{ width: '50%' }}></div>
                                </div>
                                <small>50% Increase in 25 Days</small>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                            <div class="widget-stat card">
                              <div class="card-body p-4">
                                <h4 class="card-title">Total Course</h4>
                                <h3>28</h3>
                                <div class="progress mb-2">
                                  <div class="progress-bar progress-animated bg-red" style={{ width: '76%' }}></div>
                                </div>
                                <small>76% Increase in 20 Days</small>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                            <div class="widget-stat card">
                              <div class="card-body p-4">
                                <h4 class="card-title">Fees Collection</h4>
                                <h3>25160$</h3>
                                <div class="progress mb-2">
                                  <div class="progress-bar progress-animated bg-success" style={{ width: '30%' }}></div>
                                </div>
                                <small>30% Increase in 30 Days</small>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div id="navpills2-31" class="tab-pane" role="tabpanel">
                        <div class="row">
                          <div class="col-xl-4">
                          <div class="card">
                              <div class="card-header">
                                <h4 class="card-title">Total Conversation</h4>
                              </div>
                              <div class="card-body">
                                <div className="card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "monthly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("monthly")}
                                      >
                                        Monthly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "weekly" ? "active" : ""}`}
                                        onClick={() => setActiveTab("weekly")}
                                      >
                                        Weekly
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === "today" ? "active" : ""}`}
                                        onClick={() => setActiveTab("today")}
                                      >
                                        Today
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                <div id="morris_donught" class="morris_chart_height">
                                  <svg
                                    height="210"
                                    width="556.35"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    style={{ overflow: 'hidden', position: 'relative', left: '-0.5px', top: '-0.3875px', marginLeft: "-127px" }}
                                  >
                                    <desc>Created with Raphaël 2.2.0</desc>
                                    <defs></defs>
                                    <path
                                      fill="none"
                                      stroke="#ffa7d7"
                                      d="M278.175,168.33333333333334A63.333333333333336,63.333333333333336,0,0,0,338.26029334662786,125.02170408733464"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffa7d7"
                                      stroke="#ffffff"
                                      d="M278.175,171.33333333333334A66.33333333333334,66.33333333333334,0,0,0,341.1064388209418,125.9701005967347L363.5593642294185,133.45189528200186A90,90,0,0,1,278.175,195Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ff5c00"
                                      d="M338.26029334662786,125.02170408733464A63.333333333333336,63.333333333333336,0,0,0,221.39122936749996,76.95156860238461"
                                      strokeWidth="2"
                                      opacity="1"
                                    ></path>
                                    <path
                                      fill="#ff5c00"
                                      stroke="#ffffff"
                                      d="M341.1064388209418,125.9701005967347A66.33333333333334,66.33333333333334,0,0,0,218.70147181122363,75.62295869407652L192.99934405124992,62.92735290357693A95,95,0,0,1,368.30294001994173,135.03255613100197Z"
                                      strokeWidth="3"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke="#ffaa2b"
                                      d="M221.39122936749996,76.95156860238461A63.333333333333336,63.333333333333336,0,0,0,278.1551032468546,168.33333020795862"
                                      strokeWidth="2"
                                      opacity="0"
                                    ></path>
                                    <path
                                      fill="#ffaa2b"
                                      stroke="#ffffff"
                                      d="M218.70147181122363,75.62295869407652A66.33333333333334,66.33333333333334,0,0,0,278.154160769074,171.33333005991457L278.1467256665828,194.99999555867805A90,90,0,0,1,197.48227331171046,65.14170275075709Z"
                                      strokeWidth="3"
                                    ></path>
                                    <text
                                      x="278.175"
                                      y="95"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="15px"
                                      stroke="none"
                                      fill="#000000"
                                      fontWeight="800"
                                      transform="matrix(0.7342,0,0,0.7342,73.9277,28.1174)"
                                      strokeWidth="1.3619517276161597"
                                    >
                                      <tspan dy="5.999995231628418">&nbsp; &nbsp; Total &nbsp; &nbsp;</tspan>
                                    </text>
                                    <text
                                      x="278.175"
                                      y="115"
                                      textAnchor="middle"
                                      fontFamily="Arial"
                                      fontSize="14px"
                                      stroke="none"
                                      fill="#000000"
                                      transform="matrix(1.3194,0,0,1.3194,-88.8952,-34.1806)"
                                      strokeWidth="0.7578947368421053"
                                    >
                                      <tspan dy="4.799995422363281">{getResponseCountTotalConv()}</tspan>
                                    </text>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-8">
                          <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Conversation Statistics</h4>
                              </div>
                              <div className='row mt-3 d-flex justify-content-end'>
                                <div className="col-4 card-tabs mt-sm-0 me-3">
                                  <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'monthly' ? 'active' : ''}`}
                                        onClick={() => setFilter('monthly')}
                                        role="tab"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'weekly' ? 'active' : ''}`}
                                        onClick={() => setFilter('weekly')}
                                        role="tab"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${filter === 'today' ? 'active' : ''}`}
                                        onClick={() => setFilter('today')}
                                        role="tab"
                                      >
                                        Today
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="card-body d-flex justify-content-around align-items-center">
                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-up fa-3x text-success stat-icon"></i>
                                  <h6 className="text-dark">Likes</h6>
                                  <span className="text-success fw-bold fs-4 counter">{getLikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-thumbs-down fa-3x text-danger stat-icon"></i>
                                  <h6 className="text-dark">Dislikes</h6>
                                  <span className="text-danger fw-bold fs-4 counter">{getDislikes()}</span>
                                </div>

                                <div className="stat-item text-center">
                                  <i className="fa fa-file-pdf fa-3x text-warning stat-icon"></i>
                                  <h6 className="text-dark">Response</h6>
                                  <span className="text-warning fw-bold fs-4 counter">{getResponseCount()}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            {/* <div class="card-header border-0 pb-0 mb-3">
                              <div>
                                <h4 class="card-title">Subject Wise</h4>

                              </div>
                            </div> */}
                            <div className="row mx-2">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title">Total Hubs - {totalAssesmentHubs}</h4>
                                <input
                                  type="text"
                                  className="form-control w-25"
                                  placeholder="Search Hubs..."
                                  value={searchTermByAssessment}
                                  onChange={(e) => setSearchTermByAssessment(e.target.value)}
                                />
                              </div>
                              {groupedDataAssessment.length === 0 ? (
                                <div className="text-center py-5">
                                  <h5>No data found</h5>
                                </div>
                              ) : (
                                <div id="hubsCarouselAssessment" className="carousel slide py-2" data-bs-ride="carousel">
                                  <div className="carousel-inner py-2 px-3">
                                    {groupedDataAssessment.map((group, groupIndex) => (
                                      <div
                                        className={`carousel-item ${groupIndex === 0 ? "active" : ""}`}
                                        key={groupIndex}
                                      >
                                        <div className="row">
                                          {group.map((item) => (
                                            <div className="col-lg-4 col-md-6 mb-4" key={item.id}>
                                              <div className="card overflow-hidden">
                                                <div className="card-body">
                                                  <div className="text-center">
                                                    <div className="profile-photo">
                                                      <img
                                                        src={vlogo}
                                                        width="100"
                                                        className="img-fluid rounded-circle"
                                                        alt={item.bot_name}
                                                      />
                                                    </div>
                                                    <h3 className="mt-4 mb-1">{item.bot_name}</h3>
                                                    <p className="text-muted">{item.bot_name + " Hub"}</p>
                                                    <a href='/BotSummary'
                                                      className="btn btn-outline-primary btn-rounded mt-3 px-5"

                                                    >
                                                      View
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="card-footer pt-0 pb-0 text-center">
                                                  <div className="row">
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">{item.students_count || 0}</h3>
                                                      <span>Student</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3 border-end">
                                                      <h3 className="mb-1">{item.team_count || 0}</h3>
                                                      <span>Teams</span>
                                                    </div>
                                                    <div className="col-4 pt-3 pb-3">
                                                      <h3 className="mb-1">0</h3>
                                                      <span>Response</span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#hubsCarouselAssessment"
                                    data-bs-slide="prev"
                                  >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#hubsCarouselAssessment"
                                    data-bs-slide="next"
                                  >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              )}
                            </div>

                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Most Viewed Subjects</h4>
                              </div>
                              <div class="card-body pb-0">
                                <div id="emailchart"> </div>
                                <div class="mb-3 mt-4">
                                  <h4>Legend</h4>
                                </div>
                                <div class="email-lagend mb-5">
                                  <div class="d-flex align-items-center justify-content-between mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#886CC0" />
                                      </svg>
                                      Economics (27%)
                                    </span>
                                    <h5 class="mb-0 font-w600">763</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#26E023" />
                                      </svg>
                                      History (11%)
                                    </span>
                                    <h5 class="mb-0 font-w600">321</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#61CFF1" />
                                      </svg>
                                      Geography (22%)
                                    </span>
                                    <h5 class="mb-0 font-w600">69</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-3">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FFDA7C" />
                                      </svg>
                                      Indian Polity (15%)
                                    </span>
                                    <h5 class="mb-0 font-w600">154</h5>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-between  mb-0 spam">
                                    <span class="fs-16 text-gray">
                                      <svg class="me-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="6" fill="#FF86B1" />
                                      </svg>
                                      Current Affairs (25%)
                                    </span>
                                    <h5 class="mb-0 font-w600">696</h5>
                                  </div>
                                </div>

                              </div>
                              {/* <div class="card-footer border-0 pt-0">
                                <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded d-block">Update Progress</a>

                              </div> */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-12 col-sm-12 mt-5">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Daily Stats Chart</h4>
                              </div>
                              <div className="card-body">
                                <div style={{ height: "278px", width: "100%" }}>
                                  <Bar data={data} options={options} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="card">
                              <div className="card-header d-block">
                                <h4 className="card-title">Frequently Asked Questions</h4>
                              </div>
                              <div className="card-body">
                                <div className="accordion accordion-primary" id="accordion-three">
                                  {faqs.map((faq, index) => (
                                    <div className="accordion-item" key={faq.id}>
                                      <h2 className="accordion-header">
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#collapse-${index}`}
                                          aria-expanded="false"
                                          aria-controls={`collapse-${index}`}
                                        >
                                          {faq.chat_question}
                                        </button>
                                      </h2>
                                      <div
                                        id={`collapse-${index}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordion-three"
                                      >
                                        <div className="accordion-body">
                                          <span className='d-flex align-items-center justify-content-around'>
                                            {/* <h6 className='text-success'><span className='text-dark'>Likes : </span> {faq.likes}</h6>
                                            <h6 className='text-danger'><span className='text-dark'>Dislikes : </span> {faq.dislikes}</h6> */}
                                            <h6 className='text-dark'>Frequently asked <span className='text-warning'> {faq.question_count}</span> times</h6>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {faqs.length === 0 && <p>No FAQs available.</p>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">Frequently Asked Topics </h4>
                              </div>
                              <div class="card-body">
                                <div class="bootstrap-badge">
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Monetary policy</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Economic Survey</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Inflation</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>ocean currents</a>
                                  <a href="javascript:void(0)" className="badge badge-rounded badge-outline-primary mx-1 mt-3"
                                    style={{
                                      fontSize: '1rem',
                                      padding: '0.5em 1em',
                                      borderRadius: '1rem',
                                    }}>Fundamental Rights</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="row">
                          <div class="row">
                            <div className="card-body d-flex justify-content-end align-items-center">
                              <button type="button" className="btn light btn-primary">Customization</button>
                              <button type="button" className="btn light btn-primary mx-4">Add New File</button>
                              <button type="button" className="btn light btn-info">Restart Hub</button>
                            </div>
                          </div>
                          <div class="card col-xl-3 d-flex mx-xl-5">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Current affairs New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl-3 mx-xl-5">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Current affairs New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1  px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl-3 mx-xl-5">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Current affairs New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                          <div class="card col-xl-3 mx-xl-5">
                            <div class="card-body text-center ai-icon text-primary">
                              <svg
                                id="pdf-icon"
                                className="my-1"
                                viewBox="0 0 24 24"
                                width="60"
                                height="60"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path d="M6 2H16L20 6V22H4V2H6Z" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 2V10H20" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 12H16" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8 16H12" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <h5 class="my-1">Current affairs New.pdf</h5>
                              <a href="javascript:void(0);" class="btn btn-primary btn-xs my-1 px-3">
                                Download
                              </a>
                              <button type="button" class="btn btn-danger btn-xs my-1 mx-3" onclick="handleButtonClick()">
                                Delete
                              </button>
                            </div>
                          </div>
                        </div> */}
                        {/* <div class="row">
                          <div class="card-header border-0 pb-0 mb-3">
                            <div>
                              <h4 class="card-title">Assessment Hub Setting</h4>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="card border-0 pb-0">
                              <div class="card-header border-0 pb-0">
                                <h4 class="card-title">Select Files For Assessment</h4>
                              </div>
                              <div class="card-body p-0">
                                <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                  <ul class="timeline">
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-success check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox1" required="" />
                                          <label class="form-check-label" for="customCheckBox1"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">JAVA.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox2" required="" />
                                          <label class="form-check-label" for="customCheckBox2"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Python.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox3" required="" />
                                          <label class="form-check-label" for="customCheckBox3"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Current Affairs.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-info check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox4" required="" />
                                          <label class="form-check-label" for="customCheckBox4"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">FrontEnd.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-success check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox5" required="" />
                                          <label class="form-check-label" for="customCheckBox5"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Artificial Intelligence.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="form-check custom-checkbox checkbox-warning check-lg me-3">
                                          <input type="checkbox" class="form-check-input" id="customCheckBox6" required="" />
                                          <label class="form-check-label" for="customCheckBox6"></label>
                                        </div>
                                        <div class="media-body">
                                          <h5 class="mb-0">Stand up</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="card border-0 pb-0">
                              <div class="card-header border-0 pb-0">
                                <h4 class="card-title">Selected Files</h4>
                              </div>
                              <div class="card-body p-0">
                                <div id="DZ_W_Todo4" class="widget-media dlab-scroll p-4 height370">
                                  <ul class="timeline">
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Current Affairs.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Artificial Intelligence.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Python.pdf</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                       <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Do something else</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div> 
                                    </li>
                                    <li>
                                     <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Get up</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                       <div class="timeline-panel">
                                        <div class="media-body">
                                          <h5 class="mb-0">Stand up</h5>
                                          <small class="text-muted">29 July 2020 - 02:26 PM</small>
                                        </div>
                                      </div> 
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="row">
              <div class="col-xl-12">
                <div class="row">
                  <div class="col-xl-6">

                  </div>
                  <div class="col-xl-6">
                    <div class="row">
                      <div class="col-xl-12">


                      </div>
                      <div class="col-xl-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-xl-6 col-sm-6">
                                <div class=" owl-carousel card-slider">
                                  <div class="items">
                                    <h4 class="card-title mb-4">Fillow Company Profile Website Project</h4>
                                    <span class="fs-14 font-w400 text-black">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id quibusdam eaque vero ullam odit nostrum nemo excepturi explicabo ipsum voluptas nihil quae doloremque ducimus. </span>
                                  </div>
                                  <div class="items">
                                    <h4 class="fs-20 font-w700 mb-4">Fillow Company Profile Website Project</h4>
                                    <span class="fs-14 font-w400 text-black">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab autem, quae debitis voluptatum omnis, quaerat deserunt nam voluptates exercitationem facere sequi dolorem.  </span>
                                  </div>
                                  <div class="items">
                                    <h4 class="fs-20 font-w700 mb-4">Fillow Company Profile Website Project</h4>
                                    <span class="fs-14 font-w400 text-black">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab autem, quae debitis voluptatum omnis, quaerat deserunt nam voluptates exercitationem facere sequi dolorem. </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-6 redial col-sm-6 align-self-center">
                                <div id="redial"></div>
                                <span class="text-center d-block fs-18 font-w600">On Progress <small class="text-success">70%</small></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12">

                      </div>
                      <div class="col-xl-12 col-lg-12">
                        <div class="card">
                          <div class="card-header pb-0 border-0">
                            <div>
                              <h4 class="card-title">Messages</h4>
                              <p class="mb-0">Lorem ipsum dolor sit amet</p>
                            </div>
                            <div>
                              <a href="javascript:void(0);" class="btn btn-primary btn-rounded" data-bs-toggle="modal" data-bs-target="#sendMessageModal" >+ New Messages</a>
                            </div>
                          </div>
                          <div class="card-body px-0 pt-0">
                            <div class="msg-bx d-flex justify-content-between align-items-center">
                              <div class="msg d-flex align-items-center w-100">
                                <div class="image-box active">
                                  <img src="../../assets/images/profile/small/pic6.jpg" alt="" />
                                </div>
                                <div class="ms-3 w-100 ">
                                  <a ><h5 class="mb-1">Maren Rosser</h5></a>
                                  <div class="d-flex justify-content-between">
                                    <p class="me-auto mb-0 text-black">Hei, dont forget to clear server cache!</p>
                                    <small class="me-4">25min ago</small>
                                  </div>
                                </div>

                              </div>
                              <div class="dropdown">
                                <div class="btn-link" data-bs-toggle="dropdown">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                  </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                </div>
                              </div>
                            </div>
                            <div class="msg-bx d-flex justify-content-between align-items-center">
                              <div class="msg d-flex align-items-center w-100">
                                <div class="image-box">
                                  <img src="../../assets/images/profile/small/pic7.jpg" alt="" />
                                </div>
                                <div class="ms-3 w-100">
                                  <a ><h5 class="mb-1">Kaiya Bergson</h5></a>
                                  <div class="d-flex justify-content-between">
                                    <p class="me-auto mb-0 text-black">I remember that project due is tomorrow.</p>
                                    <small class="me-4">Yesterday, 8:24 AM</small>
                                  </div>
                                </div>
                              </div>
                              <div class="dropdown">
                                <div class="btn-link" data-bs-toggle="dropdown">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                  </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                </div>
                              </div>
                            </div>
                            <div class="msg-bx d-flex justify-content-between align-items-center">
                              <div class="msg d-flex align-items-center w-100">
                                <div class="image-box active">
                                  <img src="../../assets/images/profile/small/pic4.jpg" alt="" />
                                </div>
                                <div class="ms-3 w-100">
                                  <a ><h5 class="mb-1">Ruben Press</h5></a>
                                  <div class="d-flex justify-content-between">
                                    <p class="me-auto mb-0 text-black">Ok sir. I will fix it as soon as possible</p>
                                    <small class="me-4">December 12th, 2020  10:24 AM</small>
                                  </div>
                                </div>
                              </div>
                              <div class="dropdown">
                                <div class="btn-link" data-bs-toggle="dropdown">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                  </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                </div>
                              </div>
                            </div>
                            <div class="msg-bx d-flex justify-content-between align-items-center">
                              <div class="msg d-flex align-items-center w-100">
                                <div class="image-box active">
                                  <img src="../../assets/images/profile/small/pic3.jpg" alt="" />
                                </div>
                                <div class="ms-3 w-100">
                                  <a ><h5 class="mb-1">Cristofer Torff</h5></a>
                                  <div class="d-flex justify-content-between">
                                    <p class="me-auto mb-0 text-black">Maybe we should schedule that meeting</p>
                                    <small class="me-4">December 12th, 2020  10:24 AM</small>
                                  </div>
                                </div>
                              </div>
                              <div class="dropdown">
                                <div class="btn-link" data-bs-toggle="dropdown">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                  </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                </div>
                              </div>
                            </div>
                            <div class="msg-bx d-flex justify-content-between align-items-center">
                              <div class="msg d-flex align-items-center w-100">
                                <div class="image-box active">
                                  <img src="../../assets/images/profile/small/pic5.jpg" alt="" />
                                </div>
                                <div class="ms-3 w-100">
                                  <a ><h5 class="mb-1">Ann Rosser</h5></a>
                                  <div class="d-flex justify-content-between">
                                    <p class="me-auto mb-0 text-black">I dont’t know where that files saved dude.</p>
                                    <small class="me-4">Yesterday, 8:24 AM</small>
                                  </div>
                                </div>
                              </div>
                              <div class="dropdown">
                                <div class="btn-link" data-bs-toggle="dropdown">
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
                                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
                                  </svg>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                  <a class="dropdown-item" href="javascript:void(0)">Edit</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Content body end */}

        {/* Modal */}
        {/* <div class="modal fade" id="sendMessageModal">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Send Message</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div class="modal-body">
                <form class="comment-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="text-black font-w600 form-label required">Name </label>
                        <input type="text" class="form-control" value="Author" name="Author" placeholder="Author" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="text-black font-w600 form-label">Email </label>
                        <input type="text" class="form-control" value="Email" placeholder="Email" name="Email" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label class="text-black font-w600 form-label">Comment</label>
                        <textarea rows="8" class="form-control" name="comment" placeholder="Comment"></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="mb-3 mb-0">
                        <input type="submit" value="Post Comment" class="submit btn btn-primary" name="submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        {/* Modal End */}

        {/* Footer start */}
        <Footer />
        {/* Footer end */}

      </div>
    </div>
  )
}

export default Dashboard