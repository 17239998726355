import React from 'react';

function DoneLoader() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh', // Set height to 30% of the viewport height
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '120px',
          height: '40px',
        }}
      >
        {/* First Circle */}
        <div
          style={{
            width: '30px',
            height: '30px',
            background: 'linear-gradient(135deg, #6a00ff, #00d4ff)',
            borderRadius: '50%',
            animation: 'bounce 1.5s infinite ease-in-out',
            animationDelay: '0s',
          }}
        ></div>

        {/* Second Circle */}
        <div
          style={{
            width: '30px',
            height: '30px',
            background: 'linear-gradient(135deg, #6a00ff, #00d4ff)',
            borderRadius: '50%',
            animation: 'bounce 1.5s infinite ease-in-out',
            animationDelay: '0.2s',
          }}
        ></div>

        {/* Third Circle */}
        <div
          style={{
            width: '30px',
            height: '30px',
            background: 'linear-gradient(135deg, #6a00ff, #00d4ff)',
            borderRadius: '50%',
            animation: 'bounce 1.5s infinite ease-in-out',
            animationDelay: '0.4s',
          }}
        ></div>
      </div>

      {/* Keyframes and media queries for responsiveness */}
      <style>
        {`
          @keyframes bounce {
            0%, 80%, 100% {
              transform: scale(0.8);
            }
            40% {
              transform: scale(1.2);
            }
          }

          /* Responsive adjustments */
          @media (max-width: 768px) {
            div[style*="width: 120px"] {
              width: 90px; /* Reduce loader width */
            }

            div[style*="width: 30px"] {
              width: 20px; /* Reduce circle size */
              height: 20px;
            }
          }

          @media (max-width: 480px) {
            div[style*="width: 120px"] {
              width: 60px; /* Further reduce loader width */
            }

            div[style*="width: 30px"] {
              width: 15px; /* Further reduce circle size */
              height: 15px;
            }
          }
        `}
      </style>
    </div>
  );
}

export default DoneLoader;
