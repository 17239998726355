import React from 'react'
import NavBar from '../Layouts/NavBar'
import Header from '../Layouts/Header'
import SideBar from '../Layouts/SideBar'

function StudentDetails() {
    return (
        <div id="main-wrapper">
            <NavBar />
            <Header />
            <SideBar />
            <div className="content-body default-height">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="m-0">Student Profile / Details</h4>
                        </div>
                        <div class="p-4">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4 p-4">
                                <div className="col-xl-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fas fa-search"></i></div>
                                        <input
                                            type="text"
                                            className="form-control wide"
                                            placeholder="Search"
                                            value={""}
                                            onChange={""}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3">
                                    <select
                                        className="form-control wide"
                                        value={""}
                                        onChange={""}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <option value="5">5 - items per page</option>
                                        <option value="10">10 - items per page</option>
                                        <option value="15">15 - items per page</option>
                                        <option value="20">20 - items per page</option>
                                    </select>
                                </div>
                            </div>

                            <div className="table-responsive" style={{ overflow: 'auto', minHeight: '50vh', maxHeight: '50vh' }}>
                                <table className="table table-responsive-md">
                                    <thead className="mx-auto text-center">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Student Name</th>
                                            <th>Roll Number</th>
                                            <th>Email ID</th>
                                            <th>Password</th>
                                            <th>Mobile Number</th>
                                            <th>Department</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="mx-auto text-center">
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-xs" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">view</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                    Showing <b className="text-primary"></b> of{" "}
                                    <b className="text-primary"></b> entries
                                </div>
                                <div className="text-dark">
                                    <span className="me-4">
                                        Page <b className="text-primary"></b> of{" "}
                                        <b className="text-primary"></b>
                                    </span>
                                    <button
                                        className="btn btn-xs btn-primary"
                                        onClick=""
                                        disabled=""
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        className="btn btn-xs btn-primary ms-2"
                                        onClick=""
                                        disabled=""
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade bd-example-modal-lg" tabindex="-1" style={{display: 'none'}} aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Modal title</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal">
                                    </button>
                                </div>
                                <div class="modal-body">Modal body text goes here.</div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDetails