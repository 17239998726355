
const Signin = '/'; 
const Dashboard = '/Dashboard'; 
const InterviewBot = '/InterviewBot';
const AssessmentMcq = '/AssessmentMcq';
const KnowledgeBotConfig = '/KnowledgeBotConfig';
const interviewBotCongfig= '/InterviewBotConfig';
const BotSummary='/BotSummary'
const DocSummary='/DocSummary'
const KnowledgeScreen='/KnowledgeScreen'
const StudentProfile = '/StudentProfile'
const HubInfo='/HubInfo'
const HubProfile='/HubProfile'
const StudentCreation='/StudentCreation'
const CreateTeam='/CreateTeam'
const SignIn='/'
const InterviewSummary='/InterviewSummary'
const AssignTeam='/AssignTeam'
const BatchConfig ='/BatchConfig'
const DoneLoader ='/DoneLoader'
const StudentDetails = '/StudentDetails'
const Adminsummary = '/Adminsummary'
const StudentDigitalLib ='/studentDigitalLib'
const ProfileOfStudent ='/ProfileOfStudent'
const Error400 = '/Error400'
const Error500 = '/Error500'




export const BaseRoutes = {
    Signin, 
    Dashboard,
    InterviewBot,
    KnowledgeBotConfig,
    interviewBotCongfig,
    BotSummary,
    KnowledgeScreen,
    StudentProfile,
    HubInfo,
    HubProfile,
    StudentCreation,
    CreateTeam,
    SignIn,
    DocSummary,
    InterviewSummary,
    AssignTeam,
    BatchConfig,
    AssessmentMcq,
    DoneLoader,
    Adminsummary,
    StudentDetails,
    StudentDigitalLib,
    ProfileOfStudent,
    Error400,
    Error500
}