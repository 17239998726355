import React, { useState, useEffect } from 'react';
import NavBar from '../../Layouts/NavBar';
import Header from '../../Layouts/Header';
import SideBar from '../../Layouts/SideBar';
import Footer from '../../Layouts/Footer';
import Swal from 'sweetalert2';
import { apiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';
import * as XLSX from "xlsx";
import { BaseRoutes } from '../../CommonConfig/BaseRoutes';
import { useNavigate } from 'react-router-dom';

function StudentCreation() {
    const [students, setStudents] = useState([]);
    const [studentDetails, setStudentDetails] = useState({
        org_id: 0,
        studentid: "",
        rollno: "",
        name: "",
        emailid: "",
        password: "Syncviz@123",
        number: "",
        department: "",
    });
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");

    const [editingStudent, setEditingStudent] = useState(null);
    const [editedDetails, setEditedDetails] = useState({});
    const [isValid, setIsValid] = React.useState(true);
    const [isRollNoValid, setIsRollNoValid] = React.useState(true);
    const [isDepartmentValid, setIsDepartmentValid] = React.useState(true);
    const [isNameValid, setIsNameValid] = React.useState(true);
    const [isNumberValid, setIsNumberValid] = React.useState(true);
    const [isEmailValid, setIsEmailValid] = React.useState(true);
    const [isPasswordValid, setIsPasswordValid] = React.useState(true);
    const [status, setStatus] = useState("Active");
    const [statusAssign, setStatusAssign] = useState("Not Assigned");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();



    function reloadPage() {
        window.location.reload();
    }

    const handleSubmit = async (e) => {
        if (uploadedFiles.length === 0) {
            showAlert('Please select at least one file to upload', 'warning');
            return;
        }

        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            formData.append('file', file);
        });

        try {
            const response = await axios.post(`${apiURL}/upload_student_file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                if (response.data.file_url !== "") {
                    Swal.fire({
                        title: 'Success!',
                        text: 'File(s) uploaded successfully!',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#2EA87E',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            fetchStudents();
                            const modal = document.getElementById('basicModal');
                            modal.style.display = 'none';
                            modal.setAttribute('aria-hidden', 'true');
                            modal.classList.remove('show');
                            reloadPage();
                        }
                    });
                } else {
                    showAlert('File upload failed', 'error');
                }
            } else {
                showAlert('File upload failed', 'error');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                title: 'Error uploading file',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2EA87E',
            });
        }
    };


    const handleInputStudentDetails = (e) => {
        const { name, value } = e.target;
        setStudentDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSingleOnboard = async () => {
        // Run validation functions and collect results
        const isRollNoValid = validateRollNo();
        const isStudentIDValid = validateStudentID();
        const isNameValid = validateName();
        const isDepartmentValid = validateDepartment();
        const isNumberValid = validateNumber();
        const isPasswordValid = validatePassword();
        const isEmailValid = validateEmail();

        // Check if all validations pass
        if (
            !isRollNoValid ||
            !isStudentIDValid ||
            !isNameValid ||
            !isDepartmentValid ||
            !isNumberValid ||
            !isPasswordValid ||
            !isEmailValid
        ) {
            showAlert("Please fill in all required fields correctly.", "error");
            return; // Stop execution if any validation fails
        }

        try {
            const response = await axios.post(`${apiURL}/create_student`, studentDetails);

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    title: "Success!",
                    text: "Student onboarded successfully!",
                    icon: "success",
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.isConfirmed) {
                        fetchStudents();
                        setStudentDetails({
                            studentid: "",
                            rollno: "",
                            name: "",
                            emailid: "",
                            password: "",
                            number: "",
                            department: "",
                        });
                        const modal = document.getElementById('basicModal');
                        modal.style.display = 'none';
                        modal.setAttribute('aria-hidden', 'true');
                        modal.classList.remove('show');
                        reloadPage(); // Call reloadPage() only after "Ok" is clicked
                    }
                });
            } else {
                showAlert("Failed to onboard student", "error");
            }
        } catch (error) {
            console.error("Error onboarding student:", error);
            showAlert("An error occurred during student onboarding", "error");
        }
    };


    const showAlert = (message, type) => {
        Swal.fire({
            title: message,
            icon: type,
            confirmButtonText: 'OK',
            confirmButtonColor: '#2EA87E',
        });
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles(files);
    };

    const handleDownloadTemplate = () => {
        window.open(`${apiURL}/download-template`, "_blank");
    };

    const fetchStudents = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_all_Students`);

            if (response.status === 200 && response.data && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => b.id - a.id);
                setStudents(sortedData);
                // setCount(sortedData.length);
            } else {
                // toastr.error('Failed to load students');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            window.location.href = BaseRoutes.Error500;
            // toastr.error('An error occurred while fetching students');
        } finally {
        }
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const handleEdit = (student) => {
        setEditingStudent(student.id);
        setEditedDetails({ ...student });
    };

    const handleCancelEdit = () => {
        setEditingStudent(null);
        setEditedDetails({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };

    const handleSaveEdit = async (id) => {

        try {
            const updatedDetails = {
                ...editedDetails,
                org_id: editedDetails.org_id === null ? 0 : editedDetails.org_id
            };

            const response = await axios.put(`${apiURL}/update_Students/${id}`, updatedDetails);

            if (response.status === 200) {
                showAlert('Student details updated successfully!', 'success');
                fetchStudents();
                setEditingStudent(null);
                setEditedDetails({});
            } else {
                showAlert('Failed to update student details', 'error');
            }
        } catch (error) {
            console.error('Error updating student:', error);
            showAlert('Error updating student details', 'error');
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action will permanently delete the student record.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2EA87E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${apiURL}/delete_Students/${id}`);
                    if (response.status === 200) {
                        showAlert('Student deleted successfully!', 'success');
                        fetchStudents();
                    } else {
                        showAlert('Failed to delete student', 'error');
                    }
                } catch (error) {
                    console.error('Error deleting student:', error);
                    showAlert('Error occurred while deleting the student', 'error');
                }
            }
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const filteredStudents = students.filter(
        (student) =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.studentid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            student.emailid.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalItems = filteredStudents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHour = hours % 12 || 12;

        return `${day} / ${month} / ${year} - ${formattedHour}:${minutes} ${ampm}`;
    };

    const validateStudentID = () => {
        const isValid = studentDetails.studentid && studentDetails.studentid.trim() !== '';
        setIsValid(isValid);
        return isValid;
    };

    const validateRollNo = () => {
        const isRollNoValid = studentDetails.rollno && studentDetails.rollno.trim() !== '';
        setIsRollNoValid(isRollNoValid);
        return isRollNoValid;
    };

    const validateDepartment = () => {
        const isDepartmentValid = studentDetails.department && studentDetails.department.trim() !== '';
        setIsDepartmentValid(isDepartmentValid);
        return isDepartmentValid;
    };

    const validateName = () => {
        const isNameValid = studentDetails.name && studentDetails.name.trim() !== '';
        setIsNameValid(isNameValid);
        return isNameValid;
    };

    const validateNumber = () => {
        const mobileRegex = /^[0-9]{10}$/; // Validates 10 digits
        const isNumberValid = mobileRegex.test(studentDetails.number);
        setIsNumberValid(isNumberValid);
        return isNumberValid;
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        const isEmailValid = emailRegex.test(studentDetails.emailid);
        setIsEmailValid(isEmailValid);
        return isEmailValid;
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isPasswordValid = passwordRegex.test(studentDetails.password);
        setIsPasswordValid(isPasswordValid);
        return isPasswordValid;
    };


    const toggleStatus = async (studentId) => {
        try {
            const studentIndex = students.findIndex((student) => student.id === studentId);
            const studentToUpdate = students[studentIndex];

            if (!studentToUpdate) {
                console.error('Student not found');
                return;
            }

            // Toggle status in the frontend first
            const updatedStudents = [...students];
            updatedStudents[studentIndex].isactive = !studentToUpdate.isactive;
            setStudents(updatedStudents);  // Update the state with the new list

            // Send the status change to the backend
            if (studentToUpdate.isactive) {
                const response = await axios.put(`${apiURL}/inactive_Students/${studentId}`);
                console.log('Student deactivated:', response.data);
            } else {
                const response = await axios.put(`${apiURL}/inactive_Students/${studentId}`);
                console.log('Student activated:', response.data);
            }
        } catch (error) {
            console.error("Error updating student status:", error.response ? error.response.data : error.message);
        }
    };






    // const toggleStatusAssign = () => {
    //     setStatusAssign((prevStatus) =>
    //         prevStatus === "Not Assigned" ? "Assigned" : "Not Assigned"
    //     );
    // };

    const handleDownloadExcel = () => {
        // Use the full students data instead of paginated data
        const tableData = students.map((student, index) => ({
            "S.No": index + 1,
            "Student Name": student.name,
            "Email ID": student.emailid,
            Password: student.password,
            "Mobile Number": student.number,
            "Department/Batch": student.department,
            Status: student.isactive ? "Active" : "Deactive",
        }));

        // Create a new workbook and worksheet
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Students");

        // Export the Excel file
        XLSX.writeFile(workbook, "Student_Data.xlsx");
    };

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        emailid: true,
        password: true,
        number: true,
        department: true,
        status: true,
    });


    const toggleColumnVisibility = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column],
        }));
    };


    return (
        <div id="main-wrapper">
            <NavBar />
            <Header />
            <SideBar />
            <div className="content-body default-height">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Student Onboard</h4>
                        </div>

                        <div class="card-body">
                            <div class="d-flex flex-wrap align-items-center justify-content-between">
                                <div class="align-items-center d-flex flex-wrap">
                                    <div>
                                        <button type="button" class="btn btn-primary mb-2" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg">OnBoard Student</button>
                                    </div>

                                </div>
                            </div>
                            <div class="modal fade bd-example-modal-lg" data-bs-backdrop="static" id="basicModal" style={{ display: 'none' }} aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">OnBoard Student</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal">
                                            </button>
                                        </div>
                                        <div class="">
                                            <div class="card-body">
                                                {/* <div class="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h4>Bulk OnBoard</h4>
                                                </div> */}

                                                {/* <div class="row">
                                                    <div class="col-md-12">
                                                        <label class="form-label">Upload Document</label>
                                                        <input type="file" class="form-control" onChange={handleFileUpload} />
                                                    </div>
                                                </div> */}
                                                {/* <hr /> */}
                                                <div class="basic-form">
                                                    {/* <div class="d-flex flex-wrap justify-content-start align-items-center mt-1 mb-1">
                                                        <h4>Single OnBoard</h4>
                                                    </div>  */}
                                                    <div class="card-tabs">
                                                        <ul class="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                                            <li class=" nav-item" role="presentation">
                                                                <a href="#navpills2-11" class="nav-link active" data-bs-toggle="tab" aria-expanded="false" aria-selected="true" role="tab" tabindex="-1">Single Onboard</a>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <a href="#navpills2-21" class="nav-link" data-bs-toggle="tab" aria-expanded="false" aria-selected="false" role="tab" tabindex="-1">Bulk Onboard</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="tab-content">
                                                        <div id="navpills2-11" class="tab-pane active show" role="tabpanel">
                                                            <div class="row">
                                                                <div class="row mt-3">
                                                                    <div className="mb-3 col-xl-4">
                                                                        <label className="form-label">
                                                                            Student ID <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isValid && 'is-invalid'}`}
                                                                            placeholder="Enter Student ID"
                                                                            name="studentid"
                                                                            value={studentDetails.studentid}
                                                                            onChange={handleInputStudentDetails}
                                                                            onBlur={validateStudentID}
                                                                        />
                                                                        {!isValid && (
                                                                            <div className="invalid-feedback">Please enter a valid Student ID.</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3 col-xl-4">
                                                                        <label className="form-label">
                                                                            Roll No <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isRollNoValid && 'is-invalid'}`}
                                                                            placeholder="Enter Roll No."
                                                                            name="rollno"
                                                                            value={studentDetails.rollno}
                                                                            onChange={handleInputStudentDetails}
                                                                            onBlur={validateRollNo}
                                                                        />
                                                                        {!isRollNoValid && (
                                                                            <div className="invalid-feedback">Please enter a valid Roll No.</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3 col-xl-4">
                                                                        <label className="form-label">
                                                                            Department <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isDepartmentValid && 'is-invalid'}`}
                                                                            placeholder="Enter Department"
                                                                            name="department"
                                                                            value={studentDetails.department}
                                                                            onChange={handleInputStudentDetails}
                                                                            onBlur={validateDepartment}
                                                                        />
                                                                        {!isDepartmentValid && (
                                                                            <div className="invalid-feedback">Please enter a valid Department.</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3 col-xl-6">
                                                                        <label className="form-label">
                                                                            Name <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isNameValid && 'is-invalid'}`}
                                                                            placeholder="Enter Student Name"
                                                                            name="name"
                                                                            value={studentDetails.name}
                                                                            onChange={handleInputStudentDetails}
                                                                            onBlur={validateName}
                                                                        />
                                                                        {!isNameValid && (
                                                                            <div className="invalid-feedback">Please enter a valid Name.</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3 col-xl-6">
                                                                        <label className="form-label">
                                                                            Mobile No <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isNumberValid && 'is-invalid'}`}
                                                                            placeholder="Enter Mobile No"
                                                                            name="number"
                                                                            value={studentDetails.number}
                                                                            onChange={(e) => {
                                                                                const regex = /^[0-9\b]+$/; 
                                                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                                                    handleInputStudentDetails(e); 
                                                                                }
                                                                            }}
                                                                            onBlur={validateNumber}
                                                                            maxLength={10} 
                                                                        />
                                                                        {!isNumberValid && (
                                                                            <div className="invalid-feedback">
                                                                                Please enter a valid 10-digit mobile number.
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className="mb-3 col-xl-6">
                                                                        <label className="form-label">
                                                                            Email ID <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${!isEmailValid && 'is-invalid'}`}
                                                                            placeholder="Enter Email ID"
                                                                            name="emailid"
                                                                            value={studentDetails.emailid}
                                                                            onChange={handleInputStudentDetails}
                                                                            onBlur={validateEmail}
                                                                        />
                                                                        {!isEmailValid && (
                                                                            <div className="invalid-feedback">
                                                                                Please enter a valid email address.
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-3 col-xl-6">
                                                                        <label className="form-label">
                                                                            Password <span style={{ color: 'red' }}>*</span>
                                                                        </label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                type={showPassword ? "text" : "password"}
                                                                                className={`form-control ${!isPasswordValid && 'is-invalid'}`}
                                                                                placeholder="Enter Password"
                                                                                name="password"
                                                                                value={studentDetails.password}
                                                                                onChange={handleInputStudentDetails}
                                                                                onBlur={validatePassword}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-secondary"
                                                                                onClick={() => setShowPassword(!showPassword)}
                                                                                tabIndex={-1} // prevents button from being focused when tabbing through form
                                                                            >
                                                                                {showPassword ? (
                                                                                    <i className="bi bi-eye-slash"></i> // Replace with your preferred icon
                                                                                ) : (
                                                                                    <i className="bi bi-eye"></i> // Replace with your preferred icon
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                        {!isPasswordValid && (
                                                                            <div className="invalid-feedback">
                                                                                Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                                <div className='row col-xl-3 mx-1 mt-3'>
                                                                    <button type="submit" class="btn btn-primary" onClick={handleSingleOnboard}>Create</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="navpills2-21" class="tab-pane" role="tabpanel">
                                                            <div class="row">
                                                                {/* <div class="d-flex flex-wrap justify-content-start align-items-center">
                                                                    <h4>Bulk OnBoard</h4>
                                                                </div> */}
                                                                <div class="row" style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <div className='col-12'>
                                                                        <button type="submit" style={{ float: 'right' }} class="btn btn-primary" onClick={handleDownloadTemplate}>Download Template</button>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="mb-3 col-xl-4">
                                                                    <label className="form-label">
                                                                        Team <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className='form-control'
                                                                        placeholder="Enter Department"
                                                                        name="department"

                                                                    />
                                                                </div> */}
                                                                <div class="col-md-12">
                                                                    <label class="form-label">Upload Document</label>
                                                                    <input type="file" class="form-control" onChange={handleFileUpload} />
                                                                </div>
                                                                <div className="row col-xl-3 mx-1 d-flex flex-wrap justify-content-end">
                                                                    <button type="submit" className="btn btn-primary mt-3" onClick={handleSubmit}>
                                                                        Create
                                                                    </button>
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                <div className="col-xl-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fas fa-search"></i></div>
                                        <input
                                            type="text"
                                            className="form-control wide"
                                            placeholder="Search by Name / Student ID / Mobile / E-Mail ID"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3">
                                    <select
                                        className="form-control wide"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <option value="5">5 - items per page</option>
                                        <option value="10">10 - items per page</option>
                                        <option value="15">15 - items per page</option>
                                        <option value="20">20 - items per page</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <button
                                    className="btn btn-primary btn-sm me-2"
                                    onClick={handleDownloadExcel}
                                    title="Download Excel"
                                >
                                    <i className="fas fa-file-excel"></i> Download Excel
                                </button>

                                <div className="dropdown">
                                    <button
                                        className="btn btn-primary btn-sm dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Select Columns
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {Object.keys(visibleColumns).map((col) => (
                                            <li key={col}>
                                                <label className="dropdown-item">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input me-2"
                                                        checked={visibleColumns[col]}
                                                        onChange={() => toggleColumnVisibility(col)}
                                                    />
                                                    {col}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            {visibleColumns.name && <th>Student Name</th>}
                                            {visibleColumns.emailid && <th>Email ID</th>}
                                            {visibleColumns.password && <th>Password</th>}
                                            {visibleColumns.number && <th>Mobile Number</th>}
                                            {visibleColumns.department && <th>Department/Branch</th>}
                                            {visibleColumns.status && <th>Status</th>}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedStudents.length > 0 ? (
                                            paginatedStudents.map((student, index) => (
                                                <tr key={student.id}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    {visibleColumns.name && (
                                                        <td>
                                                            {editingStudent === student.id ? (
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control-sm"
                                                                    value={editedDetails.name || ""}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                student.name
                                                            )}
                                                        </td>
                                                    )}
                                                    {visibleColumns.emailid && (
                                                        <td>
                                                            {editingStudent === student.id ? (
                                                                <input
                                                                    type="text"
                                                                    name="emailid"
                                                                    className="form-control-sm"
                                                                    value={editedDetails.emailid || ""}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                student.emailid
                                                            )}
                                                        </td>
                                                    )}
                                                    {visibleColumns.password && (
                                                        <td>
                                                            {editingStudent === student.id ? (
                                                                <input
                                                                    type="text"
                                                                    name="password"
                                                                    className="form-control-sm"
                                                                    value={editedDetails.password || ""}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                student.password
                                                            )}
                                                        </td>
                                                    )}
                                                    {visibleColumns.number && (
                                                        <td>
                                                            {editingStudent === student.id ? (
                                                                <input
                                                                    type="text"
                                                                    name="number"
                                                                    className="form-control-sm"
                                                                    value={editedDetails.number || ""}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                student.number
                                                            )}
                                                        </td>
                                                    )}
                                                    {visibleColumns.department && (
                                                        <td>
                                                            {editingStudent === student.id ? (
                                                                <input
                                                                    type="text"
                                                                    name="department"
                                                                    className="form-control-sm"
                                                                    value={editedDetails.department || ""}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                student.department
                                                            )}
                                                        </td>
                                                    )}
                                                    {visibleColumns.status && (
                                                        <td>
                                                            <button
                                                                className={`btn ${student.isactive ? "btn-success" : "btn-danger"
                                                                    } btn-xs`}
                                                                onClick={() => toggleStatus(student.id)}
                                                            >
                                                                {student.isactive ? "Active" : "Deactive"}
                                                            </button>
                                                        </td>
                                                    )}
                                                    <td>
                                                            {editingStudent === student.id ? (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        title="Save"
                                                                        aria-label="Save"
                                                                        className="btn btn-success btn-xs"
                                                                        onClick={() => handleSaveEdit(student.id)}
                                                                    >
                                                                        <i className="fas fa-check"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        title="Cancel"
                                                                        aria-label="Cancel"
                                                                        className="btn btn-warning btn-xs"
                                                                        onClick={handleCancelEdit}
                                                                    >
                                                                        <i className="fas fa-close"></i>
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        title="Edit"
                                                                        aria-label="Edit"
                                                                        className="btn btn-warning light btn-xs"
                                                                        onClick={() => handleEdit(student)}
                                                                    >
                                                                        <i className="fas fa-pen"></i>
                                                                    </button>
                                                                    {/* <button
                                                                        type="button"
                                                                        title="Delete"
                                                                        aria-label="Delete"
                                                                        className="btn btn-danger light btn-xs"
                                                                        onClick={() => handleDelete(student.id)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button> */}
                                                                </>
                                                            )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    No student data available.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                                <div className="text-dark">
                                    Showing <b className="text-primary">{paginatedStudents.length}</b> of{" "}
                                    <b className="text-primary">{students.length}</b> entries
                                </div>
                                <div className="text-dark">
                                    <span className="me-4">
                                        Page <b className="text-primary">{currentPage}</b> of{" "}
                                        <b className="text-primary">{totalPages}</b>
                                    </span>
                                    <button
                                        className="btn btn-xs btn-primary"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1 || totalPages === 0}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        className="btn btn-xs btn-primary ms-2"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages || totalPages === 0}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default StudentCreation;
