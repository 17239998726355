import React, { useEffect, useState } from 'react'
import SideBar from '../../Layouts/SideBar'
import NavBar from '../../Layouts/NavBar'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import { apiURL, botApiURL } from '../../CommonConfig/BaseUrl';
import axios from 'axios';
import booklogo from '../../imgs/FullLogo.png'
import Swal from 'sweetalert2';
import Select from 'react-select';

function Adminsummary() {

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const [books, setBooks] = useState([]);
    const [selectedBookId, setSelectedBookId] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        isbn: '',
        book_description: '',
        publisher_name: '',
        published_year: '',
        published_by: '',
        cover_file: null,
        pdf_file: null,
    });

    const handleAddBook = (e) => {
        const { name, value, files } = e.target;
        setFormData({ ...formData, [name]: files ? files[0] : value });
    };

    useEffect(() => {
        fetchBooks();
    }, []);

    const validateDetails = () => {
        const { title, isbn, book_description, publisher_name, published_year, published_by } = formData;

        if (!title || !isbn || !book_description || !publisher_name || !published_year || !published_by) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Fields',
                text: 'Please fill all the fields and upload the required files.',
            });
            return false;
        }
        return true;
    };

    const validateForm = () => {
        const { title, isbn, book_description, publisher_name, published_year, published_by, cover_file, pdf_file } = formData;

        if (!title || !isbn || !book_description || !publisher_name || !published_year || !published_by || !cover_file || !pdf_file) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Fields',
                text: 'Please fill all the fields and upload the required files.',
            });
            return false;
        }
        return true;
    };

    const handleUpload = async () => {
        if (!validateForm()) return;

        setLoading(true);
        const { title, isbn, book_description, publisher_name, published_year, published_by, cover_file, pdf_file } = formData;
        const status = 'unassigned';
        const url = `${apiURL}/create_digital-library/?title=${encodeURIComponent(title)}&isbn=${encodeURIComponent(isbn)}&book_description=${encodeURIComponent(book_description)}&publisher_name=${encodeURIComponent(publisher_name)}&published_year=${encodeURIComponent(published_year)}&published_by=${encodeURIComponent(published_by)}&status=${encodeURIComponent(status)}`;

        const data = new FormData();
        data.append('pdf_file', pdf_file);
        data.append('cover_file', cover_file);

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            Swal.fire({
                icon: 'success',
                title: 'File Uploaded',
                text: 'Your files have been successfully uploaded.',
            });

            // Reset form data
            setFormData({
                title: '',
                isbn: '',
                book_description: '',
                publisher_name: '',
                published_year: '',
                published_by: '',
                cover_file: null,
                pdf_file: null,
            });
            setActiveTab('details'); // Optional: Reset to the first tab
            fetchBooks();
            console.log(response.data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'There was an error uploading your files. Please try again.',
            });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBooks = async () => {
        try {
            const response = await axios.get(`${apiURL}/get_digital-library/`);
            setBooks(response.data);
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    };

    const deleteBook = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${apiURL}/delete_digital-library/${id}`, {
                    headers: { 'accept': 'application/json' }
                })
                    .then((response) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'File has been successfully deleted.',
                        });
                        fetchBooks();
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'There was a problem deleting the file.',
                        });
                        console.error('Error deleting the book:', error);
                    });
            }
        });
    };

    const openPdfPopup = (pdfPath, pageNumber) => {
        const pdfWindow = window.open('', '_blank', 'width=800,height=600');
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        iframe.src = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdfPath)}#page=${pageNumber}`;
        pdfWindow.document.body.appendChild(iframe);
    };

    const [options, setOptions] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);

    useEffect(() => {
        axios.get(`${apiURL}/get_all_team`, {
            headers: { 'accept': 'application/json' }
        })
            .then((response) => {
                if (response.data.message === 'Success') {
                    const transformedData = response.data.data.map(item => ({
                        label: item.Name, // Using Name as label
                        value: item.id,   // Using id as value
                    }));
                    setOptions(transformedData);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleChange = (selectedOptions) => {
        setSelectedBatches(selectedOptions);
    };

    const handleAssignButtonClick = (bookId) => {
        setSelectedBookId(bookId);
    };

    const assignBookToBatches = (bookId) => {
        fetch(`${apiURL}/update_digital-library/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: bookId,
                status: 'private',
                assigned_team_ids: selectedBatches.map(batch => batch.value)
            })
        })
            .then(response => response.json())
            .then(data => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Book successfully assigned to selected batches.',
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchBooks();
                setSelectedBatches([]);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to assign book to batches. Please try again.',
                });
                console.error('Error assigning book:', error);
            });
    };

    const addBookToPublic = (bookId) => {
        fetch(`${apiURL}/update_digital-library/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: bookId,
                status: 'public',
                assigned_team_ids: [0]
            })
        })
            .then(response => response.json())
            .then(data => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Book successfully added to public.',
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchBooks();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to add book to public. Please try again.',
                });
                console.error('Error adding book to public:', error);
            });
    };

    const addBookToUnassign = (bookId) => {
        fetch(`${apiURL}/update_digital-library/`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: bookId,
                status: 'unassigned',
                assigned_team_ids: [0]
            })
        })
            .then(response => response.json())
            .then(data => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Book successfully unassigned.',
                    timer: 2000,
                    showConfirmButton: false
                });
                fetchBooks();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to unassign book. Please try again.',
                });
                console.error('Error unassign book:', error);
            });
    };

    return (
        <div>
            <div id="main-wrapper">
                <NavBar />
                <Header />
                <SideBar />
                {/* Add Books Modal */}
                <div class="modal fade add-book-modal" data-bs-backdrop="static" id="upload-books" style={{ display: 'none' }} aria-hidden="true">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Upload Books <i class="fas fa-book ms-2"></i></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="card-tabs d-flex justify-content-center">
                                    <ul className="nav nav-tabs d-inline-flex mb-4" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === 'details' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('details')}
                                                role="tab"
                                            >
                                                Details
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === 'uploads' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('uploads')}
                                                role="tab"
                                            >
                                                File Upload
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content">
                                    {activeTab === 'details' && (
                                        <div id="details" className="tab-pane active show" role="tabpanel">
                                            <div className="row g-3">
                                                {['title', 'isbn', 'book_description', 'publisher_name', 'published_year', 'published_by'].map((field, idx) => (
                                                    <div key={idx} className="col-xl-12">
                                                        <label className="form-label">
                                                            {field.replace('_', ' ').toUpperCase()}<span className="text-danger ms-1">*</span>
                                                        </label>
                                                        {field === 'published_year' ? (
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder={`Enter ${field.replace('_', ' ')}`}
                                                                name={field}
                                                                value={formData[field]}
                                                                onChange={handleAddBook}
                                                                min="1000"
                                                                max={new Date().getFullYear()}
                                                            />
                                                        ) : (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Enter ${field.replace('_', ' ')}`}
                                                                name={field}
                                                                value={formData[field]}
                                                                onChange={handleAddBook}
                                                            />
                                                        )}
                                                    </div>
                                                ))}

                                                <div className="col-xl-12 text-center mt-4">
                                                    <button type="button" className="btn btn-primary btn-sm"
                                                        onClick={() => { if (validateDetails()) { setActiveTab('uploads'); } }}>
                                                        NEXT
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 'uploads' && (
                                        <div id="uploads" className="tab-pane active show" role="tabpanel">
                                            <div className="row g-3">
                                                {['cover_file', 'pdf_file'].map((field, idx) => (
                                                    <div key={idx} className="col-xl-12">
                                                        <label className="form-label">
                                                            {`Upload ${field === 'cover_file' ? 'Cover Photo' : 'Book'} `}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name={field}
                                                            onChange={handleAddBook}
                                                        />
                                                    </div>
                                                ))}
                                                <div className="col-xl-12 text-center mt-4">
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleUpload}>
                                                        {loading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        ) : (
                                                            "UPLOAD"
                                                        )}
                                                    </button>
                                                </div>
                                                <span className='d-flex align-items-center'>
                                                    <hr className='w-50' />OR<hr className='w-50' />
                                                </span>
                                                <div class="col-xl-12">
                                                    <label class="form-label">URL of Book<span className='text-danger ms-1'>*</span></label>
                                                    <input type="text" placeholder="Enter URL" class="form-control" />
                                                </div>
                                                <div className="col-xl-12 text-center mt-4">
                                                    <button type="submit" class="btn btn-primary btn-sm">UPLOAD</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Assign Modal */}
                <div class="modal fade assign-book-modal mt-5" data-bs-backdrop="static" id="assign-book-modal" style={{ display: 'none' }} aria-hidden="true">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Assign Book<i class="fas fa-book ms-2"></i></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal">
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className=" mt-2 ">
                                    <label className="text-primary fw-bold fs-6 ms-2 mb-0">Select Batch to Assign</label>
                                    <Select
                                        className="my-2"
                                        options={options}
                                        isMulti
                                        value={selectedBatches}
                                        onChange={handleChange}
                                        placeholder="Please select batches"
                                    />
                                    <div className="col-xl-12 text-center my-4">
                                        <button type="button" className="btn btn-primary btn-sm"
                                            onClick={() => assignBookToBatches(selectedBookId)}>
                                            Assign to Batch
                                        </button>
                                    </div>

                                </div>
                                <span className='d-flex align-items-center'>
                                    <hr className='w-50' />OR<hr className='w-50' />
                                </span>
                                <div className=" mt-4">
                                    <label className="text-primary fw-bold fs-6 ms-2 mb-0">Add to Public <span className='text-dark'>(Can be viewed by all Students)</span></label>
                                    <div className="col-xl-12 text-center my-4">
                                        <div className="col-xl-12 text-center my-4">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                onClick={() => addBookToPublic(selectedBookId)}>
                                                Add to Public
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <span className='d-flex align-items-center'>
                                    <hr className='w-50' />OR<hr className='w-50' />
                                </span>
                                <div className=" mt-4">
                                    <label className="text-primary fw-bold fs-6 ms-2 mb-0">Unassign Book <span className='text-dark'>(To Hide from all Students)</span></label>
                                    <div className="col-xl-12 text-center my-4">
                                        <div className="col-xl-12 text-center my-4">
                                            <button type="button" className="btn btn-primary btn-sm"
                                                onClick={() => addBookToUnassign(selectedBookId)}>
                                                Unassign Book
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body default-height">
                    <div class="container-fluid">
                        <div className="row d-flex justify-content-center">
                            <div class="col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Digital Library</h4>
                                    </div>
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by file name"
                                                />
                                            </div>
                                            <div className="col-4 mb-3">
                                            </div>
                                            <div className="col-4 mb-3 admin_sum_button">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    data-bs-toggle="modal" data-bs-target=".add-book-modal"
                                                >
                                                    Add Books <i className='fas fa-book ms-2'></i>
                                                </button>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <h4 className="card-title mb-3">Existing Files</h4>
                                            </div>
                                            <hr />
                                            <div class="">
                                                <div className="row g-5 mt-5">
                                                    {books.map(book => (
                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={book.id}>
                                                            <div className="book-card">
                                                                <img src={book.cover_url || booklogo} alt={book.title} />
                                                                <p className={`d-flex justify-content-end fw-bold
                                                                ${book.status === 'private' ? 'text-danger'
                                                                        : book.status === 'public' ? 'text-success'
                                                                            : book.status === 'unassigned' ? 'text-warning' : ''}`}>
                                                                    {book.status.toUpperCase()}
                                                                </p>
                                                                <div className="content">
                                                                    <h6 className='text-dark' title={book.book_description || 'No description available'}>
                                                                        {book.book_description ? book.book_description.length > 50 ? `${book.book_description.slice(0, 50)}...`
                                                                            : book.book_description : 'No description available'}</h6>
                                                                    <p className="author">By {book.published_by}</p>
                                                                    <p className='fw-bold'>ISBN : <span className="text-dark">{book.isbn}</span></p>
                                                                    <p className="details "> {book.published_year} | {book.publisher_name} </p>
                                                                    {/* {book.pdf_url.split('/').pop().replace('_', ' ').split('.')[0]} */}
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <button className="btn-danger btn-sm btn" title='delete' onClick={() => deleteBook(book.id)}>
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </button>
                                                                    <button className="btn-primary btn-sm btn" data-bs-toggle="modal" data-bs-target=".assign-book-modal"
                                                                        onClick={() => handleAssignButtonClick(book.id)}>
                                                                        Assign
                                                                    </button>
                                                                    <button onClick={() => openPdfPopup(book.pdf_url, 1)} className="btn-success btn-sm btn" title='view'>
                                                                        <i className="fa fa-eye"></i>
                                                                    </button>
                                                                    {/* <button className="btn-success btn-sm btn" onClick={() => window.open(book.pdf_url, '_blank')}><i className="fas fa-eye"></i></button> */}
                                                                    {/* <button className="btn-info btn-sm btn" onClick={() => window.open(book.cover_url)} download><i className="fas fa-download"></i> Cover</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Adminsummary