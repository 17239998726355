import React, { useEffect, useState } from 'react';
import network from '../../imgs/entrepreneurship.gif';
import answer from '../../imgs/network.gif';
import star from '../../imgs/AIstart.svg';
import chat from '../../imgs/chat.gif';
import interview from '../../imgs/online-interview.gif';
// import vlogo from '../../imgs/verandaLog.png';
import vlogo from '../../imgs/backgroundPngLogo.png';
import ContentLoader, { Code } from 'react-content-loader'
import Typewriter from 'typewriter-effect';
import ai from '../../imgs/Group 200.png';
import assessment from '../../imgs/ai.gif';
import img1 from '../../imgs/img1.jpg';
import img2 from '../../imgs/img2.jpg';
import img3 from '../../imgs/img3.png';
import img4 from '../../imgs/img4.png';
import img5 from '../../imgs/img5.jpg';
import axios from 'axios';
import { apiURL, wssURL } from '../../CommonConfig/BaseUrl';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import DoneLoader from '../BotConfigure/DoneLoader';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import booklogo from '../../imgs/7631906.jpg';
import { useLocation } from 'react-router-dom';

function StudentDigitalLib() {

  const MyLoader = () => <ContentLoader />;
  const MyCodeLoader = () => <DoneLoader />;

  const [assigneddata, setAssigneddata] = useState([]);
  const [assignedHubdata, setAssignedHubdata] = useState([]);
  const navigate = useNavigate();
  const [selectedHubId, setSelectedHubId] = useState(0);
  const [chatHistory, setChatHistory] = useState([]);
  const [todayLimit, setTodayLimit] = useState(5);
  const [yesterdayLimit, setYesterdayLimit] = useState(5);
  const [thisMonthLimit, setThisMonthLimit] = useState(5);
  const [thisYearLimit, setThisYearLimit] = useState(5);
  const [olderLimit, setOlderLimit] = useState(5);
  const [books, setBooks] = useState([]);
  const [studentId, setStudentId] = useState();
  const [myCollection, setMyCollection] = useState([]);
  const [activeTab, setActiveTab] = useState('public');

  useEffect(() => {
    const id = sessionStorage.getItem("id");
    setStudentId(id);
  }, []);

  const [activeMenu, setActiveMenu] = useState(null);

  const handleToggle = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const [isSidebarExpanded, setSidebarExpanded] = useState(false);

  const toggleSidebar = () => {
    setSidebarExpanded((prevState) => !prevState);
  };

  const handleLogoutClick = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/');
      }
    });
  };

  useEffect(() => {
    const id = sessionStorage.getItem("id");
    if (id) {
      setStudentId(id);
      fetchChatHistory(id);
    } else {
      console.error("No student ID found in session storage.");
    }
  }, []);

  const fetchChatHistory = async (student_id) => {
    try {
      const response = await axios.get(`${apiURL}/get_all_chat-history/?studentid=${student_id}`);
      if (response.status === 200) {
        const chatData = response.data;

        // Filter chat history into categories
        const filteredChatHistory = categorizeChatHistory(chatData);
        setChatHistory(filteredChatHistory);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const categorizeChatHistory = (chats) => {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const categorized = {
      today: [],
      tomorrow: [],
      thisMonth: [],
      thisYear: [],
      older: [],
    };

    chats.forEach(chat => {
      const chatDate = new Date(chat.created_date);

      if (chatDate >= startOfDay) {
        categorized.today.push(chat);
      } else if (chatDate >= new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000)) {
        categorized.tomorrow.push(chat); // If tomorrow data is required
      } else if (chatDate >= startOfMonth) {
        categorized.thisMonth.push(chat);
      } else if (chatDate >= startOfYear) {
        categorized.thisYear.push(chat);
      } else {
        categorized.older.push(chat);
      }
    });

    return categorized;
  };

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      getData();
      getHubData(sessionStorage.getItem('id'));
      setDataFetched(true);
      // openPdfPopup("paramOne", "paramTwo")
    }
  }, [dataFetched]);

  const getData = async () => {
    axios.get(`${apiURL}/get_assessment_team_data/${sessionStorage.getItem('id')}`)
      .then((response) => {
        if (response.data) {
          setAssigneddata(response.data);

          const firstItem = response.data[0];
          const assHubId = firstItem.id;
          const assHubName = firstItem.bot_name;
          sessionStorage.setItem("Ass_HubId", assHubId);
          sessionStorage.setItem("Ass_HubName", assHubName);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }

  const getHubData = async (student_id) => {
    try {
      const response = await axios.get(`${apiURL}/get_knowledge_team_data/${student_id}`);
      if (response.data) {
        const hubForUser = response.data.knowledge_data;
        setAssignedHubdata(hubForUser);
        if (hubForUser.length > 0) {
          setSelectedHubId(hubForUser[0].id);
        }
        console.log(hubForUser[0].id, 'know hub ID');

        // const firstItem = response.knowledge_data.data[0];
        // const knowHubId = firstItem.id;
        // const knowHubName = firstItem.bot_name;
        // sessionStorage.setItem("Know_HubId", knowHubId);
        // sessionStorage.setItem("Know_HubName", knowHubName);
        console.log("Assigned Hub Data:", hubForUser);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {

      loadMoreMessages();
    }
  };

  const loadMoreMessages = () => {
    setTodayLimit(prev => prev + 5);
    setYesterdayLimit(prev => prev + 5);
    setThisMonthLimit(prev => prev + 5);
    setThisYearLimit(prev => prev + 5);
    setOlderLimit(prev => prev + 5);
  };

  useEffect(() => {
    axios
      .get(`${apiURL}/get_books_by_student/?student_id=${studentId}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data) {
          setBooks(activeTab === 'public' ? response.data.public : response.data.private);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [apiURL, studentId, activeTab]);

  const addToCollection = (studentId, bookId) => {
    axios
      .post(`${apiURL}/student-library`, {
        student_id: studentId,
        book_id: bookId,
      })
      .then((response) => {
        console.log('Book added to collection:', response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Book added to your collection!',
        });
      })
      .catch((error) => {
        console.error('Error adding book to collection:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add book to your collection.',
        });
      });
  };

  useEffect(() => {
    if (activeTab === 'collection') {
      axios
        .get(`${apiURL}/student-library/${studentId}`, {
          headers: {
            accept: 'application/json',
          },
        })
        .then((response) => {
          setMyCollection(response.data);
          console.log('Collection:', response.data);
        })
        .catch((error) => {
          console.error('Error fetching collection:', error);
        });
    }
  }, [activeTab]);

  const removeFromCollection = (bookId) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Do you want to remove this book from your collection?',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiURL}/student-library?id=${bookId}`, {
            headers: {
              accept: 'application/json',
            },
          })
          .then((response) => {
            console.log('Book removed from collection:', response.data);
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Book removed from your collection!',
            });
            setMyCollection((prevCollection) => prevCollection.filter((item) => item.id !== bookId));
          })
          .catch((error) => {
            console.error('Error removing book from collection:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to remove book from your collection.',
            });
          });
      }
    });
  };

  const openPdfPopup = (pdfPath, pageNumber) => {
    const pdfWindow = window.open('', '_blank', 'width=800,height=600');

    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    // Use Google's PDF viewer
    iframe.src = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pdfPath)}#page=${pageNumber}`;

    // Append the iframe to the popup window
    pdfWindow.document.body.appendChild(iframe);
  };

  const goBack = () => {
    navigate(-1); // Navigates to the previous page
};

  return (
    <>
      <style>{`
    .mm-show {
      display: block;
    }
    ul[aria-expanded="false"] {
      display: none;
    }
    .metismenu ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
    }
    .metismenu ul.mm-show {
      max-height: 500px; /* Adjust based on the content */
    }
    body {
        background-color: #2b2d42;
        color: #edf2f4;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
        
    .dlabnav {
    background: rgba(43, 45, 66, 0.8); /* Semi-transparent background */
    border-right: 1px solid rgba(255, 255, 255, 0.2); /* Soft white border */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
}
    .header{
    border-bottom: 1px solid #2ea87e;
    }

      /* Input section styles */
      .input-section {
        background: rgba(74, 78, 105, 0.7);
        backdrop-filter: blur(10px);
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 15px;
        display: flex;
      }

      .input-field {
        width: 100%;
        border: 1px solid #2ea87e;
        border-radius: 25px;
        padding: 10px 20px;
        transition: border-color 0.3s;
        background: rgba(52, 58, 64, 0.7);
        color: #edf2f4;
      }

      .input-field:focus {
        border-color: #007bff;
        outline: none;
      }

      /* Button styles */
      .btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s, transform 0.3s;
      }

      .btn:hover {
        background-color: rgba(0, 123, 255, 0.7);
        transform: scale(1.1);
      }

      .animate-icon {
        transition: transform 0.2s;
      }

      .animate-icon:hover {
        transform: rotate(20deg);
      }

      /* Add a glowing effect for AI theme */
      .btn-primary {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      .btn-secondary {
        box-shadow: 0 0 5px rgba(108, 117, 125, 0.5);
      }

      .ai-overview-section {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }

      .ai-overview-icon {
        width: 30px;
        height: 30px;
      }

      /* Heartbeat animation */
      .heartbeat-animation {
        animation: heartbeat 1.5s ease-in-out infinite;
      }

      /* Keyframes for heartbeat effect */
      @keyframes heartbeat {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      body,
      html {
        scroll-behavior: smooth;
      }

      .info-box {
        background-color: unset;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        margin-left:10px;
      }

      .title {
        font-size: 1.3rem;
        margin-bottom: 20px;
        color: #ffffff;
      }

      .sources {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      .source-item {
        background-color: #333333;
        padding: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        color: #b3b3b3;
        border: 1px solid #2ea87e;
      }

      .source-item a {
        color: #7cc5ff;
        text-decoration: none;
        margin-bottom: 5px;
      }

      .source-name {
        font-size: 0.8rem;
        color: #999999;
      }

      .view-more {
        color: #7cc5ff;
        text-decoration: none;
        margin-top: 10px;
        display: block;
      }

      /* Icon and Text Container Styling */
      .icon-text {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        color: #b3b3b3;
        font-size: 1rem;
      }

      /* Icon Styling */
      .icon-text i {
        font-size: 1.5rem;
        color: #7cc5ff;
      }

      /* Description Styling */
      .description {
        background-color: #333333;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      .description-title {
        color: #ffffff;
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .collapse-btn {
        background: none;
        border: none;
        color: #7cc5ff;
        cursor: pointer;
      }

      /* New Thread Button */
      .new-thread {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333333;
        color: #ffffff;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 1rem;
      }

      .shortcut {
        background-color: #555555;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 0.8rem;
        color: #e0e0e0;
      }

      .active {
        background-color: #333333;
        color: #ffffff;
      }

      /* Auth Section */
      .auth-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
      }

      .signup-btn {
        background-color: #f5f5f5;
        color: #333333;
        border: none;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
      }

      .login-link {
        color: #7cc5ff;
        text-decoration: none;
        font-size: 0.9rem;
      }

      .dropdown-item {
        color: #b3b3b3;
        text-decoration: none;
        padding: 8px 0;
        transition: color 0.3s;
      }

      .dropdown-item:hover {
        color: #ffffff;
      }

      .description {
        position: relative;
        margin-bottom: 20px;
      }

      .description-title {
        margin: 0;
      }

      .icon-container {
        display: flex;
        gap: 10px;
      }

      .icon-container i {
        cursor: pointer;
        font-size: 18px;
        color: #555;
        transition: color 0.3s;
      }

      .icon-container i:hover {
        color: #007bff; /* Change color on hover */
      }

      .like-icon {
        color: #28a745; /* Green color for like */
      }

      .dislike-icon {
        color: #dc3545; /* Red color for dislike */
      }

      .copy-icon {
        color: #6c757d; /* Gray color for copy */
      }

        .accordion-item {
          margin-bottom: 0px;
          border: 0;
          background-color: transparent;
   
}
         .sources {
    /* Add some spacing if necessary */
    margin: 20px;
}

.source-item {
    margin-bottom: 15px;
    position: relative; /* Needed for tooltip positioning */
}

.tooltip-link {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: #007bff; /* Link color */
    font-weight: normal;
}
    .tooltip-linkcolor {
    position: relative; /* Position relative for the tooltip */
    text-decoration: none; /* Remove underline from link */
    color: red; /* Link color */
    font-weight: bold;
}



.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.tooltip-content {
    color: #ffcc00; /* Set the color you want for content */
    font-weight: normal; /* Optional: make it bold */
    font-size: 16px;
}

.tooltip {
    visibility: hidden;
    width: 250px; /* Adjust width as needed */
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 8px; /* Slightly rounded corners */
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 250%; /* Position above the link */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.tooltip-link:hover .tooltip {
    visibility: visible;
    opacity: 1; /* Show tooltip on hover */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.card-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-content ul li {
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.small-card {
  flex: 0 0 calc(33.333% - 16px);
  padding: 12px;
  text-align: center;
}



.image-card img,
.full-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
}

.watch-card {
  position: relative;
  text-align: center;
  padding: 12px;
}

.watch-img {
  width: 40%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.watch-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.watch-button:hover {
  background-color: #0056b3;
}

.image-row-row {
  display: flex;
  gap: 5px; /* Space between images */
  margin-bottom: -80px;
}

.image-container {
  text-align: center;
}

.small-card-img {
  width: 100%; /* Adjust the width to make the images smaller */
  height: 50%;
  border-radius: 5px;
}
  .overlay-text {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 10px;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;

}

.image-container {
  position: relative;
  display: inline-block;
}
  .big-image-container {
  text-align: center; /* centers the image horizontally */
  margin-bottom: 20px; /* adds some space below the large image */
}
  .big-card-img {
  width: 100%; /* makes the image responsive */
  max-width: 600px; /* ensures it doesn't get too large */
  height: 200px; /* maintains aspect ratio */
   border-radius: 5px;
}
   .accordion-collapse {
  max-height: 400px;  /* Set the maximum height for the content */
  overflow-y: auto;   /* Enable vertical scrolling if the content exceeds the max height */
}

.big-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.image-row-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.image-container {
  flex: 1;
  max-width: 48%;
  position: relative; /* Relative positioning for the icon overlay */
  text-align: center;
}

.small-card-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Adds rounded corners to the images */
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 5px;
}

.icon-overlay {
  position: absolute;
  top: 10px; /* Adjust for positioning */
  right: 10px; /* Adjust for positioning */
  font-size: 16px; /* Size of the icon */
  color: white; /* Color of the icon */
  background: rgba(0, 0, 0, 0.5); /* Background for visibility */
  border-radius: 50%; /* Circular background */
  padding: 5px; /* Padding for the icon background */
}


.ai-logo-img {
  width: 24px; /* Adjust the size of the AI logo */
  height: 24px; /* Adjust the size of the AI logo */
  cursor: pointer; /* Optional: pointer cursor for interactivity */
}

.help-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // background-color: #1c1c1c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 2rem;
  border-radius: 8px;
}

.help-title {
  font-size: 30px !important; 
  margin-bottom: 1rem;
  color:#ffff;
}

.button-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}


.help-button {
  background-color: #333333; /* Button color */
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.2rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.help-button i {
  font-size: 18px; /* Icon size */
}

.help-button:hover {
  background-color: #555555; /* Lighter color on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.help-button:active {
  transform: translateY(0);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}


.disclaimer {
  font-size: 14px;
  color: #aaaaaa; /* Lighter gray text for disclaimer */
}



/* Button Styling */
.assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 25px;
  padding: 10px 9px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect */
.assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Focus effect */
.assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(46, 168, 126, 0.7);
}

/* Dark Pattern Effect on Hover */
.assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}
/* Adjust for larger devices */
@media (min-width: 350px) {
  .content {
    max-width: 400px;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content {
    max-width: 960px;
  }
}

@media (min-width: 1500px) {
  .content {
    max-width: 1400px;
  }
}

@media (max-width: 576px) {
  .tooltip-content {
    font-size: 12px; /* Adjust text size for small devices */
  }
  .source-name {
    font-size: 14px; /* Adjust text size for page number */
  }
}

.icon-text:hover .genie-answer {
  color: #007bff; /* Change the text color on hover */
}

@media (max-width: 768px) {
  .assessment {
    display: none;
  }
}
.metismenu ul {
  overflow: auto;
  max-height: 30vh; /* Adjust as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent;
}

.metismenu ul::-webkit-scrollbar {
  width: 8px; /* Adjust the width */
}

.metismenu ul::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

.metismenu ul::-webkit-scrollbar-track {
  background: transparent;
}
  
/* Smaller Button Styling */
.small-assessment-button {
  background: #2EA87E; 
  border: none;
  border-radius: 20px; /* Slightly smaller border radius */
  padding: 6px 8px; /* Reduced padding */
  color: white;
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Hover effect for smaller button */
.small-assessment-button:hover {
  background: #1d8a61; /* Darker shade for hover effect */
  transform: translateY(-2px); /* Slightly less lift */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

/* Focus effect */
.small-assessment-button:focus {
  outline: none;
  box-shadow: 0px 0px 8px rgba(46, 168, 126, 0.5);
}

/* Dark Pattern Effect on Hover */
.small-assessment-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250%; /* Slightly smaller circle */
  height: 250%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.4s ease;
}

.small-assessment-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.toast-center {
  bottom: 10%;
  right: 0%;
  position: fixed;
  z-index: 9999;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  justify-content: center;
}

.cards-wrapper {
 background: rgba(43, 45, 66, 0.8); /* Semi-transparent background */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns in a row */
  gap: 1rem;
  justify-items: center;
}

.card{
 background: rgba(43, 45, 66, 0.8); /* Semi-transparent background */
 border: rgba(43, 45, 66, 0.8);
}

/* Individual card */
.info-card {
  background: rgba(0, 0, 0, 0.3); /* Transparent black background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  padding: 1rem;
  overflow: auto; /* Allow scrolling for overflow content */
  max-width: 250px; /* Set a max-width for smaller cards */
  /* Remove or increase max-height */
  max-height: none; /* Allow dynamic height */
  transition: transform 0.3s ease-in-out;
}



.info-card:hover {
  transform: translateY(-10px); /* Slight hover effect */
}

/* Card image container */
.card-image-wrapper {
  position: relative;
  height: 150px; /* Reduced height for the image container */
  overflow: hidden;
}

.card-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits the container */
  border-radius: 5px;
  border-bottom: 2px solid #f0f0f0;
}

/* Overlay text */
.image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Card content */
/* Card content */
.card-details {
  padding: 1rem;
  height: auto; /* Allow the card to adjust height dynamically based on content */
  overflow: visible; /* Ensure no content is clipped */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Space out the content within the card */
}

/* Button group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem; /* Add space above the buttons */
}

.card-details h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: white;
}

.card-details p {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-bottom: 1rem;
}

// .button-group {
//   display: flex;
//   justify-content: space-between;
// }

.action-button {
  background-color: #2EA87E;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #45a049;
}

.additional-info {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #dcdcdc; /* Light grey for text */
}

.additional-info p {
  margin: 0.2rem 0; /* Add some spacing between lines */
}

.additional-info strong {
  color: #ffffff; /* White for labels */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .cards-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row on smaller screens */
  }
}

@media (max-width: 480px) {
  .cards-wrapper {
    grid-template-columns: 1fr; /* 1 card per row on very small screens */
  }
}

.book-card {
 background: rgba(0, 0, 0, 0.3); /* Transparent black background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            
            border-radius: 8px;
            padding: 16px;
            position: relative;
            text-align: center;
            margin-bottom: 40px;
        }

    .book-card img {
    max-width: 100px; /* Increased from 120px to 160px */
    max-height: 140px; /* Increased from 120px to 160px */
    border-radius: 4px;
    position: absolute;
    top: -30px; /* Adjusted to align the larger image */
    left: 25%;
    transform: translateX(-50%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

        .book-card .content {
            margin-top: 100px;
        }

        .book-card h5 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        .book-card .author {
            color: #666;
            font-size: 14px;
            margin-bottom: 16px;
        }

        .book-card .rating {
            color: #ffc107;
            margin-bottom: 16px;
        }

        .book-card .details {
            font-size: 14px;
            color: #333;
        }

        
 
    `}</style>

      <div id="main-wrapper">

        {/* navbar */}
        <div class="nav-header bg-green">
          <a href class="brand-logo">
            <img src={vlogo} style={{ width: "75%" }} />
          </a>
          <div class="nav-control">
            <div className="hamburger" onClick={toggleSidebar}>
              {isSidebarExpanded ? (
                <i className="fas fa-times" title="Collapse Sidebar"></i>
              ) : (
                <i className="fas fa-bars" title="Expand Sidebar"></i>
              )}
            </div>
          </div>
        </div>

        {/* header */}
        <div class="header bg-green">
          <div class="header-content">
            <nav class="navbar navbar-expand">
              <div class="collapse navbar-collapse justify-content-between">
                <div class="header-left">
                  <div class="dashboard_bar">
                  </div>
                </div>
                <ul class="navbar-nav header-right">
                  <div class="dashboard_bar d-flex flex-wrap justify-content-between align-items-center">
                    <h4>{sessionStorage.getItem('username')}</h4>
                    <i className="fa fa-sign-out fs-3 text-white ms-3 mb-2" onClick={handleLogoutClick} style={{ cursor: 'pointer' }} title="Log Out"></i>
                  </div>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        {/* sidebar */}
        <div className="dlabnav">
          <div className="dlabnav-scroll">
            <ul className="metismenu" id="menu">
              {/* Knowledge Hub Menu */}
              <li>
                <a
                  className="has-arrow" style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('knowledgeHub')}
                  aria-expanded={activeMenu === 'knowledgeHub'}
                >
                  <img
                    src={chat}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Knowledge Hub</span>
                </a>
                <ul className={activeMenu === 'knowledgeHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'knowledgeHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                  {assignedHubdata.length > 0 ? (
                    assignedHubdata.map((hub) => (
                      <li key={hub.id}>
                        <a
                          href={`/KnowledgeScreen?id=${hub.id}`}
                          onClick={() => {
                            sessionStorage.setItem('hub_id', hub.id);
                            localStorage.setItem('hub_id', hub.id);
                            // setSelectedHubId(hub.id);
                          }}
                        >
                          {`${hub.bot_name}`}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>
                      <a className="">No assigned hubs found.</a>
                    </li>
                  )}
                </ul>
              </li>
              {/* Assessment Hub Menu */}
              <li>
                <a
                  className="has-arrow" style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('assessmentHub')}
                  aria-expanded={activeMenu === 'assessmentHub'}
                >
                  <img
                    src={chat}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Assessment Hub</span>
                </a>
                <ul className={activeMenu === 'assessmentHub' ? 'mm-show' : ''} aria-expanded={activeMenu === 'assessmentHub'} style={{ overflow: 'auto', maxHeight: '30vh' }}>
                  {assigneddata.length > 0 ? (
                    assigneddata.map((assigneddatas) => (
                      <li key={assigneddatas.id}>
                        <a href={`/InterviewBot?id=${assigneddatas.id}`}>
                          {`${assigneddatas.bot_name}`}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>
                      <a className="">No assigned hubs found.</a>
                    </li>
                  )}
                </ul>
              </li>

              {/* Chat History */}
              <li>
                <a
                  className="has-arrow"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleToggle('chatHistory')}
                  aria-expanded={activeMenu === 'chatHistory'}
                >
                  <img
                    src={interview}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Chat History</span>
                </a>
                <ul
                  className={activeMenu === 'chatHistory' ? 'mm-show' : ''}
                  aria-expanded={activeMenu === 'chatHistory'}
                  style={{ overflow: 'auto', maxHeight: '30vh' }}
                  onScroll={handleScroll}
                >
                  {chatHistory.today && chatHistory.today.length > 0 ? (
                    <>
                      <li className="messages-header mx-1">Today</li>
                      {chatHistory.today.slice(0, todayLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2 cursor-pointer">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.today.length > todayLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more...
                          </a>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="messages mt-2">
                      <a className="text-muted">No chat history for today.</a>
                    </li>
                  )}

                  {chatHistory.yesterday && chatHistory.yesterday.length > 0 && (
                    <>
                      <li className="messages-header mx-2">Yesterday</li>
                      {chatHistory.yesterday.slice(0, yesterdayLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.yesterday.length > yesterdayLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more...
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.thisMonth && chatHistory.thisMonth.length > 0 && (
                    <>
                      <li className="messages-header mx-2">This Month</li>
                      {chatHistory.thisMonth.slice(0, thisMonthLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.thisMonth.length > thisMonthLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.thisYear && chatHistory.thisYear.length > 0 && (
                    <>
                      <li className="messages-header mx-2">This Year</li>
                      {chatHistory.thisYear.slice(0, thisYearLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.thisYear.length > thisYearLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}

                  {chatHistory.older && chatHistory.older.length > 0 && (
                    <>
                      <li className="messages-header">Older</li>
                      {chatHistory.older.slice(0, olderLimit).map((chat) => (
                        <li key={chat.id} className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            title={chat.chat_question}
                          >
                            {chat.chat_question.length > 20 ? chat.chat_question.substring(0, 20) + '...' : chat.chat_question}
                          </a>
                        </li>
                      ))}
                      {chatHistory.older.length > olderLimit && (
                        <li className="messages mt-2">
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={loadMoreMessages}
                          >
                            View more
                          </a>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </li>

              {/* Assessment History */}
               <li>
                              <a
                                onClick={() => {
                                  window.location.href = `/ProfileOfStudent?studentId=${studentId}`;
                                }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                              >
                                <img
                                  src={interview}
                                  style={{ width: '25px' }}
                                  alt="Icon"
                                />
                                <span className="nav-text text-white ms-3">Assessment History</span>
                              </a>
                            </li>
              <li>
                <a className="has-arrow" style={{ cursor: 'pointer' }} aria-expanded="false">
                  <img
                    src={interview}
                    style={{ width: "25px" }}
                    alt="Icon"
                  />
                  <span className="nav-text text-white ms-3">Digital Library</span>
                </a>
                <ul className="" aria-expanded="false" style={{ overflow: 'auto', maxHeight: '30vh' }}>
                  <li>
                    <span className="text-muted"></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        {/* CONTENT MAIN */}
        <div class="content-body default-height">
          <div class="container-fluid">
            <div class="col-xl-12">
              <div class="card">
                <div className="card-header d-flex align-items-center" style={{ justifyContent: 'flex-start' }}>
                  <button
                    onClick={goBack}
                    className="btn btn-link text-white me-3"
                    style={{ textDecoration: 'none' }}
                  >
                    <i className="fa fa-arrow-left" style={{ fontSize: '20px' }}></i>
                  </button>
                  <h4 className='text-white'>Digital Library</h4>
                </div>
                <div class="card-body">
                  <div className="card-tabs d-flex justify-content-center">
                    <ul className="nav nav-tabs d-inline-flex mb-4" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === 'public' ? 'active' : ''}`}
                          onClick={() => setActiveTab('public')}
                          role="tab"
                        >
                          Public Library
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === 'private' ? 'active' : ''}`}
                          onClick={() => setActiveTab('private')}
                          role="tab"
                        >
                          Private Library
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === 'collection' ? 'active' : ''}`}
                          onClick={() => setActiveTab('collection')}
                          role="tab"
                        >
                          My Collection
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    {activeTab === 'public' && (
                      <div id="public" className="tab-pane active show" role="tabpanel">
                        <div className="cards-wrapper mt-5">
                          {books.map((book) => (
                            <div className="col-xl-12 mb-3" key={book.id}>
                              <div className="book-card">
                                <img src={book.cover_url} alt={book.title} />
                                <p
                                  className={`d-flex justify-content-end fw-bold ${book.status === 'private'
                                    ? 'text-danger'
                                    : book.status === 'public'
                                      ? 'text-success'
                                      : book.status === 'unassigned'
                                        ? 'text-warning'
                                        : ''
                                    }`}
                                >
                                  {book.status.toUpperCase()}
                                </p>
                                <div className="content" style={{ cursor: 'pointer' }}>
                                  <h6
                                    className="text-white mt-2 mx-1"
                                    title={book.book_description || 'No description available'}
                                  >
                                    {book.book_description
                                      ? book.book_description.length > 56
                                        ? `${book.book_description.slice(0, 56)}...`
                                        : book.book_description
                                      : 'No description available'}
                                  </h6>
                                  <p className="author text-white">By {book.published_by}</p>
                                  <p>
                                    <span className="text-white fw-bold">ISBN: </span>
                                    {book.isbn}
                                  </p>
                                  <p className="details text-white">
                                    {book.published_year} | {book.publisher_name}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <button
                                    onClick={() => openPdfPopup(book.pdf_url, 1)}
                                    className="btn-success btn-sm btn"
                                    title="View"
                                  >
                                    <i className="fa fa-eye text-dark"></i>
                                  </button>
                                  <button
                                    className="btn-danger btn-sm btn"
                                    title="Add to Collection"
                                    onClick={() => addToCollection(studentId, book.id)}
                                  >
                                    <i className="fa fa-heart text-dark"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {activeTab === 'private' && (
                      <div id="private" className="tab-pane active show" role="tabpanel">
                        <div className="cards-wrapper mt-5">
                          {books.map((book) => (
                            <div className="col-xl-12 mb-3" key={book.id}>
                              <div className="book-card">
                                <img src={book.cover_url} alt={book.title} />
                                <p
                                  className={`d-flex justify-content-end fw-bold ${book.status === 'private'
                                    ? 'text-danger'
                                    : book.status === 'public'
                                      ? 'text-success'
                                      : book.status === 'unassigned'
                                        ? 'text-warning'
                                        : ''
                                    }`}
                                >
                                  {book.status.toUpperCase()}
                                </p>
                                <div className="content" style={{ cursor: 'pointer' }}>
                                  <h6
                                    className="text-white mt-2 mx-1"
                                    title={book.book_description || 'No description available'}
                                  >
                                    {book.book_description
                                      ? book.book_description.length > 56
                                        ? `${book.book_description.slice(0, 56)}...`
                                        : book.book_description
                                      : 'No description available'}
                                  </h6>
                                  <p className="author text-white">By {book.published_by}</p>
                                  <p>
                                    <span className="text-white fw-bold">ISBN: </span>
                                    {book.isbn}
                                  </p>
                                  <p className="details text-white">
                                    {book.published_year} | {book.publisher_name}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <button
                                    onClick={() => openPdfPopup(book.pdf_url, 1)}
                                    className="btn-success btn-sm btn"
                                    title="View"
                                  >
                                    <i className="fa fa-eye text-dark"></i>
                                  </button>
                                  <button
                                    className="btn-danger btn-sm btn"
                                    title="Add to Collection"
                                    onClick={() => addToCollection(studentId, book.id)}
                                  >
                                    <i className="fa fa-heart text-dark"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {activeTab === 'collection' && (
                      <div id="collection" className="tab-pane active show" role="tabpanel">
                        <div className="cards-wrapper mt-5">
                          {myCollection.map((item) => (
                            <div className="col-xl-12 mb-3" key={item.id}>
                              <div className="book-card">
                                <img src={item.book_details.cover_url} alt={item.book_details.title} />
                                <p className={`d-flex justify-content-end fw-bold
                                  ${item.book_details.status === 'private' ? 'text-danger'
                                    : item.book_details.status === 'public' ? 'text-success'
                                      : item.book_details.status === 'unassigned' ? 'text-warning' : ''}`}>
                                  {item.book_details.status.toUpperCase()}
                                </p>
                                <div className="content" style={{ cursor: 'pointer' }}>
                                  <h6
                                    className="text-white mt-2 mx-1"
                                    title={item.book_details.book_description || 'No description available'}
                                  >
                                    {item.book_details.book_description
                                      ? item.book_details.book_description.length > 56
                                        ? `${item.book_details.book_description.slice(0, 56)}...`
                                        : item.book_details.book_description
                                      : 'No description available'}
                                  </h6>
                                  <p className="author text-white">By {item.book_details.published_by}</p>
                                  <p>
                                    <span className="text-white fw-bold">ISBN: </span>
                                    {item.book_details.isbn}
                                  </p>
                                  <p className="details text-white">
                                    {item.book_details.published_year} | {item.book_details.publisher_name}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <button className="btn-success btn-sm btn" title="View">
                                    <i className="fa fa-eye text-dark"></i>
                                  </button>
                                  <button className="btn-danger btn-sm btn" title="Remove"
                                    onClick={() => removeFromCollection(item.id)}>
                                    <i className="fa fa-trash text-dark"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* footer */}
        <div class="footer" style={{ backgroundColor: '#2b2d42' }}>
          <div class="copyright text-white">
            <p>Copyright © Designed &amp; Developed by <a className='text-green' href="https://datamoo.ai/" target="blank">DataMoo AI</a> 2025</p>
          </div>
        </div>
      </div>

    </>
  );
}

export default StudentDigitalLib;